.modal-xsm {
    width: 300px !important;
    max-width: 300px !important;
}
.modal-sm {
    width: 500px !important;
    max-width: 500px !important;
}

.modal-md {
    width: 600px !important;
    max-width: 600px !important;
}

.modal-lg {
    width: 700px !important;
    max-width: 700px !important;
}

.modal-xl {
    width: 800px !important;
    max-width: 800px !important;
}

.modal-xxl {
    width: 900px !important;
    max-width: 900px !important;
}

.modal-xxxl {
    width: 1140px;
    max-width: 1140px !important;
}

.modal-80 {
    width: 80%;
    max-width: 80% !important;
}

.modal-90 {
    width: 90%;
    max-width: 90% !important;
}

.modal-100 {
    width: 100%;
    max-width: 100% !important;
}

.w-1024 {
    width: 1024px;
    max-width: 1024px;
}
.w-1200 {
    width: 1200px;
    max-width: 1200px;
}
.w-1320 {
    width: 1320px;
    max-width: 1320px;
}

.modal {
    --bs-modal-width: 600px;
    /* Default Width */
    display: flex !important;
    align-items: center;
    margin: 0 auto;
    .modal-header,
    .modal-footer {
        border-top: none;
        border-bottom: none;
        h1 {
            line-height: 1.2;
        }
    }
    .modal-header {
        padding: 1rem 1rem 0 1rem;
       
    }
    .modal-footer {
        padding: 0 1rem 1rem 1rem;
        .btn {
            margin: 0;
            + .btn {
                margin-left: 10px !important;
            }
        }
        .row {
            margin-right: -15px !important;
            margin-left: -15px !important;
        }
    }

    .modal-footer > * {
        margin: 0;
    }
    .fa-close {
        color: $anchor-color;
        &:hover {
            color: $anchor-hover;
        }
    }
    .close {
        outline: none;
        background: transparent;
        border: 0;
        opacity: 1;
        font-size: 20px;
        color: $base-color;
        line-height: 24px;
        padding: 0;
        align-self: start;
    }
    .red-text {
        font-size: 12px;
        color: $red;
        font-weight: $font-med;
    }
}

.modal-full-popup {
    max-width: 100% !important;
    width: 100vw !important;
    height: 100vh !important;
    margin: 0 auto !important;
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .modal-content {
        border: none !important;
        border-radius: 0 !important;
    }

    .modal-header,
    .modal-footer {
        border-radius: 0 !important;
        border: none !important;
    }

    .modal-header {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        height: 55px;
        vertical-align: middle;
        padding-top: 0 !important;

        .modal-breadcrumb {
            margin: 0;
            list-style: none;
            padding: 0;
            display: inline-flex;

            li {
                padding: 0 10px;
                position: relative;

                &:first-child {
                    padding-left: 0;

                    &:before {
                        border-left: none;
                    }
                }

                &:before {
                    position: absolute;
                    content: "";
                    border-left: 1px solid $gray;
                    left: 0;
                    top: calc(50% - 9px);
                    height: 18px;
                }
            }

            .form-group {
                margin: 0;

                .form-control {
                    margin: 0;
                    height: 18px;
                }
            }
        }
    }

    .modal-body,
    .canvas-wrapper {
        height: calc(100vh - 106px) !important;
    }

    .modal-body-view {
        margin-bottom: 0 !important;
    }

    &.view-mode {
        .modal-body {
            height: calc(100vh - 115px) !important;

            .pan-element {
                left: inherit !important;
                right: inherit !important;
                position: inherit !important;

                .zoom-element {
                    height: calc(100vh - 145px) !important;

                    img {
                        &.cursorGrab {
                            cursor: -webkit-grab;
                        }

                        &.cursorGrabbing {
                            cursor: -webkit-grabbing;
                        }
                    }
                }
            }
        }

        .canvas-wrapper {
            height: calc(100vh - 145px) !important;
            width: calc(100vw - 50px) !important;

            &.set-scale-image-wrapper {
                position: relative;
                width: fit-content !important;
                margin: 0 auto;

                img {
                    height: 100%;
                    margin: 0 auto;
                    cursor: crosshair;
                }
            }
        }

        .modal-footer {
            height: 60px;
        }

        .scroll-container {
            &.scroll-container-x {
                display: none;
            }
        }
    }

    &.modal-markup {
        .modal-header {
            height: 60px;

            .project-specific-header-note {
                font-size: 12px;

                @include mq(min-desktop) {
                    font-size: 13px;
                }

                @include mq(sm-desktop) {
                    font-size: 14px;
                }
            }
        }

        .modal-body,
        .canvas-wrapper {
            height: calc(100vh - 145px) !important;
        }
    }

    .carousel-control-next,
    .carousel-control-prev {
        z-index: 0 !important;
    }
}

.modal-100 {
    &.pixie {
        height: 100vh !important;
    }
}

.create-new-modal {
    .modal-body {
        height: calc(100vh - 156px);
    }
}

.note-text {
    margin-bottom: 0;
    font-size: 11px;
}

// for pixie
.modal-page-cover {
    max-width: 100% !important;
    width: 100vw !important;
    height: 100vh !important;
    margin: 0 auto !important;
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    .modal-content {
        border: none !important;
        border-radius: 0 !important;
    }
    .modal-header,
    .modal-footer {
        border-radius: 0 !important;
        border: none !important;
    }
    .modal-header {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        height: 55px;
        vertical-align: middle;
        .modal-breadcrumb {
            margin: 0;
            list-style: none;
            padding: 0;
            display: inline-flex;
            li {
                padding: 0 10px;
                position: relative;
                &:first-child {
                    padding-left: 0;
                    &:before {
                        border-left: none;
                    }
                }
                &:before {
                    position: absolute;
                    content: "";
                    border-left: 1px solid $gray;
                    left: 0;
                    top: calc(50% - 9px);
                    height: 18px;
                }
            }
            .form-group {
                margin: 0;
                .form-control {
                    margin: 0;
                    height: 18px;
                }
            }
        }
    }
    .modal-footer {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        height: 85px;
        vertical-align: middle;
        padding-bottom: 0 !important;
      
    }
    main {
        height: calc(100vh - 200px) !important;
    }
    .modal-body-view {
        margin-bottom: 0 !important;
    }
    &.view-mode {
        .modal-body {
            height: calc(100vh - 115px) !important;
            .pan-element {
                left: inherit !important;
                right: inherit !important;
                position: inherit !important;
                .zoom-element {
                    height: calc(100vh - 145px) !important;
                    img {
                        &.cursorGrab {
                            cursor: -webkit-grab;
                        }
                        &.cursorGrabbing {
                            cursor: -webkit-grabbing;
                        }
                    }
                }
            }
        }
        .canvas-wrapper {
            height: calc(100vh - 145px) !important;
            width: calc(100vw - 50px) !important;
            &.set-scale-image-wrapper {
                position: relative;
                width: fit-content !important;
                margin: 0 auto;
                img {
                    height: 100%;
                    margin: 0 auto;
                    cursor: crosshair;
                }
            }
        }
        .modal-footer {
            height: 60px;
        }
        .scroll-container {
            &.scroll-container-x {
                display: none;
            }
        }
    }
    &.modal-markup {
        .modal-header {
            height: 60px;
            .project-specific-header-note {
                font-size: 12px;
                @include mq(min-desktop) {
                    font-size: 13px;
                }
                @include mq(sm-desktop) {
                    font-size: 14px;
                }
            }
        }
        .modal-body {
            height: calc(100vh - 145px);
        }
    }
    .carousel-control-next,
    .carousel-control-prev {
        z-index: 0 !important;
    }
    &.modal-markup {
        .modal-header {
            height: 60px;
            .project-specific-header-note {
                font-size: 12px;
                @include mq(min-desktop) {
                    font-size: 13px;
                }
                @include mq(sm-desktop) {
                    font-size: 14px;
                }
            }
        }
        .modal-body,
        .canvas-wrapper {
            height: calc(100vh - 145px);
        }
    }
}

.role-card {
    box-shadow: 0px 1px 3px #00000029;
    -webkit-box-shadow: 0px 1px 3px #00000029;
    -moz-box-shadow: 0px 1px 3px #00000029;
    -ms-box-shadow: 0px 1px 3px #00000029;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    padding: 8px 15px;
    margin-bottom: 10px !important;
    display: block;
    color: $base-color !important;
    font-size: 14px !important;
    font-weight: $font-reg !important;
    cursor: pointer;
    .role-card-radio {
        width: 18%;
        margin: 0 !important;
        label {
            font-weight: $font-med !important;
            font-size: 13px !important;
            padding-left: 22px !important;
            line-height: 14px !important;
            display: inline-block;
            color: $base-color;
        }
    }
    .role-card-description {
        width: 52%;
        border-right: 1px solid $gray-lightest;
        .role-card-list {
            margin-bottom: 0 !important;
            li {
                margin-bottom: 0;
            }
        }
    }
    .role-card-user-radio {
        margin: 0 0 2px 0 !important;
        label {
            font-weight: $font-med !important;
            font-size: 13px;
            padding-left: 22px;
            line-height: 14px;
            display: inline-block;
            color: $base-color;
        }
    }
    .role-card-user-description {
        margin-left: 22px;
    }
    .role-typical-user {
        width: 30%;
        text-align: center;
        .user-title {
            display: block;
            color: $gray-light;
            font-size: 12px;
            font-weight: $font-light;
        }
        .user {
            font-weight: $font-reg;
            font-size: 12px;
        }
    }
    &.active {
        background: $gray-lightest !important;
        .role-card-description {
            border-right: 1px solid $white;
        }
    }
    &.user-card {
        min-height: 280px;
        cursor: auto;
    }
}

.role-popup {
    .modal-body {
        max-height: calc(100vh - 180px);
        overflow: auto;
    }
}

.or-devider {
    width: 15px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        border-left: 1px solid $gray-lighter;
        height: 88%;
        left: 7px;
        top: 28px;
    }
    span {
        background: $white;
        padding: 15px 0;
        z-index: 1;
    }
}


// large pixie pop up tool tip
.view-modal-popup {
    padding: 15px 0;
    .position-relative {
        height: 100%;
    }
    .content-wrapper {
        width: 100%;
        overflow: hidden;
        .card {
            padding-bottom: 0;
            overflow: hidden;
            box-shadow: none;
            border: none;
            background: transparent;
            .card-body {
                text-align: center;
                padding: 5px;
                overflow: hidden;
                height: calc(100vh - 147px);
                border: 3px solid transparent;
                .upload-main-img {
                    .img-zoom {
                        max-width: inherit;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    .custom-slider-control {
        .control-button {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary-color;
            text-align: center;
            font-size: 50px;
            height: 100%;
            line-height: 50px;
            transition: opacity 0.15s ease;
            background: none;
            border: none;
            outline: none;
            &:hover,
            &:focus {
                color: $blue;
                border: none;
            }
            &.previous {
                left: 0;
            }
            &.next {
                right: 0;
            }
        }
    }
    
}

.modal-body {
    &.provide-body {
        p {
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}

// live feed video modal
.modal-live-feed {
    width: 676px;
    .live-feed-body {
        min-height: 90px;
        padding-bottom: 10px;
        .div-table {
            height: 400px;
            iframe {
                height: 364px;
                width: 100%;
                html {
                    margin-left: -24px;
                    margin-top: -74px;
                }
            }
        }
    }
}

.project-filter {
    .checkbox-label {
        display: inline-block;
        label {
            line-height: 24px;
            padding-left: 25px !important;
            &:before {
                font-size: 15px;
                color: $base-color;
            }
            &:after {
                font-size: 9px;
                margin: 1px 0 0 3px;
                padding: 0;
                color: $base-color;
            }
        }
    }
    .download-option-list {
        padding: 0 15px;
    }
    .blue-text {
        color: $blue;
    }
    .project-filter-body {
        @include mq(laptop) {
            max-height: calc(100vh - 560px);
            min-height: 340px;
        }
        max-height: calc(100vh - 360px);
        overflow: auto;
        min-height: 336px;
    }
}
.project-overview-report {
    .project-filter {
        border-right: none !important;
        .project-filter-body {
            // height: calc(100vh - 560px);
            // max-height: calc(100vh - 700px);
            min-height: auto !important;
        }
    }
}
.add-new-col {
    border-left: 1px solid $gray-light;
    .fa-add-new {
        font-size: 100px;
        margin-bottom: 20px;
    }
    h2,
    p {
        font-size: 20px;
        @include mq(laptop) {
            font-size: 24px;
        }
    }
}

.newprojectM {
    max-width: 90% !important;
    width: 90% !important;
    overflow: hidden;
    @include mq(laptop) {
        max-width: 75% !important;
        width: 75% !important;
    }
}
.newplanM {
    max-width: 90% !important;
    width: 90% !important;
    overflow: hidden;
    .modal-body {
        height: calc(100vh - 245px);
        overflow: auto;
    }
    @include mq(laptop) {
        max-width: 75% !important;
        width: 75% !important;
    }
    .edit-new-modal {
        .modal-body {
            height: calc(100vh - 200px);
        }
    }
}
.newBuildingM {
    max-width: 90% !important;
    width: 90% !important;
    overflow: hidden;
    .modal-body {
        height: calc(100vh - 200px);
        overflow: auto;
    }
    @include mq(laptop) {
        max-width: 75% !important;
        width: 75% !important;
    }
}
.site-move-hierarchy {
    max-width: 92% !important;
    width: 92% !important;
    overflow: hidden;
    .modal-body {
        height: calc(100vh - 160px);
        overflow: auto;
    }
    @include mq(laptop) {
        max-width: 82% !important;
        width: 82% !important;
        .modal-body {
            height: calc(100vh - 240px);
        }
    }
}
.plan-selectM {
    max-width: 68% !important;
    width: 68% !important;
    overflow: hidden;
    .modal-body {
        min-height: 550px;
        overflow: auto;
    }
    @include mq(laptop) {
        max-width: 60% !important;
        width: 60% !important;
    }
}
.set-password {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    width: 220px;
    position: relative;
    .form-control {
        height: 20px;
        padding-bottom: 0px;
    }
    .help-block {
        left: 0px;
        z-index: 1;
        right: inherit;
        margin-top: 15px;
        &:after,
        &:before {
            bottom: 100%;
            left: 20px;
            right: inherit;
            top: inherit;
        }
        &:before {
            border-bottom-color: $red;
            border-right-color: transparent;
            margin-left: -10px;
            margin-top: 0px;
        }
        &:after {
            border-bottom-color: $white;
            border-right-color: transparent;
            margin-left: -8px;
            margin-top: 0px;
        }
    }
}
.modal-tech-activity {
    max-width: 94% !important;
    width: 94% !important;
    overflow: hidden;
    .modal-body {
        height: calc(100vh - 154px);
        overflow: auto;
    }
}
.footer-info,
.footer-cta {
    min-width: 310px;
    max-width: 310px;
    &.view-mode {
        min-width: 700px;
        max-width: 700px;
    }

    &.footer-info-text {
        position: absolute;
        z-index: 999;
        bottom: -75px;
        left: 15px;
    }
    &.image-source {
        bottom: -83px !important;
    }
}

