.card-main {
    .card-accordion {
        .card {
            margin-bottom: 15px;
            border-radius: 6px !important;
            -webkit-box-shadow: 2px 1px 5px -3px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 2px 1px 5px -3px rgba(0, 0, 0, 0.5);
            -ms-box-shadow: 2px 1px 5px -3px rgba(0, 0, 0, 0.5);
            box-shadow: 2px 1px 5px -3px rgba(0, 0, 0, 0.5);
        }
    }
    .card-building {
        .card {
            border-radius: 0 !important;
            box-shadow: none !important;
            margin-bottom: 0;
            border: none;
            &.equipment-thumb-img {
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
            }
        }
        .slide-head-panel {
            padding-left: 50px !important;
            .building-name {
                padding-left: 15px !important;
                &:before {
                    left: -10px !important;
                }
            }
        }
    }

    .building-name-col {
        width: 27%;
    }
    .plans-col,
    .devices-col,
    .task-col,
    .punch-list-col,
    .operational-col,
    .device-status-col {
        width: 8%;
    }
    .hours-left-col {
        width: 9%;
    }
    .progress-col {
        width: 20%;
        .project-progress-val {
            padding-top: 7px;
            width: 70%;
            display: inline-block;
            margin-right: 15px; 
            @include mq(laptop) {
                width: 65%;
            }
            .progress-block {
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 100px);
                float: none;
                padding-left: 0;
            }
            .progress {
                background-color: $gray-lighter;
                height: 8px;
                border-radius: 10px;
                margin-bottom: 0px;
                padding: 0px !important;
                box-shadow: none;
                width: 100%;
                transition: none;
                .progress-bar {
                    background: $blue;
                    transition: none;
                }
            }
            .project-percentage {
                display: inline-block;
                vertical-align: middle;
                float: none;
            }
        }
        .project-status-count {
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
            div {
                padding-left: 0;
                font-weight: $font-med;
            }
            .building-working-op {
                color: $blue;
                width: 33.3%;
                display: inline-block;
            }
            .building-working-opwith {
                color: $yellow;
                width: 33.3%;
                display: inline-block;
            }
            .building-working-not {
                color: $red;
                width: 33.3%;
                display: inline-block;
            }
        }
    }
    .punch-list-col {
        &.punch-list-count {
            color: $primary-color;
            i,
            em {
                margin-right: 5px;
            }
        }
    }
    .project-buidling-heading {
        padding: 10px 0 10px 50px;
        border-bottom: 1px solid $gray-light;
        &:first-child {
            padding-left: 50px;
        }
        div {
            display: inline-block;
            color: $label-color;
            padding: 0 15px;
            font-size: 14px;
            font-weight: $font-reg;
            white-space: nowrap;
        }
    }
    .project-plan {
        &.project-plan-grid {
            padding: 15px 15px 0px 40px !important;
        }
        padding: 10px 0 10px 50px ;
        background: $gray-lightest;
        display: flex;
        flex-flow: wrap;
        div {
            padding: 0 15px;
            font-size: 14px;
            font-weight: $font-reg;
        }
        .equipment-thumb-img {
            padding: 10px !important;
            width: 172px;
            height: 110px;
            text-align: center;
            margin-right: 15px;
            margin-bottom: 15px !important;
            float: left;
            @include mq(laptop) {
                width: 190px;
                height: 126px;
            }
            img {
                height: 68px;
                width: auto;
                display: block;
                margin: 0 auto;
                @include mq(laptop) {
                    height: 85px;
                }
            }
            label {
                font-size: 14px;
                font-weight: $font-reg;
                margin-bottom: 0;
                margin-top: 5px;
                cursor: pointer;
                padding: 0 15px 0 0;
            }
        }
    }
    .panel-group {
        margin-bottom: 0px;
        .panel {
            min-height: inherit;
            padding-bottom: 0px;
            &.panel-open {
                .accordion-toggle[aria-expanded="true"] {
                    padding-bottom: 0 !important;
                    .project-val {
                        &:before {
                            top: 3px;
                            left: 15px;
                        }
                        &.slide-toggle {
                            &:before {
                                left: 5px;
                                top: 7px;
                            }
                        }
                    }
                    .slide-head-panel {
                        .project-name,
                        .building-name {
                            &::before {
                                transform: rotate(90deg);
                                -webkit-transform: rotate(90deg);
                                -moz-transform: rotate(90deg);
                                -ms-transform: rotate(90deg);
                                -o-transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
            .panel-heading {
                border-radius: 0px;
                background: none;
                padding: 0px;
                .slide-head-panel {
                    &.main-panel {
                        padding: 15px 0;
                    }
                    padding: 10px 0;
                    div {
                        font-size: 14px;
                        line-height: 1.5;
                        padding: 0 15px;
                        cursor: default;
                        display: inline-block;
                    }
                    .project-name,
                    .building-name {
                        color: $primary-color;
                        position: relative;
                        padding-left: 25px;
                        &:before {
                            font-family: "Font Awesome 5 Pro", sans-serif;
                            content: "\f105";
                            left: 5px;
                            top: 2px;
                            position: absolute;
                            font-size: 19px;
                            line-height: 19px;
                            background: transparent;
                            cursor: pointer;
                        }
                        &.slide-toggle {
                            &:before {
                                top: 2px;
                            }
                        }
                    }
                    h2 {
                        &.project-name-title {
                            font-size: 18px;
                            line-height: 24px;
                            display: block;
                            color: $base-color;
                            font-weight: $font-med;
                            margin: 0 0 10px 0;
                            @include mq(laptop) {
                                font-size: 20px;
                            }
                        }
                        span {
                            &.live-system-lable {
                                background: $green;
                                border-radius: 4px;
                                font-size: 13px;
                                line-height: 13px;
                                color: $white;
                                padding: 5px 10px;
                                position: relative;
                                left: 5px;
                                top: -2px;
                                display: inline-block;
                            }
                        }
                    }
                    .status-col,
                    .count-col {
                        display: block;
                        text-align: right;
                    }
                    .status-col {
                        margin-bottom: 10px;
                        span {
                            margin-left: 15px;
                        }
                    }
                    .project-info-list {
                        list-style-type: none;
                        margin-bottom: 0;
                        padding-left: 15px;
                        margin-top: 12px;
                        display: flex;
                        li {
                            padding: 0 5px;
                            display: inline-block;
                            position: relative;
                            
                            white-space: pre-wrap;
                          
                            &:first-child {
                                &:before {
                                    border-left: none;
                                }
                            }
                            &:last-child {
                                padding-right: 0;
                                &.progress-percent {
                                    padding-left: 0;
                                    &:before {
                                        border-left: none;
                                    }
                                }
                            }
                            &:before {
                                position: absolute;
                                content: "";
                                border-left: 1px solid $gray-light;
                                left: 0;
                                top: 3px;
                                height: 80%;
                            }
                        }
                    }
                    .count-list {
                        list-style-type: none;
                        margin-bottom: 0;
                        li {
                            padding: 0 10px;
                            display: inline-block;
                            position: relative;
                            &.progress-percent, &:first-child {
                                padding-left: 0;
                                &:before {
                                    border-left: none;
                                }
                            }
                        
                            &:last-child {
                                padding-right: 0;
                             
                            }
                            &.progress-col {
                                width: 100px;
                                @include mq(min-desktop) {
                                    width: 195px;
                                }
                                .project-progress-val {
                                    padding-top: 0;
                                    width: 100%;
                                }
                            }
                            &:before {
                                position: absolute;
                                content: "";
                                border-left: 1px solid $gray-light;
                                left: 0;
                                top: 3px;
                                height: 14px;
                            }
                        }
                    }
                    .design-val {
                        min-width: 115px;
                        padding: 3px 5px;
                        color: $white;
                        font-size: 14px;
                        text-align: center;
                        border-radius: 3px;
                        display: inline-block;
                        &.open {
                            background: $red;
                        }
                        &.inprogress {
                            background: $yellow;
                        }
                        &.done {
                            background: $blue;
                        }
                        &.published {
                            background: $green;
                        }
                        &.archive {
                            background: $gray-light;
                        }
                    }
                }
            }
            .link {
                cursor: pointer !important;
                color: $anchor-color !important;
                &:hover {
                    color: $anchor-hover !important;
                }
            }
            .panel-body {
                padding: 0px;
                border: none;
            }
            .expand-body {
                background: $gray-lightest;
                border-bottom: 1px solid $gray-lighter;
                &:last-child {
                    border-bottom: none;
                }
                .expand-inner {
                    padding: 10px 0;
                    div {
                        float: left;
                        font-size: 14px;
                        line-height: 1.1;
                        padding: 0 15px;
                    }
                    .project-exp {
                        color: $dark-color;
                        position: relative;
                        padding-left: 40px;
                    }
                    .project-progress-exp {
                        .progress-block {
                            display: inline-block;
                            vertical-align: middle;
                            width: calc(100% - 100px);
                            float: none;
                            padding-left: 0;
                        }
                        .progress {
                            background-color: $gray-lighter;
                            height: 6px;
                            border-radius: 10px;
                            margin-bottom: 0px;
                            box-shadow: none;
                            width: 100%;
                            padding: 0px;
                            transition: none;
                            .progress-bar {
                                background: $blue;
                                transition: none;
                            }
                        }
                        .project-percentage {
                            display: inline-block;
                            vertical-align: middle;
                            float: none;
                        }
                    }
                }
            }
        }
    }
    .no-project {
        padding: 0 !important;
        margin-bottom: 30px;
        line-height: 22px;
        min-height: auto;
    }
    .building-dropdown {
        display: inline-block;
        vertical-align: middle;
        padding: 0 8px !important;
        line-height: 17px !important;
        div {
            padding: 0 !important;
        }
        .dropdown-toggle {
            box-shadow: none;
        }
        .dropdown-menu {
            right: 0px;
            left: inherit;
            box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.1);
            border: 1px solid $gray-lighter;
            border-radius: 0px;
            margin-top: 0;
            padding: 0;
            li {
               
                margin-bottom: 0px;
                a {
                    padding: 5px 15px;
                    line-height: 19px;
                    font-size: 14px;
                    &:hover,
                    &:focus {
                        text-decoration: none;
                        background: $blue !important;
                        border-radius: 0;
                        color: $white !important;
                    }
                }
            }
        }
    }
}

.project-chart-bar {
    width: 100%;
    padding: 0 15px;
    .project-chart-bar-progressbars {
        border-top: 1px solid $gray-lightest;
        padding: 10px 0;
        width: 100%;
        ul {
            &.bar-list {
                list-style-type: none;
                margin-bottom: 0;
                li {
                    padding: 0 10px;
                    display: inline-block;
                    position: relative;
                    &:first-child,
                    &:last-child {
                        &:before {
                            border-left: none;
                        }
                    }
                    &:last-child {
                        padding-right: 0;
                        min-width: 40px;
                        text-align: left;
                        &.progress-status {
                            padding-left: 0;
                        }
                    }
                    &:before {
                        position: absolute;
                        content: "";
                        border-left: 1px solid $gray-lighter;
                        left: 0;
                        top: 3px;
                        height: 14px;
                    }
                    &.progress-col {
                        width: 195px;
                        .project-progress-val {
                            padding-top: 0;
                            width: 100%;
                        }
                    }
                    &.progress-status {
                        font-size: 13px;
                    }
                }
                &.device-bar {
                    .progress-bar {
                        background: $purple;
                    }
                }
                &.task-bar {
                    .progress-bar {
                        background: $green;
                    }
                }
                &.hours-bar {
                    .progress-bar {
                        background: $blue;
                    }
                }
            }
        }
    }
    .project-chart-bar-progresscharts {
        padding: 16px 0;
        width: 100%;
        .project-chart-bar-card {
            border-radius: 2px !important;
            padding: 15px;
            box-shadow: 0px 2px 6px #0000000a;
            -webkit-box-shadow: 0px 2px 6px #0000000a;
            -moz-box-shadow: 0px 2px 6px #0000000a;
            margin-bottom: 5px;
            border: none !important;
            height: 215px;
            width: 91%;
        }
        h3 {
            font-size: 15px;
            font-weight: $font-med;
            margin-bottom: 5px;
        }
        .chart-card {
            display: inline-block;
            width: 100%;
            overflow-x: auto;
            height: 200px;
            border-radius: 0 !important;
            .ngx-charts-outer {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
            svg {
                overflow: visible !important;
                margin-left: -3px;
            }
        }
        .chart-bar-info {
            display: inline-block;
            width: 182px;
            padding: 15px 20px;
            .chart-select-menu {
                box-shadow: 2px 1px 5px -3px rgba(0, 0, 0, 0.5);
                -ms-box-shadow: 2px 1px 5px -3px rgba(0, 0, 0, 0.5);
                padding: 10px;
                border-radius: 4px;
                border: 1px solid $gray-lightest;
                outline: none;
                height: 40px;
                line-height: 40px;
                width: 125px;
                &:focus,
                &::selection,
                &:visited {
                    border: 1px solid $gray-lightest;
                    outline: none;
                }
            }
        }
        .chart-color-indicator {
            list-style: none;
            margin: 15px 0;
            padding: 0;
            li {
                position: relative;
                padding-left: 20px;
                margin-bottom: 8px;
                &:before {
                    position: absolute;
                    content: "";
                    width: 14px;
                    height: 14px;
                    background: $white;
                    border-radius: 100%;
                    left: 0;
                    top: 3px;
                }
                &.devices {
                    &:before {
                        border: 3px solid $purple;
                    }
                }
                &.task {
                    &:before {
                        border: 3px solid $blue;
                    }
                }
            }
        }
    }
}

.project-expand-body {
    .card-header {
        border-bottom: none;
    }
}

.progress-bar {
    position: relative;
    &:hover {
        .progress-tooltip {
            display: block;
        }
    }
}

.project-progress-val {
    position: relative;
}

.progress-tooltip {
    display: none !important;
    position: absolute;
    min-width: 100px;
    width: max-content;
    height: 55px;
    box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background: $white;
    left: 0;
    bottom: 20px;
    padding: 8px 10px !important;
    line-height: 20px;
    font-size: 13px;
    z-index: 999;
    &.tooltip-project-stauts {
        max-width: auto !important;
        height: auto !important;
        bottom: 14px !important;
    }
    &.tooltip-sm {
        max-width: 180px !important;
        height: auto !important;
        bottom: 20px !important;
    }
    &.tooltip-md {
        max-width: auto !important;
        height: auto !important;
        bottom: 22px !important;
    }
    &.tooltip-site {
        max-width: auto !important;
        height: auto !important;
        bottom: 18px !important;
    }
    .blue {
        color: $blue;
    }
    .red {
        color: $red;
    }
    .yellow {
        color: $yellow;
    }
    p {
        margin: 0;
        text-align: left;
    }
    &.show-tooltip {
        display: block !important;
        &.show-tooltip-dagner,
        &.show-tooltip-warning,
        &.show-tooltip-info {
            bottom: 15px;
        }
    }
}

.chart-bar-tooltip {
    position: absolute;
    width: max-content !important;
    height: auto !important;
    box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background: $white;
    color: $base-color;
    bottom: -10px;
    padding: 6px 10px !important;
    line-height: 16px;
    font-size: 13px;
    z-index: 9999;
    fill: none !important;
    text-align: right;
    &.left {
        text-align: left !important;
    }
    &.live-planning-tooltip {
        padding: 0 10px 4px 10px !important;
    }
    .tooltip-label {
        min-width: 10px;
        width: 100%;
        text-align: center;
        display: inline-block;
        font-weight: 500;
        margin: 0 0 8px 0;
        position: relative;
        &::before {
            content: "";
            border-top: 1px solid $gray-lighter;
            position: absolute;
            left: -10px;
            top: 28px;
            right: -10px;
        }
    }
    p {
        margin: 0 0 5px 0;
        padding: 0;
        &:last-child {
            margin: 0;
        }
        .tooltip-count {
            min-width: 10px;
            text-align: right;
            display: inline-block;
        }
        
    }
    .click-bar-info {
        color: $gray-light;
    }
}

.tooltip-caret {
    display: none !important;
    fill: none !important;
}

.quick-edit {
    position: relative;
    .quick-edit-icon {
        display: none;
        font-size: 14px;
        color: $blue;
        position: absolute;
        cursor: pointer;
        bottom: 2px;
        padding-left: 5px;
        z-index: 9999;
    }
    .quick-edit-icon-grid-plan {
        position: absolute;
        bottom: 2px;
        padding-left: 5px;
        z-index: 9999;
    }
    &:hover {
        .quick-edit-icon {
            display: inline-block;
        }
    }
}

.configue-col-modal-project {
    .device-info-list {
        height: 370px !important;
    }
}

.chart-card {
    .bar {
        transform: scale(-8, 1);
        @include mq(sm-desktop) {
            transform: scale(-8, 1);
        }
        @include mq(md-desktop) {
            transform: scale(4, 1);
        }
        @include mq(lg-desktop) {
            transform: scale(1.6, 1);
        }
        @include mq(xlg-desktop) {
            transform: scale(1, 1);
        }
    }
}

.project-progress-container {
    width: 430px;
    margin-right: -109px;
    .progressbar {
        margin: 0;
        padding: 0;
        li {
            list-style-type: none;
            width: 25%;
            float: left;
            font-size: 13px;
            position: relative;
            text-align: center;
            font-weight: $font-med;
            line-height: 16px;
            &:after {
                width: 23px;
                height: 23px;
                content: "";
                line-height: 23px;
                display: block;
                text-align: center;
                margin: 0 auto;
                border-radius: 50%;
                background-color: $gray-light;
                position: relative;
                z-index: 2;
            }
            &:before {
                width: 95px;
                height: 5px;
                content: "";
                position: absolute;
                background-color: $gray-light;
                top: 26px;
                left: -43px;
                z-index: 1;
            }
            &:first-child {
                &:before {
                    content: none;
                }
            }
            &.active {
                color: $blue;
                &:before,
                &:after {
                    background-color: $blue;
                }
            }
        }
    }
    .project-progress-icon {
        position: absolute;
        top: 22px;
        left: 44%;
        color: white;
        font-size: 12px;
        z-index: 3;
    }
}

.download-dropdown {
    &.project-download-dropdown {
        width: 150px !important;
        width: max-content;
        .dropdown {
            .dropdown-toggle,
            .dropdown-menu {
                min-width: 100%;
                right: 0 !important;
                left: auto !important;
                .dropdown-item {
                    padding: 8px 10px;
                }
            }
        }
        em {
            margin-right: 2px;
        }
    }
}

.device-color-label {
    font-weight: $font-reg;
    font-size: 14px;
    color: $base-color;
    min-width: 96px;
}

.pin-color {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    &.system-type-panel {
        position: absolute;
        right: 12px;
        top: 12px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    }
    &.active {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            left: 1px;
            top: 1px;
            border: 1px solid $white;
            border-radius: 4px;
            -moz-border-radius: 4px;
            -webkit-border-radius: 4px;
            -ms-border-radius: 4px;
        }
    }
}

.device-color-form-group {
    .pin-color {
        cursor: pointer;
    }
}

.custom-color-panel {
    li {
        display: inline-block;
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.task-arrow {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 17px solid transparent;
    border-radius: 0;
    position: absolute;
    transform: translate(17px, 50px);
    z-index: 999;
}

.task-arrow-child {
    width: 0;
    height: 0;
    border-radius: 0;
    position: absolute;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-top: 9px solid transparent;
    transform: translate(9px, 31px) !important;
}

.Operation-task {
    .task-arrow,
    .task-arrow-child {
        border-top-color: $blue;
    }
}

.OperationalWithIssue-task {
    .task-arrow,
    .task-arrow-child {
        border-top-color: $yellow;
    }
}

.NotWorking-task {
    .task-arrow,
    .task-arrow-child {
        border-top-color: $red;
    }
}

.vertical-devider {
    position: relative;
    height: 50px;
    &::before {
        content: "";
        position: absolute;
        top: 1px;
        left: 50%;
        width: 1px;
        height: 100%;
        border-left: 1px solid $border-color;
    }
}

.list-card {
    padding: 15px;
    border-radius: 5px !important;
    margin-bottom: 15px;
    .count-list {
        list-style-type: none;
        margin-bottom: 0;
        li {
            padding: 0 10px;
            display: inline-block;
            position: relative;
            &:first-child {
                &:before {
                    border-left: none;
                }
            }
            // &:last-child {
            //     padding-right: 0;
               
            // }
            &.progress-percent {
                padding-left: 0;
                &:before {
                    border-left: none;
                }
            }
            &.progress-col {
                width: 100px;
                @include mq(min-desktop) {
                    width: 195px;
                }
                .project-progress-val {
                    padding-top: 0;
                    width: 100%;
                }
            }
            &:before {
                position: absolute;
                content: "";
                border-left: 1px solid $gray-light;
                left: 0;
                top: 3px;
                height: 14px;
            }
        }
    }
    .progress-col {
        width: 33%;
        .project-progress-val {
            padding-top: 7px;
            width: 70%;
            display: inline-block;
            margin-right: 15px;
            @include mq(laptop) {
                width: 55%;
            }
            .progress-block {
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 100px);
                float: none;
                padding-left: 0;
            }
            .progress {
                background-color: $gray-lighter;
                height: 8px;
                border-radius: 10px;
                margin-bottom: 0px;
                padding: 0px !important;
                box-shadow: none;
                width: 100%;
                transition: none;
                .progress-bar {
                    background: $blue;
                    transition: none;
                }
            }
            .project-percentage {
                display: inline-block;
                vertical-align: middle;
                float: none;
            }
        }
        .project-status-count {
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
            div {
                padding-left: 0;
                font-weight: $font-med;
            }
            .building-working-op {
                color: $blue;
                width: 33.3%;
                display: inline-block;
            }
            .building-working-opwith {
                color: $yellow;
                width: 33.3%;
                display: inline-block;
            }
            .building-working-not {
                color: $red;
                width: 33.3%;
                display: inline-block;
            }
        }
    }
}
.live-chart-card {
    display: inline-block;
    height: 250px;
    border-radius: 0 !important;
    &.live-bar-chart {
        height: 270px;
    }
    .ngx-charts-outer {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    svg {
        overflow: visible !important;
    }
    .legend-wrap {
        .legend-labels {
            background-color: transparent;
            text-align: center;
            white-space: pre-wrap;
            overflow: visible;
            li {
                margin: 4px 4px 0 4px;
                display: inline-block;
                &.legend-label {
                    .legend-label-color {
                        opacity: 0.8;
                    }
                    .legend-label-text {
                        font-size: 13px;
                        color: $anchor-color;
                    }
                    &:hover {
                    .legend-label-color {
                        opacity: 1;
                    }
                    .legend-label-text {
                        color: $anchor-hover;
                    }
                    }
                }
            }
        }
    }
    .bar-chart {
        width: 100% !important;
    }
}

 .project-progress-val {
            padding-top: 7px;
            width: 70%;
            display: inline-block;
            margin-right: 15px; 
            @include mq(laptop) {
                width: 65%;
            }
            .progress-block {
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 100px);
                float: none;
                padding-left: 0;
            }
            .progress {
                background-color: $gray-lighter;
                height: 8px;
                border-radius: 10px;
                margin-bottom: 0px;
                padding: 0px !important;
                box-shadow: none;
                width: 100%;
                transition: none;
                .progress-bar {
                    background: $blue;
                    transition: none;
                }
            }
            .project-percentage {
                display: inline-block;
                vertical-align: middle;
                float: none;
            }
        }