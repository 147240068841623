

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &::-moz-placeholder {opacity: 1; @content}
  &:-moz-placeholder {@content}
  &:-ms-input-placeholder {@content}
  &.placeholder {@content}
}

/// Font-size px to rem calc
/// @example scss - Usage
/// selector {
///    @include rem-font-size(16px);
/// }

@mixin rem-font-size($pxSize) {
  font-size: $pxSize;
  font-size: ($pxSize / 10px) + rem;
}
