// live device start
.drag-icon {
    --alpha: 1;
    &.site-device {
        background: rgba($primary-color, var(--alpha)) !important;
    }
}

.equipment-tooltip {
    .equipment-inner {
        &.tooltip-site-device {
            border: 2px solid $primary-color !important;
            &.active {
                box-shadow: 0 0 0 rgba(0, 54, 74, 0.5) !important;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                -ms-border-radius: 4px;
                -o-border-radius: 4px;
                background: $primary-color !important;
                .equip-part-name,
                .equip-part-number {
                    color: $white !important;
                }
            }
        }
    }
}

.pulse {
    &.pulse-site-device {
        &:after {
            box-shadow: 0 0 1px 4px $primary-color !important;
            -webkit-box-shadow: 0 0 1px 4px $primary-color !important;
            -ms-box-shadow: 0 0 1px 4px $primary-color !important;
            -moz-box-shadow: 0 0 1px 4px $primary-color !important;
            -o-box-shadow: 0 0 1px 4px $primary-color !important;
        }
    }
}

.badge {
    border: 2px solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
    &.site-device {
        background-color: rgba($primary-color, var(--alpha)) !important;
    }
    &.site-device-project {
        background-color: $primary-color;
    }
}

.filter-tabs {
    &.project-site-device-tabs {
        .nav-tabs {
            li {
                width: 50%;
            }
        }
    }
    .site-device-title {
        background: $primary-color;
        padding: 8px 0;
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin: -10px -13px 5px;
        line-height: 1;
        text-transform: uppercase;
    }
}

// live device end
.drag-blocks {
    left: 0;
    top: 0;
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
    z-index: 1 !important;
}

.cursorMove {
    z-index: 3;
}

.highLightIcon {
    z-index: 4;
}

.show-primary {
    opacity: 0.5;
}

.show-primary-pin {
    --alpha: 0.5;
    .equipment-icon {
        opacity: 0.5;
    }
}

// svg for move device
.line-move-svg-floor {
    left: 0;
    top: 0;
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
    z-index: 0 !important;
    pointer-events: none;
}

// svg for move device end
// svg for switches line
.switch-line {
    --alpha: 1;
    left: 0;
    top: 0;
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
    z-index: 1 !important;
    pointer-events: none;
    .outline {
        filter: drop-shadow(1px 0 0 $primary-color) drop-shadow(-1px 0 0 $primary-color)
            drop-shadow(0 1px 0 $primary-color) drop-shadow(0 -1px 0 $primary-color);
    }
    .cable-plus {
        &:hover {
            opacity: 1 !important;
        }
    }
    line {
        stroke-miterlimit: 0;
        position: absolute;
        &:before {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            background: $white;
            border: 1px solid $base-color;
        }
        &:hover {
            content: "+";
            position: absolute;
            left: 0;
            top: 0;
            font-size: 15px;
            color: red;
        }
    }
    g {
        pointer-events: visible;
        transform-box: fill-box;
        cursor: pointer;
    }
}

.secondary-active {
    background: transparent !important;
}

// svg for switches line end
.drag-device-pin {
    text-align: center;
    width: 52px;
    height: 52px;
    border-radius: 50% 50% 50% 0;
    transform: rotate(-45deg) !important;
    -webkit-transform: rotate(-45deg) !important;
    -moz-transform: rotate(-45deg) !important;
    -ms-transform: rotate(-45deg) !important;
    -o-transform: rotate(-45deg) !important;
    z-index: 1;
    padding: 3px;
    i {
        line-height: 32px;
        display: block;
        position: relative;
        z-index: 2;
        transform: rotate(45deg) !important;
        -webkit-transform: rotate(45deg) !important;
        &::before {
            padding-left: 5px;
            font-size: 34px;
            color: $white;
        }
    }
}

.drag-device-task {
    width: 52px;
    height: 52px;
    border-radius: 5px;
    border-top-color: currentColor;
    i {
        position: absolute;
        left: 9px;
        top: 1px;
        line-height: 35px;
        &::before {
            font-size: 32px;
            color: $white;
        }
    }
    .task-arrow {
        position: relative;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 11px solid;
        z-index: 999;
    }
}

.drag-device-pin,
.drag-device-task {
    &.Operational,
    &.Operation-task {
        background: $blue;
    }
    &.OperationalWithIssue,
    &.OperationalWithIssue-task {
        background: $yellow;
    }
    &.NotWorking,
    &.NotWorking-task {
        background: $red;
    }
}
.dragging {
    .rotate-360 {
        display: none !important;
    }
    .equipment-tooltip {
        display: none !important;
    }
    #secondary-device {
        display: none !important;
    }
    will-change: transform;
}
.device-active {
    z-index: 4 !important;
}

