label {
    font-weight: $font-med;
}

textarea.form-control,
input.form-control,
select.form-control {
    padding-left: 0;
    border: none;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}

input.form-control {
    -webkit-appearance: none !important;
}

select.form-control {
    -webkit-appearance: menulist !important;
}

.form-group {
    margin-bottom: 1rem;
    position: relative;
}

.form-check {
    min-height: auto !important;
}

.toast-title {
    word-wrap: break-word;
}

.watcher-field {
    .foundation-theme {
        tag:has(span.invalid) {
            border-color: #e00b0b;
        }
        tag {
            background: $white !important;
            border: 1px solid $base-color;
            border-radius: 5px !important;
            -webkit-border-radius: 5px !important;
            -moz-border-radius: 5px !important;
            box-shadow: none !important;
            margin: 0 0 4px 0;
        }

        tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
            box-shadow: none !important;
        }

        div {
            span {
                max-width: 312px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                padding-right: 30px;
                font-family: $base-font-family !important;
                line-height: 24px;
            }
            em {
                position: absolute !important;
                right: 5px;
                top: 4px !important;
                pointer-events: all !important;
            }
        }
    }

    .ng2-tag-input {
        border-bottom: none !important;

        input {
            background: transparent;

            &:focus {
                border-bottom: 1px solid $blue;
            }
        }
    }
}

.action-col {
    margin-bottom: 7px;
    margin-left: 15px;

    .radio-label {
        min-width: 150px;
        display: inline-block;

        label {
            font-size: 14px;
            font-weight: $font-reg;
        }
    }

    .action-label {
        font-style: italic;
        font-size: 12px;
    }
}

.fa-trash-alt {
    color: $red !important;

    &:hover {
        color: $dangerous-action-hover !important;
    }
}

// read only styling
input:disabled,
select:disabled,
select[disabled],
textarea:disabled,
textarea[disabled] {
    cursor: not-allowed !important;
    border: none !important;
    -webkit-appearance: none;
}

.placeholder {
    color: $placeholder-color;
    background-color: transparent;
    opacity: 1;
}

.placeholder-label {
    color: $base-color;
}

.ng2-tag-input--disabled {
    pointer-events: none !important;

    &.ng2-tag-input {
        border: none !important;
    }

    input {
        cursor: not-allowed !important;
        pointer-events: none !important;
        -webkit-appearance: none !important;
    }
}

.read-only-form {
    .form-group {

        input:disabled,
        input[disabled],
        select:disabled,
        select[disabled],
        textarea:disabled,
        textarea[disabled],
        .comment-desc {
            cursor: not-allowed !important;
            border: none !important;
            -webkit-appearance: none !important;
        }

        .comment-desc {
            height: max-content !important;
            max-height: 100px;
            overflow: auto;
        }

        .project-desc {
            max-height: 660px !important;
        }

        textarea[disabled] {
            min-height: max-content !important;
            padding: 0px !important;
            max-height: 100px;
            overflow: auto;
        }

        sup {
            display: none;
        }

        .placeholder-label {
            color: $placeholder-color;
        }
    }

    .mydp {
        border-bottom: none !important;

        /*for calendar */
        .selection {
            &::-webkit-input-placeholder {
                color: $placeholder-color !important;
            }

            &::-moz-placeholder {
                color: $placeholder-color !important;
            }

            &:-ms-input-placeholder {
                color: $placeholder-color !important;
            }
        }
    }

    .mydpicon,
    sup {
        display: none;
    }
}

.comment-desc {
    margin-bottom: 0px;

    span {
        line-height: 1;
        margin-bottom: 0px;
        white-space: pre-line;
    }
}

.part-list-search,
.filter-body {

    input:disabled,
    select:disabled,
    select[disabled] {
        border-bottom: 1px solid $gray-lighter !important;
    }
}

//created and update scss//
.form-group {
    .radio {
        label {
            line-height: 20px;
        }
    }

    label {
        font-size: 13px;
        color: $label-color;
        font-weight: $font-reg;
        line-height: 19px;
        margin-bottom: 0;

        sup {
            color: $red;
        }

        .btn-remove {
            position: absolute;
            right: 15px;
            bottom: 20px;
        }
    }

    .btn-status-group {
        label {
            font-size: 11px;
            line-height: 12px;
        }
    }

    .form-control,
    .selection {
        font-size: 14px;
        font-weight: $font-reg;
        color: $base-color;
        height: 24px;
        border-radius: 0px;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid $gray-lighter;
        padding-left: 0px;
        line-height: 17px;
        background: none;
        padding-bottom: 5px;
        margin-bottom: 10px;
        padding-top: 0px;

        option {
            padding: 0;
        }

        &:focus {
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            color: $placeholder-color;
        }

        &::-moz-placeholder {
            color: $placeholder-color;
        }

        &:-ms-input-placeholder {
            color: $placeholder-color;
        }

        &.ddl-select {
            border: none;
            border-bottom: 1px solid $gray-light;
            box-shadow: none;
            border-radius: 0;
            padding-left: 0px;
            line-height: 1.1;
            background: none;
            padding-bottom: 10px;
            height: 30px;
            padding-top: 0px;
            font-weight: $font-light;
            color: $base-color;
        }

        &.form-control-sm {
            max-width: 100px;
        }
    }

    .label-message {
        color: $placeholder-color;
        margin-bottom: 0 !important;
    }

    &.error-block {
        .help-block {
            color: $red;
        }
    }

    .form-control {
        &.ng-invalid {
            &:focus {
                border-color: $red;
            }
        }

        .help-block {
            color: $red;
        }
    }

    &.subject-error {
        .help-block {
            left: 0;
            z-index: 99;
            right: inherit;
            margin-top: 0;

            &:after,
            &:before {
                bottom: 100%;
                left: 20px;
                right: inherit;
                top: inherit;
            }

            &:before {
                border-bottom-color: $red;
                border-right-color: transparent;
                margin-left: -10px;
                margin-top: 0px;
            }

            &:after {
                border-bottom-color: $white;
                border-right-color: transparent;
                margin-left: -8px;
                margin-top: 0px;
            }
        }
    }

    &.select-floor {
        .help-block {
            max-width: 111px;
            right: inherit;
            margin-top: 15px;
            position: relative;

            &:after,
            &:before {
                bottom: 100%;
                left: 20px;
                right: inherit;
                top: inherit;
            }

            &:before {
                border-bottom-color: $red;
                border-right-color: transparent;
                margin-left: -10px;
                margin-top: 0px;
            }

            &:after {
                border-bottom-color: $white;
                border-right-color: transparent;
                margin-left: -8px;
                margin-top: 0px;
            }
        }
    }

    &.create-date,
    &.update-date {
        .mydrp {
            border-bottom: 1px solid $form-control-border;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0px;
            height: 30px;

            .selectiongroup {
                border-radius: 0px;

                .inputnoteditable {
                    height: 26px !important;
                    padding-right: 50px;

                    @include placeholder {
                        color: $base-color;
                        font-weight: $font-light;
                        font-family: $base-font-family;
                    }
                }

                .selbtngroup {
                    height: 26px !important;
                }
            }
        }
    }
}

//created and update date css//
.date-format {
    margin-bottom: 10px;

    label {
        display: block;
        width: auto;
        font-size: 13px;
    }

    .created-by {
        display: block;
        font-size: 13px;
        font-weight: $font-light;
        line-height: 1;
    }

    .created {
        float: left;
    }

    .created-date {
        float: right;
        text-align: right;
    }
}

.file-upload {
    margin-bottom: 10px;
    margin-top: 5px;
    text-align: center;

    .date-format {
        margin-bottom: 0px;
        display: inline-block;
        margin-left: 10px;
        text-align: left;

        .created {
            float: none;
            line-height: 1.2;
        }

        label {
            display: inline-block;
            margin-bottom: 0px;
            vertical-align: middle;
            line-height: 1.2;
            white-space: nowrap;
        }

        .created-by {
            font-size: 13px;
            font-weight: $font-light;
            line-height: 1.2;
            vertical-align: middle;
            display: inline-block;
            max-width: 140px;
            min-width: 40px;
            color: $label-color;
            margin-left: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.image-source {
                max-width: 320px !important;
                @include mq(sm-desktop) {
                    max-width: 516px !important;
                }
            }
        }
        
        .created-date {
            text-align: left;
            float: none;
            vertical-align: top;
            font-size: 13px;
            font-weight: $font-light;
            line-height: 1.2;
            color: $label-color;
            display: inline-block;
        }
    }
}



.linkified {
    z-index: 9;
    position: relative;
}

.linkified-textarea {
    overflow: auto;
    height: 80px !important;
}

.project-filter {
    .checkbox-label {
        &.uncheckall {
            input {
                &[type="checkbox"] {
                    &.lbl-check+label {
                        &:after {
                            content: "\f068";
                            overflow: auto;
                            opacity: 1;
                            color: $base-color;
                            max-width: inherit !important;
                            font-size: 8px !important;
                            margin: 0 0 0 4px !important;
                        }
                    }
                }
            }
        }
    }

    .panel-group {
        .panel {
            &.panel-open {
                .panel-heading {
                    position: relative;

                    &:before {
                        transform: rotate(90deg) !important;
                        -webkit-transform: rotate(90deg) !important;
                        -moz-transform: rotate(90deg) !important;
                        -ms-transform: rotate(90deg) !important;
                        left: 16px !important;
                    }
                }
            }

            border-radius: 0px;
            box-shadow: none;
            background: none;
            margin-top: 0px !important;
            margin-bottom: 0;
            border: none;

            .panel-default {
                padding-bottom: 0;
                min-height: 0;
                border: 0;

                .panel-heading {
                    background-color: transparent;
                    border: none;
                    border-radius: 0px;
                    padding: 0 15px 0 40px;
                    position: relative;

                    &:before {
                        font-family: "Font Awesome 5 Pro", sans-serif;
                        content: "\f105";
                        left: 19px;
                        top: 0;
                        position: absolute;
                        font-size: 26px;
                        line-height: 26px;
                        background: transparent;
                        cursor: pointer;
                        transform: rotate(0deg);
                        -webkit-transform: rotate(0deg);
                        -moz-transform: rotate(0deg);
                        -ms-transform: rotate(0deg);
                    }
                }

                .panel-body {
                    background: none;
                    border: none;
                    padding: 0;

                    .expand-body {
                        padding-left: 65px !important;
                    }
                }
            }
        }
    }

    .panel-open {
        .panel-collapse {
            .expand-body {
                padding-left: 25px !important;

                .expand-body {
                    padding-left: 65px !important;
                }

                .panel-heading {
                    &:before {
                        transform: rotate(0deg) !important;
                        -webkit-transform: rotate(0deg) !important;
                        -moz-transform: rotate(0deg) !important;
                        -ms-transform: rotate(0deg) !important;
                    }
                }

                .panel-open {
                    .panel-heading {
                        &:before {
                            transform: rotate(90deg) !important;
                            -webkit-transform: rotate(90deg) !important;
                            -moz-transform: rotate(90deg) !important;
                            -ms-transform: rotate(90deg) !important;
                        }
                    }
                }
            }
        }
    }

    .checkbox-label {
        display: inline-block;

        label {
            line-height: 24px !important;
            padding-left: 25px !important;

            &:before {
                font-size: 15px !important;
            }

            &:after {
                font-size: 9px !important;
                margin: 1px 0 0 3px !important;
                padding: 0 !important;
            }
        }
    }

    .download-option-list {
        padding: 0 15px;
    }
}

.not-allowed {
    cursor: not-allowed !important;
}

.multiselect-dropdown {
    .dropdown-btn {
        border-left: inherit !important;
        border-top: inherit !important;
        border-right: inherit !important;
        border-radius: 0 !important;
        padding: 5px 0 !important;

        .dropdown-multiselect__caret {
            right: 4px !important;
            top: -2px !important;

            &::before {
                border: none !important;
                position: absolute !important;
                top: 5px !important;
                content: "\f107" !important;
                font-family: "Font Awesome 5 Pro", sans-serif !important;
                font-size: 14px !important;
                line-height: 14px !important;
                color: $base-color !important;
                font-weight: 600;
            }
        }

        .dropdown-multiselect--active {
            .dropdown-multiselect__caret {
                width: 33px !important;
                height: 30px !important;

                &::before {
                    right: 22px !important;
                }
            }
        }

        .selected-item-container {
            .selected-item {
                border: 1px solid $base-color !important;
                margin: 8px 8px 0 0 !important;
                background: $white !important;
                color: $base-color !important;
                border-radius: 5px !important;
                max-width: max-content !important;
                padding: 2px 8px !important;

                &:hover {
                    box-shadow: none !important;
                }

                a {
                    font-size: 14px !important;
                    font-weight: 700 !important;
                    color: $base-color !important;
                }
            }
        }
    }

    .dropdown-list {
        overflow: hidden;
        padding-top: 0 !important;

        .multiselect-item-checkbox {
            &:hover {
                background: transparent;
            }

            input {
                &+div {
                    &::before {
                        border: 2px solid $base-color !important;
                        border-radius: 2px !important;
                        color: $base-color !important;
                    }

                    &::after {
                        color: $base-color !important;
                    }
                }

                &:checked {
                    &+div {
                        &::before {
                            background: transparent !important;
                        }

                        &::after {
                            border-color: $base-color !important;
                            border-radius: 1px !important;
                            width: 7px !important;
                        }
                    }
                }
            }

            &.uncheckall {
                input {
                    &+div {
                        &::after {
                            border-color: transparent;
                            border-radius: 1px !important;
                            font-family: "Font Awesome 5 Pro", sans-serif !important;
                            content: "\f068" !important;
                            left: 4px;
                            top: 5px !important;
                            font-size: 14px;
                            border: none !important;
                            transform: rotate(0deg) !important;
                        }
                    }
                }
            }

            .filter-textbox {
                input {
                    &::before {
                        border: none !important;
                        position: absolute !important;
                        top: 5px !important;
                        content: "\f107" !important;
                        font-family: "Font Awesome 5 Pro", sans-serif !important;
                        font-size: 20px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }

        .filter-textbox {
            input {
                &::placeholder {
                    color: $placeholder-color !important;
                }
            }

            &::after {
                position: absolute !important;
                top: 12px !important;
                left: 12px !important;
                content: "\f002" !important;
                font-family: "Font Awesome 5 Pro", sans-serif !important;
                color: $primary-color !important;
                font-size: 16px !important;
                line-height: 16px !important;
                font-weight: $font-bold !important;
            }
        }
    }

    .no-filtered-data {
        h5 {
            font-size: 14px;
            font-weight: $font-reg;
            margin-bottom: 0;
        }
    }
}

.form-check {
    .form-check-input {
        margin-left: 0;
        width: 32px;
        height: 15px;
        background-color: $gray !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        border: none;

        &:focus {
            background-color: $blue !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
            box-shadow: inherit !important;
        }

        input {
            &[type="checkbox"]:checked {
                background-color: $blue !important;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
            }
        }

        &[type="checkbox"]:not(:checked) {
            background-color: $gray !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        }
    }
}

.form-switch .form-check-input:checked {
    background-color: $blue !important;
}

.inline-editor {
    .form-control {
        border-bottom: 1px solid $gray;
        font-weight: $font-reg;
        font-size: 14px;
        color: $base-color;
        box-shadow: none;
        border-right: none;
        border-radius: 0px;
        border-top: none;
        border-left: none;
        background: none;
        padding: 0px;
        line-height: 12px;

        &:focus {
            border-bottom: 1px solid $blue;
        }

        &::-webkit-input-placeholder {
            color: $placeholder-color;
        }

        &::-moz-placeholder {
            color: $placeholder-color;
        }

        &:-ms-input-placeholder {
            color: $placeholder-color;
        }
    }

    .form-error {
        border-bottom: 1px solid $red;
    }
}

.audit-note {
    min-height: 95px !important;
}

/* For regular autofill */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
}

/* For autofill with focus */
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
}

.multiselect-dropdown {
    .dropdown-btn {
        line-height: 13px !important;
        border-bottom: 1px solid $gray-lighter !important;
    }

    .disabled {
        pointer-events: none;

        span {
            background-color: transparent !important;
        }

        .dropdown-btn {
            cursor: not-allowed !important;
        }
    }
}