
.guideContainer {
    circle {
        z-index: 1;
        transform-box: fill-box;
        &.selectedNode,
        &:hover {
            fill: $white !important;
        }
    }
    circle:last-child,
    circle:first-child {
        opacity: 0;
    }
}

.switch-line {
    --alpha: 1;
    left: 0;
    top: 0;
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
    z-index: -1 !important;
    pointer-events: none;
    &.svg-zindex {
        z-index: 2 !important;
    }
    &.svg-selected-zindex {
        z-index: 3 !important;
    }
    line {
        stroke-miterlimit: 0;
        position: absolute;
        &:before {
            content: "circle";
            position: absolute;
            width: 100%;
            height: 100%;
            background: $white;
            border: 1px solid $base-color;
            background-image: url("/assets/images/logo-smal-new.svg");
            display: block;
            left: 0;
            top: 0;
        }
    }
    g {
        pointer-events: visible;
        transform-box: fill-box;
        cursor: grab;
    }
    text {
        font-size: 28px;
        line-height: 28px;
        font-weight: 400;
        height: 26px;
        transform: translate(0px, 2px);
    }
}

.set-scale {
    z-index: 0 !important;
}
