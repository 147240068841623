.font-light {
    font-weight: $font-light;
}

.font-reg {
    font-weight: $font-reg;
}

.font-semi {
    font-weight: $font-semi;
}

.font-bold {
    font-weight: $font-bold;
}

.font-med {
    font-weight: $font-med;
}

b,
strong {
    font-weight: $font-med;
}

th {
    font-weight: $font-med;
}

.div-table {
    display: table;
    text-align: center;
    width: 100%;
    height: 100%;
    table-layout: fixed;
}

.div-table-cell {
    display: table-cell;
    vertical-align: middle;
}

a:focus,
a:hover {
    outline: none;
    outline-offset: 0px;
}

.help-block {
    color: $red;
    background: $white;
    border: 2px solid $red;
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    max-width: 400px;
    line-height: 1.1;
    font-size: 12px;
    right: 15px;
    margin-top: -53px;
    z-index: 9;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:before,
    &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:after {
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $white;
        border-width: 8px;
        margin-top: -8px;
    }
    &::before {
        border-right-color: $red;
        border-width: 10px;
        margin-top: -10px;
    }
    &.left {
        &:before,
        &:after {
            right: inherit;
            left: 100%;
            transform: rotate(180deg);
        }
    }
    &.bottom {
        left: 15px;
        z-index: 11;
        right: inherit;
        margin-top: 8px;
        &:after,
        &:before {
            bottom: 100%;
            left: 20px;
            right: inherit;
            top: inherit;
        }
        &:before {
            border-bottom-color: $red;
            border-right-color: transparent;
            margin-left: -10px;
            margin-top: 0px;
        }
        &:after {
            border-bottom-color: $white;
            border-right-color: transparent;
            margin-left: -8px;
            margin-top: 0px;
        }
    }
}

.login-form-group {
    .help-block {
        @include mq(mobile) {
            right: 0;
            bottom: 34px;
            left: 0;
            padding: 7px 10px;
            width: max-content;
            max-width: 100%;
            text-align: left;
        }
        &:before {
            @include mq(mobile) {
                left: 25px;
                -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
                top: calc(100% - -10px);
            }
        }
        &:after {
            @include mq(mobile) {
                margin-top: -6px;
                left: 27px;
                -webkit-transform: rotate(90deg);
                transform: rotate(-90deg);
                top: calc(100% - -5px);
            }
        }
    }
}

.bg-danger {
    background-color: $red !important;
}

.bg-warning {
    background-color: $yellow !important;
}

.bg-info {
    background-color: $blue !important;
}

.profile-popup {
    .form-group {
        &.filename-block {
            .help-block {
                width: 287px !important;
                left: 30px !important;
                &:after,
                &:before {
                    left: 258px !important;
                }
            }
        }
    }
    .modal-footer {
        &.profile-footer {
            .btn+.btn {
                margin-left: 8px !important;
            }
        }
    }
}

.select-plan-validation-block {
    .help-block {
        right: -55px;
        z-index: 9;
        margin-top: -48px;
    }
    &.site-validation-block {
        .help-block {
            right: -5px;
        }
    }
}
.min-auto {
    min-width: auto !important;
}