.ng2-tag-input {
  min-height: 30px !important;
  padding: 0px !important;
  border-bottom: 1px solid $gray-lighter !important;
}

.ng2-tag-input__text-input {
  width: 100% !important;
  height: 26px !important;
  font-family: $base-font-family !important;
  padding: 0px !important;
}

.ng2-tag-input.foundation-theme tag {
  height: 24px !important;
  line-height: 26px !important;
  letter-spacing: 0px !important;
  padding: 0px 5px 0 !important;
  border: 1px solid $base-color;
  border-radius: 5px !important;
  white-space: nowrap;
  max-width: 600px;
  margin-bottom: 5px;
}

.ng2-tag-input.foundation-theme tag div {
  line-height: 20px !important;
  span {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    float: left;
    margin-right: 5px;
  }
}

.ng2-tag-input.foundation-theme tag .fa-close {
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.ng2-tag-input.foundation-theme tag:hover,
.ng2-tag-input.foundation-theme tag:focus {
  background: none !important;
}

.ng2-tag-input.foundation-theme tag:not(.readonly):not(.tag--editing):focus {
  background: none !important;
  color: initial !important;
}
.ng2-dropdown-menu__options-container {
  width: 340px !important;
  max-height: 95px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  padding: 0;
  .ng2-menu-item {
    line-height: normal !important;
    border-bottom: 1px solid $gray-lighter !important;
    width: 340px !important;
    padding: 5px;
    &:hover {
      background: $gray-lightest;
    }
    .user-profile-block {
      .user-profile-img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 0;
        float: left;
        margin-left: 0;
        position: relative;
        left: -15px;
        img {
          width: auto;
          height: 40px;
          object-fit: cover;
        }
      }
      .user-desc {
        display: inline-block;
        margin-top: 5px;
        .user-name {
          font-size: 14px;
          line-height: 1.2;
          display: block;
          color: $base-color;
          font-weight: $font-med;
          margin-bottom: 2px;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          width: 250px;
        }
        .user-email {
          display: block;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          width: 250px;
        }
      }
    }
  }
}
.ng2-tag-input.foundation-theme tag-input-form {
  width: 100% !important;
}

.ng2-dropdown-menu {
  z-index: 1501 !important;
  width: 340px !important;
  padding: 0 !important;
  max-height: 95px !important;
}
.ng2-tag-input.foundation-theme tag.removeAnimation {
  transition: transform 650ms linear;
  background: $off-white !important;
  transform: translateY(-200px);
  left: 0 !important;
}
