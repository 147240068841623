// Typography

/* set default styles in all browsers */

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    display: block;
    font-weight: $font-med;
}

h1,
.h1 {
    font-size: $h1-font-size;
    line-height: 1.25;
}

h2,
.h2 {
    font-size: $h2-font-size;
    line-height: 24px;
}

h3,
.h3 {
    font-size: $h3-font-size;
}

h4,
.h4 {
    font-size: $h4-font-size;
}

p {
    margin: 0 0 15px;
}

/* set styles for default page links */

a {
    color: $anchor-color !important;
    text-decoration: none;
    &:hover,
    &:focus {
        color: $anchor-hover !important;
    }
}

.font-regular {
    font-weight: $font-reg !important;
}