@font-face {
    font-family: "siteowl";
    src: url($my-cdn-base-path + "assets/fonts/siteowl.eot?24804961");
    src:
        url($my-cdn-base-path + "assets/fonts/siteowl.eot?24804961#iefix") format("embedded-opentype"),
        url($my-cdn-base-path + "assets/fonts/siteowl.woff2?24804961") format("woff2"),
        url($my-cdn-base-path + "assets/fonts/siteowl.woff?24804961") format("woff"),
        url($my-cdn-base-path + "assets/fonts/siteowl.ttf?24804961") format("truetype"),
        url($my-cdn-base-path + "assets/fonts/siteowl.svg?24804961#siteowl") format("svg");
    font-weight: normal;
    font-style: normal;
}

.equipment-icon {
    font-family: "siteowl";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    font-size: 50px;
    text-align: center;
    color: $black;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-shooter-detection {
    font-size: 38px;
}

.icon-wire-mold:before { content: '\e800'; } /* '' */
.icon-trenching:before { content: '\e801'; } /* '' */
.icon-training:before { content: '\e802'; } /* '' */
.icon-electrical-panels:before { content: '\e803'; } /* '' */
.icon-conduit:before { content: '\e804'; } /* '' */
.icon-electrical-work:before { content: '\e805'; } /* '' */
.icon-penetrations:before { content: '\e806'; } /* '' */
.icon-programming:before { content: '\e807'; } /* '' */
.icon-emergency-phone-towers:before { content: '\e808'; } /* '' */
.icon-emergency-phone-wall:before { content: '\e809'; } /* '' */
.icon-shooter-detection:before { content: '\e80a'; } /* '' */
.icon-ptz-camera:before { content: '\e80b'; } /* '' */
.icon-nvr-dvr:before { content: '\e80c'; } /* '' */
.icon-panoramic-camera:before { content: '\e80d'; } /* '' */
.icon-injector:before { content: '\e80e'; } /* '' */
.icon-ir-illuminator:before { content: '\e80f'; } /* '' */
.icon-mass-notification-system:before { content: '\e810'; } /* '' */
.icon-power-supply:before { content: '\e811'; } /* '' */
.icon-fixed-camera:before { content: '\e812'; } /* '' */
.icon-video-monitor:before { content: '\e813'; } /* '' */
.icon-network-switch:before { content: '\e814'; } /* '' */
.icon-door-release:before { content: '\e815'; } /* '' */
.icon-panel:before { content: '\e816'; } /* '' */
.icon-request-to-exit-button:before { content: '\e817'; } /* '' */
.icon-microphone:before { content: '\e818'; } /* '' */
.icon-encoder:before { content: '\e819'; } /* '' */
.icon-general-component:before { content: '\e81a'; } /* '' */
.icon-workstation:before { content: '\e81b'; } /* '' */
.icon-double-door:before { content: '\e81c'; } /* '' */
.icon-network-server:before { content: '\e81d'; } /* '' */
.icon-single-door:before { content: '\e81e'; } /* '' */
.icon-request-to-exit-motion:before { content: '\e81f'; } /* '' */
.icon-card-reader:before { content: '\e820'; } /* '' */
.icon-door-contact:before { content: '\e821'; } /* '' */
.icon-electrified-hardware:before { content: '\e822'; } /* '' */
.icon-alarm-sounder:before { content: '\e823'; } /* '' */
.icon-expansion-board:before { content: '\e824'; } /* '' */
.icon-intercom-end-point:before { content: '\e825'; } /* '' */
.icon-intercom-master:before { content: '\e826'; } /* '' */
.icon-doorloop:before { content: '\e827'; } /* '' */
.icon-dome-camera:before { content: '\e828'; } /* '' */
.icon-mortise:before { content: '\e829'; } /* '' */
.icon-surface-mount-vertical-rods:before { content: '\e82a'; } /* '' */
.icon-electrification-kit:before { content: '\e82b'; } /* '' */
.icon-turret-camera:before { content: '\e82c'; } /* '' */
.icon-mag-lock:before { content: '\e82d'; } /* '' */
.icon-el-rx:before { content: '\e82e'; } /* '' */
.icon-hinge:before { content: '\e82f'; } /* '' */
.icon-keypad:before { content: '\e830'; } /* '' */
.icon-motion-detector:before { content: '\e831'; } /* '' */
.icon-beam-sensor:before { content: '\e832'; } /* '' */
.icon-panel-1:before { content: '\e833'; } /* '' */
.icon-general-icon-2:before { content: '\e834'; } /* '' */
.icon-alarm-strobe:before { content: '\e835'; } /* '' */
.icon-zone-expander-system-expansion:before { content: '\e836'; } /* '' */
.icon-panic-button:before { content: '\e837'; } /* '' */
.icon-general-icon-1:before { content: '\e838'; } /* '' */
.icon-temperature-sensor:before { content: '\e839'; } /* '' */
.icon-glass-break:before { content: '\e83a'; } /* '' */
.icon-enclosure:before { content: '\e83b'; } /* '' */
.icon-surface-mount-strike:before { content: '\e83c'; } /* '' */
.icon-ups-power:before { content: '\e83d'; } /* '' */
.icon-server:before { content: '\e83e'; } /* '' */
.icon-rack:before { content: '\e83f'; } /* '' */
.icon-recessed-strike:before { content: '\e840'; } /* '' */
.icon-fiber-patch-cable:before { content: '\e841'; } /* '' */
.icon-poe-extender:before { content: '\e842'; } /* '' */
.icon-cable:before { content: '\e843'; } /* '' */
.icon-cylindrical-lever:before { content: '\e844'; } /* '' */
.icon-wireless-hub:before { content: '\e845'; } /* '' */
.icon-general-communication:before { content: '\e846'; } /* '' */
.icon-concealed-vertical-rods:before { content: '\e847'; } /* '' */
.icon-network-router:before { content: '\e848'; } /* '' */
.icon-bullet-camera:before { content: '\e849'; } /* '' */
.icon-help:before { content: '\e84a'; } /* '' */
.icon-gate:before { content: '\e84b'; } /* '' */
.icon-gate-operator:before { content: '\e84c'; } /* '' */
.icon-occupancy-sensor:before { content: '\e84d'; } /* '' */
.icon-overhead-door:before { content: '\e84e'; } /* '' */
.icon-overhead-door-contact:before { content: '\e84f'; } /* '' */
.icon-elevator:before { content: '\e850'; } /* '' */
.icon-door-signal-light:before { content: '\e851'; } /* '' */
.icon-occupancy-sensor-intrusion-detection:before { content: '\e852'; } /* '' */
.icon-revolving-door:before { content: '\e853'; } /* '' */
.icon-audio-visual-task:before { content: '\e854'; } /* '' */
.icon-antenna:before { content: '\e855'; } /* '' */
.icon-audio-source:before { content: '\e856'; } /* '' */
.icon-amplifier:before { content: '\e857'; } /* '' */
.icon-av-receiver:before { content: '\e858'; } /* '' */
.icon-console:before { content: '\e859'; } /* '' */
.icon-control-panel:before { content: '\e85a'; } /* '' */
.icon-detector:before { content: '\e85b'; } /* '' */
.icon-end-of-line:before { content: '\e85c'; } /* '' */
.icon-extinguisher:before { content: '\e85d'; } /* '' */
.icon-fire-alarm-task:before { content: '\e85e'; } /* '' */
.icon-fire-department-connection:before { content: '\e85f'; } /* '' */
.icon-security-trailer:before { content: '\e860'; } /* '' */
.icon-flow-switch:before { content: '\e861'; } /* '' */
.icon-gauge:before { content: '\e862'; } /* '' */
.icon-general-audio-visual-:before { content: '\e863'; } /* '' */
.icon-general-fire:before { content: '\e864'; } /* '' */
.icon-hydrant:before { content: '\e865'; } /* '' */
.icon-lighting:before { content: '\e866'; } /* '' */
.icon-lighting-control:before { content: '\e867'; } /* '' */
.icon-media-player:before { content: '\e868'; } /* '' */
.icon-meter:before { content: '\e869'; } /* '' */
.icon-outlet:before { content: '\e86a'; } /* '' */
.icon-phone:before { content: '\e86b'; } /* '' */
.icon-projector:before { content: '\e86c'; } /* '' */
.icon-projector-screen:before { content: '\e86d'; } /* '' */
.icon-pull-station:before { content: '\e86e'; } /* '' */
.icon-relay:before { content: '\e86f'; } /* '' */
.icon-sign:before { content: '\e870'; } /* '' */
.icon-speaker:before { content: '\e871'; } /* '' */
.icon-sprinkler-head:before { content: '\e872'; } /* '' */
.icon-subwoofer:before { content: '\e873'; } /* '' */
.icon-tamper-switch:before { content: '\e874'; } /* '' */
.icon-terminal:before { content: '\e875'; } /* '' */
.icon-thermometer:before { content: '\e876'; } /* '' */
.icon-valve:before { content: '\e877'; } /* '' */
.icon-vent:before { content: '\e878'; } /* '' */
.icon-video-screen-:before { content: '\e879'; } /* '' */
.icon-video-source:before { content: '\e87a'; } /* '' */
.icon-volume-control:before { content: '\e87b'; } /* '' */
.icon-wireless-access-point:before { content: '\e87c'; } /* '' */
.icon-fire-alarm-sounder:before { content: '\e87d'; } /* '' */
.icon-fire-alarm-strobe:before { content: '\e87e'; } /* '' */
.icon-fire-panel:before { content: '\e880'; } /* '' */
.icon-fire-power-supply:before { content: '\e881'; } /* '' */
.icon-fire-mass-notification-system:before { content: '\e882'; } /* '' */
.icon-av-workstation:before { content: '\e883'; } /* '' */
.icon-av-microphone:before { content: '\e885'; } /* '' */
.icon-device:before { content: '\e886'; } /* '' */
.icon-task:before { content: '\e887'; } /* '' */
.icon-circle:before { content: '\e888'; } /* '' */
.icon-general-infrastructure:before { content: '\e8a0'; } /* '' */
.icon-electrical-panel-task:before { content: '\e8a5'; } /* '' */
.icon-training-task:before { content: '\e8a6'; } /* '' */
.icon-programming-task:before { content: '\e8a7'; } /* '' */
.icon-penetrations-task:before { content: '\e8a8'; } /* '' */
.icon-general-task:before { content: '\e8a9'; } /* '' */
.icon-infrastructure-task:before { content: '\e8aa'; } /* '' */
.icon-communications-task:before { content: '\e8ab'; } /* '' */
.icon-intrusion-detection-task:before { content: '\e8ac'; } /* '' */
.icon-access-control-task:before { content: '\e8ad'; } /* '' */
.icon-video-surveillance-task:before { content: '\e8ae'; } /* '' */
.icon-smart-cabinet:before { content: '\e8af'; } /* '' */
.icon-camera-accessory:before { content: '\e908'; } /* '' */
.icon-cellular-communicator:before { content: '\e909'; } /* '' */
.icon-controller:before { content: '\e90a'; } /* '' */
.icon-display-wall:before { content: '\e90b'; } /* '' */
.icon-fire-alarm-speaker:before { content: '\e90c'; } /* '' */
.icon-fire-horn-strobe:before { content: '\e90d'; } /* '' */
.icon-fisheye-camera:before { content: '\e90f'; } /* '' */
.icon-general-detector:before { content: '\e910'; } /* '' */
.icon-general-sensor:before { content: '\e912'; } /* '' */
.icon-intercom:before { content: '\e913'; } /* '' */
.icon-lpr-camera:before { content: '\e914'; } /* '' */
.icon-junction-box:before { content: '\e915'; } /* '' */
.icon-lens:before { content: '\e916'; } /* '' */
.icon-mount:before { content: '\e917'; } /* '' */
.icon-network-jack:before { content: '\e918'; } /* '' */
.icon-patch-panel:before { content: '\e919'; } /* '' */
.icon-communication-power-supply:before { content: '\e91a'; } /* '' */
.icon-communication-server:before { content: '\e91b'; } /* '' */
.icon-siren:before { content: '\e91c'; } /* '' */
.icon-fire-speaker-strobe:before { content: '\e91d'; } /* '' */
.icon-turnstile:before { content: '\e91e'; } /* '' */
.icon-video-intercom:before { content: '\e91f'; } /* '' */
.icon-window-contact:before { content: '\e920'; } /* '' */
.icon-wireless-lock:before { content: '\e921'; } /* '' */
.icon-wireless-lock-gateway:before { content: '\e922'; } /* '' */
.icon-audio-mount:before { content: '\e923'; } /* '' */
.icon-automatic-door:before { content: '\e924'; } /* '' */
.icon-device-pin:before { content: '\ea14'; } /* '' */
