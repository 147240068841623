.context-menu {
  width: 300px;
  z-index: 10;
  padding: 8px 0;
  user-select: none;
  border-radius: 4px;

  .context-menu-item {
    width: 100%;
    background-color: inherit;
    border: none;
    font-size: 16px;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    &:disabled {
      cursor: default;
    }
    .mat-icon {
      margin-right: 16px;
    }
    .alt-text {
      margin-left: auto;
      font-size: 14px;
    }
    .indent {
      margin-left: 40px;
    }
  }
  .separator {
    background-color: rgba(0, 0, 0, 0.11);
    margin: 8px 0;
    height: 1px;
    max-height: 1px;
    min-height: 1px;
    width: 100%;
  }
}
