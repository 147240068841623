/* Color scheme */

$black: #000000;
$white: #FFFFFF;
$base-color: #262626;
// $dk-gray: #262626;
$primary-color: #003F77;
// $dark-blue: #003F77;
$anchor-color: #003F77;
$anchor-hover: #00B2F8;
$dark-color: #003F77;
$yellow: #E8BC0A;
// $secondary-color: #E8BC0A; 
$red: #DA2955;
// $alert-danger-color: #DA2955;
// $lt-red: #f02841;
$green: #4EDD8E;
$blue: #00B2F8;
// $dk-blue: #00B2F8;
$purple: #a4a1fb;
$placeholder-color: #889da4;
$label-color: #68828a;
$lt-white: #f2f2f2;
$off-white: #f5f5f5;
$lt-gray: #ececec;
// $dash-gray: #ececec;
// $active-gray: #ececec;


$border-color: #cccccc;
// $bg-gray: #cccccc;
// $border-alt: #cccccc;
// $table-border: #cccccc;

$table-head-color: #343c4b;
$table-head-border: #c4c4c4;
$table-body-border: #ebebeb;
$low-color: #343c4b;
$form-control-border: #a4aaba;
$focus-color: #66afe9;

/**** new variable   ****/

$gray-darker: #262626;
$gray-dark: #68828a;
$gray: #889da4;
// $remove-color: #889da4;
// $lt-cl-gray: #889da4;

$gray-light: #bbc5c9;
$gray-lighter: #d4dfe2;
$gray-lightest: #eef6f6;
$primary-action: #00B2F8;
$primary-action-hover: #008ED1;
$primary-action-active: #006BAB;
$secondary-action: #bbc5c9;
$secondary-action-hover: #dde7eb;
$secondary-action-active: #99a3a7;
$dangerous-action: $red;
$dangerous-action-hover: #FB4C6E;
$dangerous-action-active: #D72d58;
$cta-action: #59c7a8;
$cta-action-hover: #6edeaf;
$cta-action-active: #45bb9b;
$basic-action: $anchor-color;
$basic-action-hover: $anchor-hover;
$basic-action-active: $anchor-hover;


$base-font-family: "Fira Sans", sans-serif;
$base-font-size: 14px;
$font-light: 300;
$font-light-italic: "300i";
$font-reg: 400;
$font-reg-italic: "400i";
$font-med: 500;
$font-med-italic: "500i";
$font-semi: 600;
$font-semi-italic: "600i";
$font-bold: 700;
$font-bold-italic: "700i";
$h1-font-size: 22px;
$h2-font-size: 20px;
$h3-font-size: 15px;
$h4-font-size: 20px;

/**** Variables in queries, using @content  ****/

$break-small: 767px;
$break-tablet: 768px;
$break-tablet-xl: 992px;
$break-desktop: 1200px;
$min-desktop: 1340px;
$laptop: 1500px;
$sm-desktop: 1536px;
$md-desktop: 1700px;
$lg-desktop: 1800px;
$xlg-desktop: 1900px;



/**** spacer  ****/

$spacer: 15px;

@mixin mq($media) {
    @if $media==mobile {
        @media only screen and (max-width: $break-small) {
            @content;
        }
    }

    @else if $media==tablet {
        @media only screen and (min-width: $break-tablet) {
            @content;
        }

        //@media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
    }

    @else if $media==tablet-xl {
        @media only screen and (min-width: $break-tablet-xl) {
            @content;
        }
    }

    @else if $media==desktop {
        @media only screen and (min-width: $break-desktop) {
            @content;
        }
    }

    @else if $media==min-desktop {
        @media only screen and (min-width: $min-desktop) {
            @content;
        }
    }

    @else if $media==laptop {
        @media only screen and (min-width: $laptop) {
            @content;
        }
    }

    @else if $media==sm-desktop {
        @media only screen and (min-width: $sm-desktop) {
            @content;
        }
    }

    @else if $media==md-desktop {
        @media only screen and (min-width: $md-desktop) {
            @content;
        }
    }

    @else if $media==lg-desktop {
        @media only screen and (min-width: $lg-desktop) {
            @content;
        }
    }

    @else if $media==xlg-desktop {
        @media only screen and (min-width: $xlg-desktop) {
            @content;
        }
    }
}

@mixin background-opacity($color, $opacity: 0.8) {
    background: $color !important;
    /* The Fallback */
    background: rgba($color, $opacity) !important;
}

@mixin border-opacity($color, $opacity: 0.8) {
    /* The Fallback */
    border-top-color: rgba($color, $opacity) !important;
}

@mixin shadow-opacity($shadow) {
    box-shadow: $shadow !important;
    -webkit-box-shadow: $shadow !important;
    -ms-box-shadow: $shadow !important;
    -moz-box-shadow: $shadow !important;
    -o-box-shadow: $shadow !important;
}