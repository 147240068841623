// Profile page
.file-upload-img {
    padding: 0 0 15px;
    max-width: 466px;
    max-height: 350px;
    margin: 0 auto;
}

.btn-profile-camera {
    width: 70px;
    height: 70px; 
    background: $white;
    display: block;
    border: none;
    margin: 0 auto;
    text-align: center;
    &:focus {
        outline: none;
    }
    i,
    em {
        line-height: 70px;
        font-size: 60px;
        color: $blue;
    }
}

.modal-profile {
    padding-bottom: 0px;
}

.modal-dialog {
    &.modal-profile {
        width: 450px;
    }
}

.alt-text {
    display: block;
    font-weight: $font-med;
}

// for add resource dialog box
.add-resource-block {
    .resource-listing {
        border: 1px solid $gray-light;
        height: 250px;
        overflow-y: auto;
        padding-top: 5px;
        margin-top: 5px;
        div {
            line-height: normal;
        }
        p {
            font-size: 16px;
            font-weight: $font-med;
            line-height: 1.2;
            margin-top: 10px;
            margin-bottom: 0px;
            margin-left: 10px;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                cursor: pointer;
                padding: 7px 10px;
                background: $white;
                position: relative;
                &:hover {
                    background: $blue;
                    color: $white;
                }
            }
        }
    }
}

.draw {
    transition: color 0.25s;
    box-sizing: border-box;
    position: relative;
    &::before,
    &::after {
        // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
        border: 2px solid transparent;
        width: 0;
        height: 0;
        position: absolute;
        content: "";
        box-sizing: inherit;
    }
    // This covers the top & right borders (expands right, then down)
    &::before {
        top: 0;
        left: 0;
    }
    // And this the bottom & left borders (expands left, then up)
    &::after {
        bottom: 0;
        right: 0;
    }
    &:hover {
        color: $dark-color;
    }
    // Hover styles
    &:hover::before,
    &:hover::after {
        width: 100%;
        height: 100%;
    }
    &:hover::before {
        border-top-color: $dark-color; // Make borders visible
        border-right-color: $dark-color;
        transition: width 0.25s ease-out, // Width expands first
        height 0.25s ease-out 0.25s; // And then height
    }
    &:hover::after {
        border-bottom-color: $dark-color; // Make borders visible
        border-left-color: $dark-color;
        transition: border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
        width 0.25s ease-out 0.5s, // And then exanding width
        height 0.25s ease-out 0.75s; // And finally height
    }
}

// Project Overview Report dailog
.download-checkbox-list {
    &.download-option-list {
        border: none !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .checkbox-label {
        display: block;
    }
}

// Project - Dashboard Design start
.project-collapse-block {
    background: $white;
    .project-heading {
        border-bottom: 1px solid $gray-light;
        padding: 10px 0;
        div {
            float: left;
            color: $label-color;
            padding: 0 15px;
            font-size: 14px;
            font-weight: $font-reg;
            white-space: nowrap;
        }
        .project-name {
            width: 18%;
        }
        .icon-information {
            width: 4%;
        }
        .customer-name {
            width: 13%;
        }
        .project-manager {
            width: 15%;
        }
        .design-status {
            width: 12%;
        }
        .plans,
        .devices,
        .hours-left {
            width: 7%;
        }
        .punch {
            width: 9%;
        }
        .project-progress {
            width: 16%;
        }
        .operational {
            width: 10%;
        }
        .devices-status {
            width: 5%;
        }
    }
    .project-body {
        border-bottom: 1px solid $lt-gray;
        &:last-child {
            border-bottom: none;
        }
        .panel-group {
            margin-bottom: 0px;
            .panel {
                box-shadow: none;
                border-radius: 0px;
                border: none;
                min-height: inherit;
                margin-bottom: 0px;
                padding-bottom: 0px;
                border-bottom: 1px solid $gray-lighter !important;
                &:last-child {
                    border-bottom: none !important;
                }
                &.panel-open {
                    .accordion-toggle[aria-expanded="true"] {
                        padding-bottom: 0 !important;
                        .project-val {
                            &:before {
                                top: 3px;
                                left: 15px;
                            }
                            &.slide-toggle {
                                &:before {
                                    left: 5px;
                                    top: 7px;
                                }
                            }
                        }
                        .slide-head-panel {
                            .project-val {
                                &::before {
                                    transform: rotate(90deg);
                                    -webkit-transform: rotate(90deg);
                                    -moz-transform: rotate(90deg);
                                    -ms-transform: rotate(90deg);
                                    -o-transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }
                .panel-heading {
                    border-radius: 0px;
                    background: none;
                    padding: 0px;
                    .slide-head-panel {
                        padding: 10px 0;
                        div {
                            float: left;
                            font-size: 14px;
                            line-height: 1.5;
                            padding: 0 15px;
                            cursor: default;
                        }
                        .project-val {
                            width: 18%;
                            color: $dark-color;
                            position: relative;
                            padding-left: 40px;
                            padding-top: 4px;
                            &:before {
                                font-family: "Font Awesome 5 Pro", sans-serif;
                                content: "\f105";
                                left: 15px;
                                top: 4px;
                                position: absolute;
                                font-size: 19px;
                                line-height: 19px;
                                background: transparent;
                                cursor: pointer;
                            }
                            &.slide-toggle {
                                &:before {
                                    top: 2px;
                                }
                            }
                        }
                        .customer-val {
                            width: 13%;
                        }
                        .project-manager-val {
                            width: 15%;
                        }
                        .design-val {
                            width: 12%;
                            &.design {
                                color: $dark-color;
                            }
                            &.open {
                                color: $red;
                            }
                            &.inprogress {
                                color: $yellow;
                            }
                            &.done {
                                color: $blue;
                            }
                        }
                        .plans-val,
                        .devices-val,
                        .hours-left-val {
                            width: 7%;
                        }
                        .punch-val {
                            width: 9%;
                        }
                        .icon-information-val {
                            width: 4%;
                        }
                        .project-progress-val {
                            padding-top: 7px;
                            width: 13%;
                            .progress-block {
                                display: inline-block;
                                vertical-align: middle;
                                width: calc(100% - 100px);
                                float: none;
                                padding-left: 0;
                            }
                            .progress {
                                background-color: $gray-lighter;
                                height: 6px;
                                border-radius: 10px;
                                margin-bottom: 0px;
                                padding: 0px;
                                box-shadow: none;
                                width: 100%;
                                transition: none;
                                .progress-bar {
                                    background: $blue;
                                    transition: none;
                                }
                            }
                            .project-percentage {
                                display: inline-block;
                                vertical-align: middle;
                                float: none;
                            }
                        }
                        .operational-value {
                            width: 10%;
                        }
                        .devices-status-value {
                            width: 5%;
                        }
                    }
                }
                .panel-body {
                    padding: 0px;
                    border: none;
                }
                .expand-body {
                    background: $gray-lightest;
                    border-bottom: 1px solid $gray-lighter;
                    &:last-child {
                        border-bottom: none;
                    }
                    .expand-inner {
                        padding: 10px 0;
                        div {
                            float: left;
                            font-size: 14px;
                            line-height: 1.1;
                            padding: 0 15px;
                        }
                        .project-exp {
                            width: 18%;
                            color: $dark-color;
                            position: relative;
                            padding-left: 40px;
                        }
                        .customer-exp {
                            width: 13%;
                        }
                        .project-manager-exp {
                            width: 15%;
                        }
                        .design-exp {
                            width: 12%;
                        }
                        .plans-exp,
                        .devices-exp,
                        .hours-left-exp {
                            width: 7%;
                        }
                        .punch-exp {
                            width: 9%;
                        }
                        .operational-exp {
                            width: 10%;
                        }
                        .devices-status-exp {
                            width: 5%;
                        }
                        .icon-information-exp {
                            width: 4%;
                        }
                        .project-progress-exp {
                            width: 13%;
                            .progress-block {
                                display: inline-block;
                                vertical-align: middle;
                                width: calc(100% - 100px);
                                float: none;
                                padding-left: 0;
                            }
                            .progress {
                                background-color: $gray-lighter;
                                height: 6px;
                                border-radius: 10px;
                                margin-bottom: 0px;
                                box-shadow: none;
                                width: 100%;
                                padding: 0px;
                                transition: none;
                                .progress-bar {
                                    background: $blue;
                                    transition: none;
                                }
                            }
                            .project-percentage {
                                display: inline-block;
                                vertical-align: middle;
                                float: none;
                            }
                        }
                    }
                }
            }
        }
    }
    &.dashboard-installation {
        .hours-left,
        .hours-left-val,
        .hours-left-exp {
            width: 10% !important;
        }
        .project-progress,
        .project-progress-val,
        .project-progress-exp {
            width: 28% !important;
            @include mq(laptop) {
                width: 22% !important;
            }
        }
    }
}

// Project - Dashboard Design end
// Progress bar for floor  start
.sync-progress-val {
    width: 100%;
    .progress-block {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        float: none;
        padding-left: 0;
    }
    .progress {
        background-color: $gray-lighter;
        height: 6px;
        border-radius: 10px;
        margin-bottom: 0px;
        padding: 0px;
        box-shadow: none;
        width: 100%;
        transition: none;
        .progress-bar {
            background-color: $blue !important;
            transition: none;
        }
    }
    .project-percentage {
        display: inline-block;
        vertical-align: middle;
        float: none;
    }
}

// Progress bar for floor  end
.equipment-counter-pdf {
    .panel-heading,
    .panel-body {
        padding: 15px !important;
    }
}

.equipment-counter {
    .tab-content {
        overflow: auto;
        height: calc(100vh - 305px);
        @include mq(laptop) {
            height: calc(100vh - 490px);
        }
    }
}

// drag device box start
.drag-thumb-img {
    .thumb-inner {
        display: block;
        width: 90%;
        height: 160px;
        margin: 0 auto 15px auto;
        overflow: hidden;
        .thumb-inner-img {
            position: relative;
            height: 100%;
            width: 100%;
            padding: 15px 15px 40px 15px;
            border: 2px solid transparent;
            .thumb-remove {
                cursor: pointer;
                position: absolute;
                right: 8px;
                top: 5px;
                font-size: 14px;
                color: $red;
            }
            img {
                height: 100%;
                margin: 0 auto;
            }
            .thumb-floor-name {
                display: block;
                width: calc(100% + 4px);
                background: $primary-color;
                left: -2px;
                color: $white;
                padding: 5px 0;
                text-align: center;
                position: absolute;
                bottom: -2px;
            }
        }
        &.active {
            .thumb-inner-img {
                border: 2px solid $blue;
                .thumb-floor-name {
                    background: $blue;
                }
            }
            &.archive {
                .thumb-floor-name {
                    border: 2px solid $blue !important;
                    border-top: none !important;
                    padding: 5px 0 3px 0 !important;
                }
            }
        }
        &.archive {
            .thumb-inner-img {
                // border: 2px solid $blue;
                .thumb-floor-name {
                    background: $gray !important;
                }
            }
        }
    }
}

// drag device box end

/*favorite reorder when we drag component */

.fav-reorder-main {
    .equip-items {
        &.gu-transit {
            background: $gray-lightest !important;
        }
        display: block;
        text-align: center;
        width: 100px;
        padding-top: 25px;
        background: $gray-lightest;
        opacity: 1;
        height: 100px;
        position: relative;
        cursor: grabbing;
        i,
        em {
            margin-bottom: 5px;
            display: inline-block;
            line-height: 30px;
            cursor: grabbing;
        }
        span {
            display: block;
            line-height: 1.1;
            font-size: 11px;
            font-weight: $font-light;
            color: $base-color;
            margin: 2px auto 0 auto;
            cursor: grabbing;
        }
        .favorite-btn,
        .equipment-thumb-remove,
        .fav-reorder,
        .equipment-thumb-badge {
            display: none;
        }
    }
}


/*favorite reorder when we drag component */

/* Manage Project tab start */
.projects-card {
    padding: 15px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px !important;
    margin-bottom: 15px;
    overflow: auto;
}
.project-filter {
    max-height: calc(100vh - 170px);
    overflow: auto;
    .checkbox-label {
        display: inline-block;
        label {
            line-height: 24px;
            padding-left: 25px !important;
            color: $base-color !important;
            &:before {
                font-size: 15px;
                color: $base-color;
            }
            &:after {
                font-size: 9px;
                margin: 1px 0 0 3px;
                padding: 0;
                color: $base-color;
            }
        }
    }
    .download-option-list {
        padding: 0 15px;
    }
    .blue-text {
        color: $blue;
    }
    .project-filter-body {
        overflow: auto;
        height: 192px;
    }
}
/* Manage Project tab end */

.gridline-path {
    stroke: #ddd;
    stroke-width: 1;
    fill: none;
 }