.btn {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    font-size: $base-font-size;
    i,
    em {
        margin-right: 5px;
    }
    &.btn-primary {
        background-color: $primary-action !important;
        border-color: $primary-action !important;
        border-radius: 4px;
        line-height: 1;
        padding: 10px 12px;
        min-width: 150px;
        margin-right: 10px;
        color: $white !important;
        &:hover {
            background-color: $primary-action-hover !important;
            border-color: $primary-action-hover !important;
        }
        &:focus,
        &:active {
            background-color: $primary-action-active !important;
            border-color: $primary-action-active !important;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        }
        &.dropdown-toggle {
            min-width: 150px;
        }
        &:not(:disabled) {
            &:not(.disabled).active {
                background-color: $primary-action-active !important;
                border-color: $primary-action-active !important;
                &:focus {
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                }
            }
            &:not(.disabled) {
                &:active {
                    background-color: $primary-action-active !important;
                    border-color: $primary-action-active !important;
                    &:focus {
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }
    }
    &.btn-danger {
        background-color: $dangerous-action;
        border-color: $dangerous-action;
        border-radius: 4px;
        line-height: 1;
        padding: 10px 12px;
        color: $white !important;
        min-width: 150px;
        margin-right: 10px;
        &:hover {
            background-color: $dangerous-action-hover;
            border-color: $dangerous-action-hover;
        }
        &:focus {
            background-color: $dangerous-action-active;
            border-color: $dangerous-action-active;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        }
        &:active {
            background-color: $dangerous-action-active;
            border-color: $dangerous-action-active;
        }
    }
    &.btn-full-width {
        width: 100%;
        min-width: inherit;
        margin-right: 0px;
    }
    &.btn-default {
        background-color: $blue;
        border-color: $blue;
        border-radius: 4px;
        line-height: 1;
        padding: 10px 12px;
        color: $white;
        min-width: 150px;
        margin-right: 10px;
        &:hover,
        &:focus {
            background-color: $blue;
            border-color: $blue;
            color: $white;
        }
    }
    &.btn-secondary {
        background-color: $secondary-action !important;
        border-color: $secondary-action !important;
        border-radius: 4px;
        line-height: 1;
        padding: 10px 12px;
        color: $white !important;
        min-width: 150px;
        margin-right: 10px;
        &:hover {
            background-color: $secondary-action-hover !important;
            border-color: $secondary-action-hover !important;
        }
        &:focus {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
            background-color: $secondary-action-active !important;
            border-color: $secondary-action-active !important;
        }
        &:active {
            background-color: $secondary-action-active !important;
            border-color: $secondary-action-active !important;
        }
        &:not(:disabled) {
            &:not(.disabled).active {
                background-color: $secondary-action-active !important;
                border-color: $secondary-action-active !important;
                &:focus {
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                }
            }
            &:not(.disabled) {
                &:active {
                    background-color: $secondary-action-active !important;
                    border-color: $secondary-action-active !important;
                    &:focus {
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }
    }
    &.btn-action {
        background-color: $cta-action;
        border-color: $cta-action;
        border-radius: 4px;
        line-height: 1;
        padding: 10px 12px;
        color: $white !important;
        min-width: 150px;
        margin-right: 10px;
        text-align: center;
        &:hover {
            background-color: $cta-action-hover;
            border-color: $cta-action-hover;
        }
        &:focus {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
            background-color: $cta-action-active;
            border-color: $cta-action-active;
        }
        &:active {
            background-color: $cta-action-active;
            border-color: $cta-action-active;
        }
        &:not(:disabled) {
            &:not(.disabled).active {
                background-color: $cta-action-active;
                border-color: $cta-action-active;
                &:focus {
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                }
            }
            &:not(.disabled) {
                &:active {
                    background-color: $cta-action-active;
                    border-color: $cta-action-active;
                    &:focus {
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }
    }
    &.btn-basic {
        background-color: $basic-action;
        border-color: $basic-action;
        border-radius: 4px;
        line-height: 1;
        padding: 10px 12px;
        color: $white !important;
        min-width: 150px;
        margin-right: 10px;
        &:hover {
            background-color: $basic-action-hover;
            border-color: $basic-action-hover;
        }
        &:focus {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
            background-color: $basic-action-active;
            border-color: $basic-action-active;
        }
        &:active {
            background-color: $basic-action-active;
            border-color: $basic-action-active;
        }
        &:not(:disabled) {
            &:not(.disabled).active {
                background-color: $basic-action-active;
                border-color: $basic-action-active;
                &:focus {
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                }
            }
            &:not(.disabled) {
                &:active {
                    background-color: $basic-action-active;
                    border-color: $basic-action-active;
                    &:focus {
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }
    }
    &.btn-archive {
        background-color: $white;
        border-color: $white;
        border-radius: 4px;
        line-height: 1;
        padding: 10px 12px;
        color: $gray-dark !important;
        min-width: 150px;
        margin-right: 10px;
        box-shadow: none;
        i,
        em {
            &.fa-trash-alt {
                color: $gray-dark !important;
            }
        }
        &:hover {
            background-color: $dangerous-action;
            border-color: $dangerous-action;
            color: $white !important;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
            i,
            em {
                &.fa-trash-alt {
                    color: $white !important;
                }
            }
        }
        &:focus {
            background-color: $dangerous-action;
            border-color: $dangerous-action;
            color: $white !important;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
            i,
            em {
                &.fa-trash-alt {
                    color: $white !important;
                }
            }
        }
        &:active {
            background-color: $dangerous-action;
            border-color: $dangerous-action;
            color: $white !important;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
            i,
            em {
                &.fa-trash-alt {
                    color: $white !important;
                }
            }
        }
        &:not(:disabled) {
            &:not(.disabled).active {
                background-color: $dangerous-action;
                border-color: $dangerous-action;
                &:focus {
                    box-shadow: none;
                }
            }
            &:not(.disabled) {
                &:active {
                    background-color: $dangerous-action;
                    border-color: $dangerous-action;
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

.create-btn {
    position: absolute;
    top: 5px;
    right: 6px;
    color: $white;
    font-size: 24px;
    background: $blue;
    border-radius: 50%;
    line-height: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
}

.btn-replace {
    padding: 0 !important;
    min-width: inherit !important;
    .filename-block {
        margin-bottom: 0px;
        .upload-link {
            color: $white !important;
            margin-left: 0;
            padding: 10px 12px;
            min-width: 150px;
            display: block;
        }
    }
}

button:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

a:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}


a {
        &.btn-back {
            display: inline-block;
            margin-right: 10px;
            svg {
                fill: $base-color;
                width: 22px;
                height: 22px;
                display: inline-block;
                text-align: left;
                vertical-align: middle;
                margin-right: 10px;
            }
            &:hover,
            &:focus {
                color: $base-color;
                svg {
                    fill: $base-color;
                }
            }
        }
    }