.pi {
	.w-50 {
		width: 50px !important;
	}

	.text-primary {
		color: $blue !important;
	}

	.mb-4 {
		margin-bottom: 4px !important;
	}

	.bg-primary {
		background-color: $blue !important;
	}

	.bg-primary-light {
		background-color: $gray-lighter !important;
	}

	.bg-controls {
		background-color: $primary-color !important;
	}
}

.tiny-scrollbar {
	padding-bottom: 0 !important;
}

.view-modal-popup {
	.icon-panel-editor {
		&.card {
			right: 15px !important;
		}
	}
}

.pixie-container {
	.isolate {
		&.bg-white {
			margin-right: 10px;
		}
	}

	.rounded-button {
		button {
			.svg-icon {
				fill: $anchor-color !important;
			}

			&:hover {
				.svg-icon {
					fill: $anchor-hover !important;
				}
			}

			&:disabled {
				.svg-icon {
					fill: $gray-lighter !important;
				}

				&:hover {
					.svg-icon {
						fill: $gray-lighter !important;
					}
				}
			}
		}
	}

	.right-0 {
		&.top-0 {
			button {
				.svg-icon {
					fill: $anchor-color !important;
				}

				&:hover {
					.svg-icon {
						fill: $anchor-hover !important;
					}
				}

				&:disabled {
					.svg-icon {
						fill: $gray-lighter !important;
					}

					&:hover {
						.svg-icon {
							fill: $gray-lighter !important;
						}
					}
				}
			}
		}
	}

	.pixie-root {
		height: calc(100vh - 75px) !important;
	}
}