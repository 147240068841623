$background-color_1: rgba(0, 0, 0, 0.1);
$background-color_2: rgba(0, 0, 0, 0.2);
$background-color_3: #e9edf0;
$background-color_4: #9aaec1;
$background-color_5: #54708b;
$background-color_6: #d5dadd;
$background-color_7: #777;
$background-color_8: #616161;
$background-color_9: #6F6E6E;
$border-color_1: #777;
$border-color_2: #616161;
$border-color_3: #6F6E6E;

/* .bs-datepicker */
/* .bs-timepicker */
/* screen size < 1024px */
/* theming */
/*# sourceMappingURL=bs-datepicker.css.map */
.bs-datepicker {
	display: flex;
	align-items: stretch;
	flex-flow: row wrap;
	background: #fff;
	box-shadow: 0 0 10px 0 #aaa;
	position: relative;
	z-index: 1;
	&:after {
		clear: both;
		content: "";
		display: block;
	}
	bs-day-picker {
		float: left;
	}
	button {
		&:hover {
			outline: none;
		}
		&:focus {
			outline: none;
		}
		&:active {
			outline: none;
		}
	}
	input {
		&:hover {
			outline: none;
		}
		&:focus {
			outline: none;
		}
		&:active {
			outline: none;
		}
	}
	.current-timedate {
		color: $blue;
		font-size: 15px;
		text-align: center;
		height: 30px;
		line-height: 30px;
		border-radius: 20px;
		border: 1px solid #e9edf0;
		margin-bottom: 10px;
		cursor: pointer;
		text-transform: uppercase;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		span {
			&:not(:empty) {
				&:before {
					content: "";
					width: 15px;
					height: 16px;
					display: inline-block;
					margin-right: 4px;
					vertical-align: text-bottom;
					background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
				}
			}
		}
	}
	.bs-media-container {
		display: flex;
	}
	.bs-timepicker-in-datepicker-container {
		display: flex;
		justify-content: space-around;
	}
}
.bs-datepicker-btns {
	button {
		&:hover {
			outline: none;
		}
		&:focus {
			outline: none;
		}
		&:active {
			outline: none;
		}
	}
}
.bs-datepicker-predefined-btns {
	button {
		&:active {
			outline: none;
		}
		&:focus {
			outline: none;
		}
		width: 100%;
		display: block;
		height: 30px;
		background-color: $background-color_4;
		border-radius: 4px;
		color: $white;
		border: 0;
		margin-bottom: 10px;
		padding: 0 18px;
		text-align: left;
		transition: 0.3s;
		&:hover {
			background-color: $background-color_5;
		}
	}
}
.bs-datepicker-head {
	min-width: 300px;
	height: 50px;
	padding: 10px;
	border-radius: 3px 3px 0 0;
	text-align: justify;
	&:after {
		content: "";
		display: inline-block;
		vertical-align: top;
		width: 100%;
	}
	button {
		display: inline-block;
		vertical-align: top;
		padding: 0;
		height: 30px;
		line-height: 30px;
		border: 0;
		background: transparent;
		text-align: center;
		cursor: pointer;
		color: $white;
		transition: 0.3s;
		&:hover {
			background-color: $blue;
		}
		&:active {
			background-color: $blue;
		}
	}
	button[disabled] {
		background: $gray;
		color: $gray-light;
		cursor: not-allowed;
		&:hover {
			background: $gray-light;
			color: $gray-light;
			cursor: not-allowed;
		}
		&:active {
			background: $gray-light;
			color: $gray-light;
			cursor: not-allowed;
		}
	}
	button.previous {
		span {
			transform: translate(-1px, -1px);
			font-size: 28px;
			line-height: 1;
			display: inline-block;
			position: relative;
			height: 100%;
			width: 100%;
			border-radius: 50%;
		}
		border-radius: 50%;
		width: 30px;
		height: 30px;
	}
	button.next {
		span {
			transform: translate(1px, -1px);
			font-size: 28px;
			line-height: 1;
			display: inline-block;
			position: relative;
			height: 100%;
			width: 100%;
			border-radius: 50%;
		}
		border-radius: 50%;
		width: 30px;
		height: 30px;
	}
	button.current {
		border-radius: 15px;
		max-width: 155px;
		padding: 0 13px;
	}
}
.bs-datepicker-body {
	padding: 10px;
	border-radius: 0 0 3px 3px;
	min-height: 232px;
	min-width: 278px;
	border: 1px solid #e9edf0;
	.days.weeks {
		position: relative;
		z-index: 1;
	}
	table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		th {
			font-size: 13px;
			color: $gray;
			font-weight: 400;
			text-align: center;
		}
		td {
			color: $gray-darker;
			text-align: center;
			position: relative;
			padding: 0;
			span {
				display: block;
				margin: 0 auto;
				font-size: 13px;
				border-radius: 50%;
				-moz-user-select: none;
				-webkit-user-select: none;
				-ms-user-select: none;
				&:before {
					content: "";
					display: block;
					position: absolute;
					z-index: -1;
					top: 6px;
					bottom: 6px;
					left: -3px;
					right: -2px;
					box-sizing: content-box;
					background: transparent;
				}
			}
			&:not(.disabled) {
				&:not(.week) {
					span {
						&:not(.disabled) {
							&:not(.is-other-month) {
								cursor: pointer;
							}
						}
					}
				}
			}
			span.is-highlighted {
				&:not(.disabled) {
					&:not(.selected) {
						background-color: $background-color_3;
						transition: 0s;
					}
				}
			}
			span.is-active-other-month {
				&:not(.disabled) {
					&:not(.selected) {
						background-color: $background-color_3;
						transition: 0s;
						cursor: pointer;
					}
				}
			}
			span.disabled {
				color: $gray;
			}
			span.selected {
				color: $white;
			}
			span.is-other-month {
				color: $gray-light;
			}
			span.active.select-start {
				&:after {
					content: "";
					display: block;
					position: absolute;
					z-index: -1;
					width: 100%;
					height: 100%;
					transition: 0.3s;
					top: 0;
					border-radius: 50%;
				}
			}
			span.active.select-end {
				&:after {
					content: "";
					display: block;
					position: absolute;
					z-index: -1;
					width: 100%;
					height: 100%;
					transition: 0.3s;
					top: 0;
					border-radius: 50%;
				}
			}
			&:before {
				content: "";
				display: block;
				position: absolute;
				z-index: -1;
				top: 6px;
				bottom: 6px;
				left: -3px;
				right: -2px;
				box-sizing: content-box;
				background: transparent;
			}
			&:last-child.active {
				&:before {
					border-radius: 0 3px 3px 0;
					width: 125%;
					left: -25%;
				}
			}
			span[class*=select-] {
				border-radius: 50%;
				color: $white;
			}
			
		}
		td.is-highlighted {
			&:not(.disabled) {
				&:not(.selected) {
					span {
						background-color: $background-color_3;
						transition: 0s;
					}
				}
			}
		}
		td.is-active-other-month {
			&:not(.disabled) {
				&:not(.selected) {
					span {
						background-color: $background-color_3;
						transition: 0s;
						cursor: pointer;
					}
				}
			}
		}
		td.disabled {
			span {
				color: $gray;
			}
		}
		td.selected {
			span {
				color: $white;
			}
		}
		td.is-other-month {
			span {
				color: $gray-light;
			}
		}
		td.active {
			position: relative;
		}
		td.active.select-start {
			&:before {
				left: 35%;
			}
			span {
				&:after {
					content: "";
					display: block;
					position: absolute;
					z-index: -1;
					width: 100%;
					height: 100%;
					transition: 0.3s;
					top: 0;
					border-radius: 50%;
				}
			}
			+ {
				td.active {
					&:before {
						left: -20%;
					}
				}
			}
		}
		td.active.select-end {
			&:before {
				left: -85%;
			}
			span {
				&:after {
					content: "";
					display: block;
					position: absolute;
					z-index: -1;
					width: 100%;
					height: 100%;
					transition: 0.3s;
					top: 0;
					border-radius: 50%;
				}
			}
		}
		td[class*=select-] {
			span {
				border-radius: 50%;
				color: $white;
			}
		}
		&:not(.weeks) {
			tr {
				td {
					&:first-child {
						&:before {
							border-radius: 3px 0 0 3px;
						}
					}
				}
			}
		}
	}
	table.days {
		td.active {
			&:not(.select-start) {
				&:before {
					background: #e9edf0;
				}
			}
			+ {
				td.is-highlighted {
					&:before {
						background: #e9edf0;
						width: 100%;
					}
				}
				td.select-end {
					&:before {
						background: #e9edf0;
						width: 100%;
					}
				}
			}
		}
		td.in-range {
			&:not(.select-start) {
				&:before {
					background: #e9edf0;
				}
			}
			+ {
				td.is-highlighted {
					&:before {
						background: #e9edf0;
						width: 100%;
					}
				}
				td.select-end {
					&:before {
						background: #e9edf0;
						width: 100%;
					}
				}
			}
		}
		span.active {
			&:not(.select-start) {
				&:before {
					background: #e9edf0;
				}
			}
		}
		span.in-range {
			&:not(.select-start) {
				&:before {
					background: #e9edf0;
				}
			}
		}
		span {
			width: 32px;
			height: 32px;
			line-height: 32px;
		}
		span.select-start {
			z-index: 2;
		}
		span.is-highlighted.in-range {
			&:before {
				right: 3px;
				left: 0;
			}
		}
		span.in-range.select-end {
			&:before {
				right: 4px;
				left: 0;
			}
		}
		td.select-start {
			+ {
				td.select-end {
					&:before {
						background: #e9edf0;
						width: 100%;
					}
				}
				td.is-highlighted {
					&:before {
						background: #e9edf0;
						width: 100%;
					}
				}
			}
		}
	}
	table.weeks {
		tr {
			td {
				&:nth-child(2).active {
					&:before {
						border-radius: 3px 0 0 3px;
						left: 0;
						width: 100%;
					}
				}
				&:first-child {
					color: $red;
				}
			}
			
		}
	}
	table.years {
		td {
			span {
				width: 46px;
				height: 46px;
				line-height: 45px;
				margin: 0 auto;
			}
		}
		tr {
			&:not(:last-child) {
				td {
					span {
						margin-bottom: 8px;
					}
				}
			}
		}
	}
	table.months {
		td {
			height: 52px;
			span {
				padding: 6px;
				border-radius: 15px;
			}
		}
	}
}
.bs-datepicker-multiple {
	border-radius: 4px 0 0 4px;
	+ {
		.bs-datepicker-multiple {
			margin-left: 10px;
		}
	}
	.bs-datepicker {
		box-shadow: none;
		position: relative;
		&:not(:last-child) {
			padding-right: 10px;
		}
		+ {
			.bs-datepicker {
				&:after {
					content: "";
					display: block;
					width: 14px;
					height: 10px;
					background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
					position: absolute;
					top: 25px;
					left: -8px;
				}
			}
		}
		.left {
			float: left;
		}
		.right {
			float: right;
		}
	}
}
.bs-datepicker-container {
	padding: 0;
}
.bs-datepicker-custom-range {
	padding: 15px;
	background: #eee;
}
.bs-datepicker-buttons {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	padding-top: 10px;
	border-top: 1px solid #e9edf0;
	.btn-default {
		margin-left: 10px;
	}
	.btn-today-wrapper {
		display: flex;
		flex-flow: row wrap;
	}
	.clear-right {
		flex-grow: 0;
	}
	.today-right {
		flex-grow: 0;
	}
	.clear-left {
		flex-grow: 1;
	}
	.today-left {
		flex-grow: 1;
	}
	.clear-center {
		flex-grow: 0.5;
	}
	.today-center {
		flex-grow: 0.5;
	}
}
.bs-timepicker-container {
	padding: 10px 0;
}
.bs-timepicker-label {
	color: $blue;
	margin-bottom: 10px;
}
.bs-timepicker-controls {
	display: inline-block;
	vertical-align: top;
	margin-right: 10px;
	button {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 0;
		background-color: $background-color_3;
		color: $blue;
		font-size: 16px;
		font-weight: 700;
		vertical-align: middle;
		line-height: 0;
		padding: 0;
		transition: 0.3s;
		&:hover {
			background-color: $background-color_6;
		}
	}
	input {
		width: 35px;
		height: 25px;
		border-radius: 13px;
		text-align: center;
		border: 1px solid #e9edf0;
	}
}
.bs-timepicker {
	.switch-time-format {
		text-transform: uppercase;
		min-width: 54px;
		height: 25px;
		border-radius: 20px;
		border: 1px solid #e9edf0;
		background: #fff;
		color: $blue;
		font-size: 13px;
		img {
			vertical-align: initial;
			margin-left: 4px;
		}
	}
}
bs-datepicker-container {
	z-index: 1080;
}
bs-daterangepicker-container {
	z-index: 1080;
}
.theme-default {
	.bs-datepicker-head {
		background-color: $primary-color ;
	}
	.btn-today-wrapper {
		.btn-success {
			background-color: $blue;
			border-color: $blue;
			&:not(:disabled) {
				&:not(.disabled) {
					&:active {
						&:focus {
							box-shadow: none;
						}
						background-color: $background-color_8;
						border-color: $border-color_2;
					}
				}
			}
			&:focus {
				box-shadow: none;
			}
			&:hover {
				background-color: $background-color_9;
				border-color: $border-color_3;
			}
		}
	}
	.btn-clear-wrapper {
		.btn-success {
			background-color: $background-color_7;
			border-color: $border-color_1;
			&:not(:disabled) {
				&:not(.disabled) {
					&:active {
						&:focus {
							box-shadow: none;
						}
						background-color: $background-color_8;
						border-color: $border-color_2;
					}
				}
			}
			&:focus {
				box-shadow: none;
			}
			&:hover {
				background-color: $background-color_9;
				border-color: $border-color_3;
			}
		}
	}
	.bs-datepicker-predefined-btns {
		button.selected {
			background-color: $primary-color;
		}
	}
	.bs-datepicker-body {
		table {
			td {
				span.selected {
					background-color: $gray-lighter;
				}
				span[class*=select-] {
					&:after {
						background-color: $gray-lighter;
					}
				}
			}
			td.selected {
				span {
					background-color: $gray-lighter;
				}
			}
			td[class*=select-] {
				span {
					&:after {
						background-color: $gray-lighter;
					}
				}
			}
			td.week {
				span {
					color: $gray-dark;
				}
			}
			td.active-week {
				span {
					&:hover {
						cursor: pointer;
						background-color: $primary-color;
						color: $white;
						opacity: 0.5;
						transition: 0s;
					}
				}
			}
		}
	}
}
.today {
	background-color: $blue;
	color: $white;

}

