body {
    overflow-y: auto !important;
}

.display-none {
    display: none;
}

.word-break-all {
    word-break: break-all;
}

.card {
    border-radius: 0 !important;
    background: $white;
    min-height: 50px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 0;

    h4 {
        font-weight: $font-med;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
    }

    .card-body {
        padding: 15px;
    }
}

.green-text {
    color: $green;
}

.status-message {
    font-size: 18px;
    line-height: 1;
    font-weight: $font-reg;
    padding-left: 15px;
}

// Page sub header
.sub-header {
    padding: 13px 0 8px 0;
    position: fixed;
    background: $off-white;
    padding-left: 65px;
    padding-right: 15px;
    left: 0;
    top: 50px;
    right: 0;
    z-index: 10;

    h1 {
        margin: 0;
        padding: 0;
        font-size: 22px;
        line-height: 30px;
        color: $base-color;
        margin-right: 15px;
        font-weight: $font-med;
    }

    .breadcrumb {
        background: transparent;
        padding: 0;
        margin-bottom: 0;
        border: none;
        li {
            padding: 0 10px;
            position: relative;
            line-height: 34px;
            height: 30px;
            color: $label-color;
            font-size: 15px;
            width: calc(70vw / 4);
            max-width: max-content;
            display: inline-block;
            &.ticket-text-truncate,
            &:first-child {
                padding-left: 0;
                h1 {
                    padding-left: 0 !important;
                }
                &:before {
                    border-left: none;
                }
            }
            &:before {
                position: absolute;
                content: "";
                border-left: 1px solid $gray;
                left: 0;
                top: 9px;
                height: 18px;
            }
            a {
                &.back-btn {
                    font-size: 22px;
                    line-height: 36px;
                    color: $anchor-color;
                    margin-right: 0;
                    font-weight: $font-med;
                    &:hover {
                        color: $anchor-hover;
                    }
                }
            }
            .form-control {
                min-width: 100px;
                background: 0 0;
                height: 18px;
                padding: 0;
                border-bottom: none;
                color: $primary-color;
                font-size: 14px;
                margin-top: 9px;
            }
        }
    }
    .btn {
        margin-right: 0 !important;
        margin-left: 10px;
    }
    .btn-actions {
        width: 132px;
        float: right;
        text-align: right;

        @include mq(tablet-xl) {
            width: 108px;
        }

        @include mq(desktop) {
            width: 132px;
        }

        .action-item {
            float: right;
            color: $white;

            &.edit-right {
                float: right;
            }

            a {
                color: $white;
                display: inline-block;
                padding: 15px 0;
                width: 72px;
                text-align: center;

                @include mq(tablet-xl) {
                    width: 60px;
                }

                @include mq(desktop) {
                    width: 72px;
                }

                &:hover,
                &:focus {
                    color: $white;
                }

                &.btn-edit {
                    display: inline-block;
                    padding: 15px 0px;
                    width: 60px;
                    text-align: center;
                    margin: 0px;
                    background: $blue;
                    color: $white;

                    @include mq(tablet-xl) {
                        width: 48px;
                    }

                    @include mq(desktop) {
                        width: 60px;
                    }

                    &:hover,
                    &:focus {
                        background: $blue;
                        color: $white;
                    }
                }
            }
        }
    }
}

// spinner animation for domotoz
.spinner-block {
    text-align: left;

    .success-message {
        font-weight: $font-med;
        line-height: 34px;
    }

    .erorr-message {
        font-weight: $font-med;
        color: $red;
        line-height: 34px;
    }

    .popup-spinner {
        margin-top: 10px;
        font-size: 20px;

        &.fa-animated {
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
        }
    }
}

.fa-animated {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

///animation for spinner //
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-moz-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-ms-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-o-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.domotz-body {
    min-height: 90px;

    .spinner-block {
        left: 50%;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        margin-left: -69px;

        .popup-spinner {
            margin-top: 0px;
            margin-right: 10px;
        }
    }
}

.help-block {
    &.ip-error {
        left: 15px;
        z-index: 1;
        right: inherit;
        margin-top: 15px;

        &:after,
        &:before {
            bottom: 100%;
            left: 20px;
            right: inherit;
            top: inherit;
        }

        &:before {
            border-bottom-color: $red;
            border-right-color: transparent;
            margin-left: -10px;
            margin-top: 0px;
        }

        &:after {
            border-bottom-color: $white;
            border-right-color: transparent;
            margin-left: -8px;
            margin-top: 0px;
        }
    }
}

.inline-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 99;

    i,
    em {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -7px;
        margin-left: -7px;
        color: $white;

        &.fa-animated {
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
        }
    }
}

.action {
    color: $red;
}

.device-list-info {
    span {
        display: block;

        &.red {
            color: $red;
        }

        &.blue {
            color: $blue;
        }

        &.yellow {
            color: $yellow;
        }
    }
}

.terms-group {
    margin-top: 20px;
    margin-bottom: 20px;

    .checkbox-label {
        label {
            line-height: 14px;
            color: $base-color;

            a {
                text-decoration: none;
                color: $blue !important;
                position: relative;
            }
        }
    }

    .help-block {
        margin-top: -15px;
        width: 235px;
        left: 390px;
    }
}

.invalid-block {
    color: $red;
}

.add-technician {
    &.disabled-pdf {
        pointer-events: none;
        opacity: 0.5;
    }

    i,
    em {
        &.fa-spinner {
            &.fa-animated {
                -webkit-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite;
            }
        }
    }
}

.no-plan {
    background: $gray-lightest;
    padding: 10px 15px;
    margin-bottom: 0;

    p {
        margin-bottom: 0px;
    }
}

.no-project {
    padding: 10px;
    min-height: 50px;
    line-height: 50px;

    p {
        margin-bottom: 0px;
    }
}

.no-padding {
    padding: 0 !important;
}

.padding-r-0 {
    padding-right: 0 !important;
}

.mrg-t-30 {
    margin-top: 30px !important;
}

.mrg-t-15 {
    margin-top: 15px !important;
}

.mrg-0 {
    margin: 0 !important;
}

.mrg-b-15 {
    margin-bottom: 15px !important;
}

.mrg-b-30 {
    margin-bottom: 30px !important;
}

.mrg-l-m-15 {
    margin-left: -15px;
}

.mrg-l-40 {
    margin-left: 40px;
}

.mrg-t-40 {
    margin-top: 40px;
}

.border-none {
    border: none !important;

    &:before {
        border: none !important;
    }
}

.listing-floor-buildings {
    .dropdown-menu {
        right: 0px;
        left: inherit;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 0px;
        font-size: 14px;
        color: $base-color;

        li {
            margin-bottom: 0px;

            a {
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    li {
        &.expand-inner {
            border-bottom: 1px solid $gray-lighter !important;
            margin-bottom: 0 !important;

            &:last-child {
                border-bottom: none !important;
            }
        }
    }
}

.icon-information {
    width: 10px;
}

.content-wrapper-main {
    margin-top: 13px;
}

.filter-panel {
    padding: 0;
    border-bottom: none;

    .filter-header {
        background: $white;

        .filter-label {
            padding: 14px 15px;
            color: $primary-color;
            font-weight: $font-reg;
            margin: 0;
            line-height: 22px;

            span {
                cursor: pointer;
            }
        }

        .device-feature {
            padding: 9px 15px;
            line-height: 18px;
        }

        .checkbox-label {
            label {
                margin: 0;
                padding-left: 25px !important;
                color: $primary-color !important;
            }
        }

        .configure-anchor {
            margin-left: 15px;
            color: $primary-color;
        }
    }

    .filter-body {
        background: $gray-lightest;
        padding: 15px 15px 5px 15px;

        .form-group {
            margin-bottom: 15px;

            .mydrp {
                border-bottom: 1px solid $gray-lighter;
                height: 22px;

                .selectiongroup {
                    height: 18px !important;

                    .selection {
                        font-size: 14px !important;
                        font-weight: $font-reg !important;
                        padding-left: 0 !important;
                    }

                    .selection,
                    .btnpicker,
                    .selbtngroup {
                        background: $gray-lightest;
                        height: 18px !important;
                    }
                }
            }
        }

        .filter-btn {
            margin-top: 5px;
        }
    }
}

.breadcrumb {
    background: transparent;
    padding: 0;
    margin-bottom: 0;
    border: none;

    li {
        padding: 0 10px;
        position: relative;
        line-height: 37px;
        height: 37px;
        color: $label-color;

        &:first-child {
            &:before {
                border-left: none;
            }
        }

        &:before {
            position: absolute;
            content: "";
            border-left: 1px solid $gray;
            left: 0;
            top: 9px;
            height: 18px;
        }

        a {
            &.back-btn {
                font-size: 22px;
                line-height: 36px;
                color: $anchor-color;
                margin-right: 0;

                &:hover {
                    color: $anchor-hover;
                }
            }
        }

        .form-control {
            min-width: 100px;
            background: 0 0;
            height: 18px;
            padding: 0;
            border-bottom: none;
            color: $primary-color;
            font-size: 14px;
            margin-top: 9px;
        }
    }
}

.footer-panel {
    .breadcrumb {
        background: transparent;
        padding: 4px 0;
        margin-bottom: 0;
        border: none;

        li {
            padding: 0 10px;
            position: relative;
            line-height: 37px;
            height: 37px;
            color: $label-color;

            &:first-child {
                &:before {
                    border-left: none;
                }
            }

            &:before {
                position: absolute;
                content: "";
                border-left: 1px solid $gray;
                left: 0;
                top: 9px;
                height: 18px;
            }

            a {
                &.back-btn {
                    font-size: 22px;
                    line-height: 36px;
                    color: $anchor-color;
                    margin-right: 0;

                    &:hover {
                        color: $anchor-hover;
                    }
                }
            }

            .form-control {
                min-width: 100px;
                background: 0 0;
                height: 18px;
                padding: 0;
                border-bottom: none;
                color: $primary-color;
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }
}

.project-status {
    .progress-status {
        float: left;
        font-size: 14px;
        line-height: 1;
        font-weight: $font-reg;
        width: 100%;
        margin-top: 6px;
        margin-right: 10px;
        position: relative;

        .progress {
            background-color: $gray-lighter;
            height: 8px;
            border-radius: 10px;
            margin-bottom: 0px;
            box-shadow: none;

            bar {
                &.progress-bar {
                    background-color: $blue;
                    transition: none;
                }
            }
        }
    }
}

// loader start
.dashboard-loader,
.page-loader {
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 3;
    left: 0;
    top: 0;
    bottom: 0;
    min-height: 100px;

    i,
    em {
        position: absolute;
        color: $white;
        font-size: 36px;
        line-height: 36px;
        left: 50%;
        top: 50%;
        margin-left: -18px;
        margin-top: -18px;

        &.fa-animated {
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
        }
    }
}

.page-table-loader {
    background: rgba(0, 0, 0, 0.4);

    td {
        height: 170px;
        vertical-align: middle;

        i,
        em {
            font-size: 36px;
            line-height: 36px;

            &.fa-animated {
                -webkit-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite;
            }
        }
    }
}

.loader-wrapper {
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 3;
    left: 0;
    top: 0;
    bottom: 0;
    min-height: 100px;
}

// loader end

.primary-link {
    color: $anchor-color !important;
    cursor: pointer;
    &:hover {
        color: $anchor-hover !important;
    }
}

.red-link {
    color: $red !important;
    cursor: pointer;
    &:hover {
        color: $dangerous-action-hover !important;
    }
}

.blue,
.blue-text {
    color: $blue !important;
}

.red-text {
    color: $red !important;
}

.yellow-text {
    color: $yellow !important;
}
.gray-light-text {
    color: $gray-light !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-image: inherit !important;
}

.progress-container {
    width: 700px;
    margin-left: -33px;

    .progressbar {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            width: 25%;
            float: left;
            font-size: 14px;
            position: relative;
            text-align: center;
            font-weight: $font-med;

            &:after {
                width: 25px;
                height: 25px;
                content: "";
                line-height: 25px;
                display: block;
                text-align: center;
                margin: 0 auto;
                border-radius: 50%;
                background-color: $gray-dark;
            }

            &:before {
                width: 100%;
                height: 5px;
                content: "";
                position: absolute;
                background-color: $gray-dark;
                top: 32px;
                left: -75px;
                z-index: 1;
            }

            &:first-child {
                &:before {
                    content: none;
                }
            }

            &.active {
                color: $blue;

                &:before,
                &:after {
                    background-color: $blue;
                }
            }

            &.disabled {
                color: $gray-light;

                &:before,
                &:after {
                    background-color: $gray-light;
                }
            }
        }
    }

    &.account-setup-bar {
        margin: 20px 0 0 0;
        height: 50px;
        width: 100%;

        .progressbar {
            li {
                width: 130px;

                @include mq(mobile) {
                    width: 110px;
                }

                &:before {
                    left: -41%;

                    @include mq(mobile) {
                        left: -39%;
                    }
                }
            }
        }
    }
}

.empty-screen-message {
    height: calc(100vh - 185px);

    i,
    em {
        &.icon {
            font-size: 120px;
            line-height: 120px;
            margin-bottom: 30px;

            @include mq(laptop) {
                font-size: 150px;
                line-height: 150px;
                margin-bottom: 40px;
            }
        }
    }

    h1 {
        font-size: 26px;
        line-height: 22px;
        margin-bottom: 30px;

        @include mq(laptop) {
            font-size: 38px;
            line-height: 46px;
            margin-bottom: 30px;
        }
    }

    p {
        font-size: 19px;
        line-height: 26px;
        margin-bottom: 30px;

        @include mq(laptop) {
            font-size: 24px;
            line-height: 34px;
        }
    }
}

.open {
    color: $red;
}

.inprogress {
    color: $yellow;
}

.done {
    color: $blue;
}

.archive {
    color: $gray;
}

.published {
    color: $green;
}

.dp-show {
    display: block !important;
}

.moveable-rotation {
    display: block !important;
}

.download-dropdown {
    display: inline-block;
    vertical-align: top;

    .dropdown {
        position: relative;

        .dropdown-toggle {
            min-width: 150px;
            background: $primary-action !important;
            border-color: $primary-action !important;

            &:hover {
                background: $primary-action-hover !important;
                border-color: $primary-action-hover !important;
            }

            &:focus {
                box-shadow: none;
                background: $primary-action-active !important;
                border-color: $primary-action-active !important;
            }

            &:after {
                top: 6px;
                position: relative;
                font-family: "Font Awesome 5 Pro", sans-serif;
                content: "\f107";
                border: none;
                font-size: 17px;
                line-height: 1px;
            }
        }

        &.open {
            .btn {
                border-radius: 4px 4px 0 0 !important;
            }
        }
    }

    .dropdown-menu {
        box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid $gray-lighter;
        min-width: 150px;
        top: 34px !important;
        border-radius: 0px 0px 5px 5px;
        padding: 0;

        .dropdown-item {
            padding: 8px 15px;
            font-size: $base-font-size;
            font-weight: $font-reg;
            &:hover {
                background-color: $gray-lightest !important;
            }
        }
    }
}
.device-accordion-wrapper {
    .custom-tooltip {
        .custom-tooltip-content {
            position: fixed !important;
            &.info-tooltip {
            position: absolute !important;
            left: -80px !important;
        }
        }
    }
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 184px);
    .site-device-title,
    .archived-title, .published-title {
        margin: 0 !important;
    }
    .device-accordion {
       
        .panel-heading {
            padding: 15px !important;
        }
        .panel-collapse {
            &.show {
                border-top: 1px solid $border-color !important;
            }
        }
        .form-group {
            text-align: left;
        }

        .card-body {
            padding: 5px 10px !important;
            text-align: left !important;
        }
        .ticket-btn-group {
            position: relative !important;
            left: -15px;
            background: transparent !important;
            padding: 15px 0 0;
        }
    
    }
    // .custom-tooltip-content {
    //     position: fixed;
      
    //     &.info-tooltip {
    //         position: absolute !important;
    //         left: -80px !important;
    //     }
    // }
    // .flag {
    // .flag-tooltip {
    //     .custom-tooltip-content {
    //         bottom: 20px !important;
    //         top: inherit !important;
    //     }
    // }}
}
.equipment-count {
    .panel-group {
        .panel {
            background: none;
            box-shadow: none;
            border: none;

            &.panel-default {
                border-radius: 0px;
                border: none;
                box-shadow: none;
                background: none;
                min-height: inherit;
                padding-bottom: 0px;
                margin-bottom: 0;
                border-bottom: 1px solid $gray-light;

                .panel-heading {
                    background-color: transparent;
                    border: none;
                    padding: 15px 20px;

                    .panel-title {
                        .accordion-toggle[aria-expanded="true"] {
                            .slide-toggle {
                                cursor: pointer;

                                &:before {
                                    font-family: "Font Awesome 5 Pro", sans-serif;
                                    content: "\f105";
                                    left: 0;
                                    top: -2px;
                                    position: absolute;
                                    font-size: 19px;
                                    line-height: 19px;
                                    background: transparent;
                                    cursor: pointer;
                                    transform: rotate(90deg);
                                    -webkit-transform: rotate(90deg);
                                    -moz-transform: rotate(90deg);
                                    -ms-transform: rotate(90deg);
                                }
                            }
                        }

                        .slide-toggle {
                            text-align: left;
                            position: relative;
                            color: $primary-color;
                            padding-left: 20px;
                            vertical-align: middle;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: $font-med;
                            width: 100%;

                            &[aria-expanded="true"] {
                                &:before {
                                    font-family: "Font Awesome 5 Pro", sans-serif;
                                    content: "\f105";
                                    left: 0;
                                    top: -2px;
                                    position: absolute;
                                    font-size: 19px;
                                    line-height: 19px;
                                    background: transparent;
                                    cursor: pointer;
                                    transform: rotate(90deg);
                                    -webkit-transform: rotate(90deg);
                                    -moz-transform: rotate(90deg);
                                    -ms-transform: rotate(90deg);
                                }
                            }

                            &:before {
                                font-family: "Font Awesome 5 Pro", sans-serif;
                                content: "\f105";
                                left: 0;
                                top: -2px;
                                position: absolute;
                                font-size: 19px;
                                line-height: 19px;
                                background: transparent;
                                cursor: pointer;
                            }

                            a {
                                color: $gray;
                            }
                        }
                    }
                }

                .panel-body {
                    padding: 5px 0 0 0;
                    background: $gray-lightest;
                    border-top: 1px solid $gray-light;
                    text-align: left;
                    height: auto;
                }
            }
        }
    }
}

.shareable-link-spacer {
    margin-right: 160px !important;
}

.shareable-link-col {
    padding-left: 5px !important;

    &::before {
        border-left: none !important;
    }
}

.shareable-link-action {
    color: $primary-color;
    cursor: pointer;
    font-size: 15px;
    line-height: 15px;
    position: absolute;
    width: max-content;
    display: inline-block;
    padding: 0 20px 0 0;
    margin-left: 10px;
    vertical-align: middle;

    .share-link-text {
        display: none;
    }

    &:hover {
        color: $blue;

        .share-link-text {
            display: block;
            position: absolute;
            left: 20px;
            top: 0;
            width: max-content;
        }
    }
}

.device-shareable-link {
    text-align: left;
    margin: 20px auto;
    color: $primary-color;
    font-weight: $font-med;
    cursor: pointer;
    padding-left: 5px;

    &:hover {
        color: $blue;
    }
}

.asterik {
    color: $red;
}

.mt-10 {
    margin-top: 10px !important;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
}

.toast:not(.show) {
    display: block;
}

.pointer-events-none {
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.secondary-close-btn {
    background: $white;
    width: 22px;
    height: 22px;
    position: absolute;
    right: -8px;
    top: -8px;
    border: 1px solid $gray-lighter;
    border-radius: 50px;
    line-height: 22px;
    z-index: 8;
    cursor: pointer;

    &.left {
        left: -8px;
        right: inherit;
    }

    em {
        &.icon-close {
            font-size: 14px;
            line-height: 14px;
            position: relative;
        }
    }
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.rounded-3 {
    border-radius: 0.3rem !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: $gray-lightest;

    &:hover {
        ::-webkit-scrollbar-thumb {
            background-color: darken($gray-light, 25%);
        }
    }
}

::-webkit-scrollbar-thumb {
    background-color: $gray-light;
    border-radius: 3px;
}

.grid-view {
    list-style: none;
    display: inline-block;
    margin: 0 10px 0 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
    padding: 0;
    overflow: hidden;

    li {
        display: inline-block;
        width: 50px;
        height: 37px;
        background: $gray-lighter;
        line-height: 37px;
        color: $gray-dark;
        font-size: 14px;
        text-align: center;
        cursor: pointer;

        &.active {
            background: $blue;
            color: $white;
        }
    }

    .hierarchy-btn {
        background: $primary-action;
        color: $white;

        &:hover {
            background: $primary-action-hover;
            color: $white;
        }
    }
}
 .tab-btn {
    list-style: none;
 
    margin: 0 10px 0 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
    padding: 0;
    overflow: hidden;

    li {
        display: inline-block;
        width: 100px;
        height: 37px;
        background: $gray-lighter;
        line-height: 37px;
        color: $gray-dark;
        font-size: 14px;
        text-align: center;
        cursor: pointer;

        &.active {
            background: $blue;
            color: $white;
        }
    }

    .hierarchy-btn {
        background: $primary-action;
        color: $white;

        &:hover {
            background: $primary-action-hover;
            color: $white;
        }
    }
}
.toast-success {
    background-color: $green !important;
}
.toast-warning {
    background-color: $yellow !important;
}
.toast-error {
    background-color: $red !important;
}
.pt-2px {
    padding-top: 2px !important;
}
.chart-bar-tooltip {
    transform: translateX(-60%);
}

.table-layout-fixed {
    table-layout: fixed;
}
.border-top {
    border-top: 1px solid $border-color;
}
.list-disc {
    list-style: disc;
}

.project-progress-val {
    padding-top: 7px;
    width: 70%;
    display: inline-block;
    margin-right: 15px; 
    @include mq(laptop) {
        width: 65%;
    }
    .progress-block {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 100px);
        float: none;
        padding-left: 0;
    }
    .progress {
        background-color: $gray-lighter;
        height: 8px;
        border-radius: 10px;
        margin-bottom: 0px;
        padding: 0px !important;
        box-shadow: none;
        width: 100%;
        transition: none;
        .progress-bar {
            background: $blue;
            transition: none;
        }
    }
    .project-percentage {
        display: inline-block;
        vertical-align: middle;
        float: none;
    }
}