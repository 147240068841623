/* You can add global styles to this file, and also import other style files */

/* ---------------------------------------------------------
   Common Styles 
   ---------------------------------------------------------*/
//abstracts
@import "globalVar";
@import "_mixins";
@import "_variables";
@import "_functions";

//vendor
@import "normalize";
@import "_siteowl"; // custom fonts
@import "_fontawesome";
@import "_ng2";
@import "_datepicker";
@import "_pixie";
//base
@import "_typography";
@import "_helpers";
@import "_reset";
@import "_common";

//layouts
@import "_equipments"; // for device and task bar
@import "_card-list"; // Project and site device list
@import "_floor-plan-components"; // floor plan related features
@import "user-profile.component"; //@import 'node_modules/angular2-toaster/toaster';
@import "_site-hierarchy.component"; // for site hierachy component
@import "_floor-device"; // all floor plan device new styling
@import "_login"; // login and reset password related pages
@import "_pages"; // other pages
@import "_tickets";
@import "_cable-pathways"; //cable-path
@import "_floor-details";
@import "_devicepinslider"; //coverage slider
@import "_animation"; //pulse animation


//components
@import "forms";
@import "_tables";
@import "_modal-popup";
@import "_role-tooltip";
@import "_buttons";
@import "_tabs";
@import "_context-menu";



