.table {
    margin-bottom: $spacer;
    tr {
        th {
            padding: 10px;
            vertical-align: middle;
            border-top: none;
            position: relative;
            color: $label-color;
            font-weight: $font-reg;
            border-bottom: 1px solid $gray-light;
            white-space: nowrap;
            &.sort-both {
                &:first-child {
                    padding-left: 10px;
                }
            }
            &.sort-asc,
            &.sort-desc {
                cursor: pointer;
                &:first-child {
                    padding-left: 13px;
                    &:before {
                        position: absolute;
                        left: 0 !important;
                    }
                }
            }
            &:first-child {
                &.sort-asc,
                &.sort-desc {
                    &::before {
                        left: 0px;
                    }
                }
            }
        }
        td {
            padding: 10px;
            vertical-align: middle;
            border-top: 1px solid $gray-lighter;
            position: relative;
        }
        .sort-asc {
            &:before {
                font-family: "Font Awesome 5 Pro", sans-serif;
                left: -4px; 
                content: "\f062";
                color: $label-color;
                position: absolute;
                font-weight: font-reg;
            }
        }
        .sort-desc {
            &:before {
                font-family: "Font Awesome 5 Pro", sans-serif;
                left: -4px; 
                content: "\f063";
                color: $label-color;
                position: absolute;
                font-weight: font-reg;
            }
        }
    }
    tbody {
        tr {
          td {
            vertical-align: middle;
            .checkbox-label {
              label {
                margin-bottom: 0px;
              }
            }
            &.td-employer {
              max-width: 50px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            &.td-email {
              max-width: 100px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            span {
              display: block;
              position: relative;
              .btn-edit-cust {
                display: inline-block;
                vertical-align: middle;
                margin-left: 5px;
                position: absolute;
              }
            }
            &.action {
              .check-status {
                &.disabled-status {
                  label {
                    cursor: not-allowed;
                  }
                }
                display: inline-block;
                vertical-align: middle;
                .btn-remove {
                  display: inline-block;
                  vertical-align: middle;
                  font-size: 18px;
                }
                input {
                  &[type="checkbox"] {
                    display: none;
                    &:checked + label {
                      &:before {
                        background: inherit;
                        opacity: 0.5;
                      }
                      &:after {
                        background: $primary-color;
                        left: 20px;
                      }
                    }
                  }
                }
                label {
                  cursor: pointer;
                  height: 0px;
                  position: relative;
                  width: 40px;
                  &:before {
                    background: $base-color;
                    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
                    border-radius: 8px;
                    content: "";
                    height: 16px;
                    margin-top: -8px;
                    position: absolute;
                    opacity: 0.3;
                    transition: all 0.4s ease-in-out;
                    width: 40px;
                  }
                  &:after {
                    background: $white;
                    border-radius: 16px;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
                    content: "";
                    height: 24px;
                    left: -4px;
                    margin-top: -8px;
                    position: absolute;
                    top: -4px;
                    transition: all 0.3s ease-in-out;
                    width: 24px;
                  }
                }
                span {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
}

.pagination {
    margin-bottom: 30px;
    background: $white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    display: inline-flex;
    .page-item {
        .page-link {
            color: $primary-color;
            background-color: transparent;
            line-height: 22px;
            padding: 10px 6px;
            border: none;
            font-size: 14px;
            &:hover {
                background-color: transparent;
            }
            &:focus {
                box-shadow: none;
            }
        }
        &:before {
            color: $primary-color;
        }
        &.active {
            .page-link {
                border: none;
                color: $anchor-hover !important;
            }
        }
        &.disabled {
            .page-link {
                color: $gray !important;
            }
            &:before {
                color: $gray !important;
            }
        }
        &.pagination-first {
            &:before {
                font-family: "Font Awesome 5 Pro", sans-serif;
                font-size: 22px;
                left: 5px;
                top: 4px;
                width: 30px;
                text-align: center;
                height: 35px;
                content: "\f100";
                position: absolute;
                background: #dee2e6;
                background: $white; // z-index: 9;
            }
            .page-link {
                border-right: 1px solid $gray-lighter;
                padding: 10px 15px;
                line-height: 22px;
                color: transparent !important;
            }
        }
        &.pagination-prev {
            .page-link {
                border-right: 1px solid $gray-lighter;
                padding: 10px 15px;
                line-height: 22px;
                margin-right: 8px;
            }
        }
        &.pagination-last {
            &:before {
                font-family: "Font Awesome 5 Pro", sans-serif;
                font-size: 22px;
                right: 3px;
                top: 5px;
                width: 30px;
                text-align: center;
                height: 35px;
                content: "\f101";
                position: absolute;
                background: #dee2e6;
                background: $white; // z-index: 9;
            }
            .page-link {
                border-left: 1px solid $gray-lighter;
                padding: 10px 15px;
                line-height: 22px;
                color: transparent !important;
            }
        }
        &.pagination-next {
            .page-link {
                border-left: 1px solid $gray-lighter;
                padding: 10px 15px;
                line-height: 22px;
                margin-left: 8px;
            }
        }
    }
}

.part-tab-list {
    .pagination {
        margin-bottom: 15px !important;
    }
}

.text-primary {
    color: $primary-color !important;
}

.custom-table {
    min-height: 156px;
    .header {
        td {
            height: 30px !important;
        }
    }
    .table {
        tbody {
            .help-block-inline-edit {
                color: $red;
                background: $white;
                border: 2px solid $red;
                border-radius: 4px;
                padding: 10px;
                position: absolute;
                max-width: 400px;
                min-width: 160px;
                line-height: 1.1;
                font-size: 12px;
                z-index: 999;
                left: calc(100% - 20px);
                margin-top: -40px;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                -ms-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                &:before,
                &:after {
                    right: 100%;
                    top: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }
                &:after {
                    border-color: rgba(136, 183, 213, 0);
                    border-right-color: $white;
                    border-width: 8px;
                    margin-top: -8px;
                }
                &::before {
                    border-right-color: $red;
                    border-width: 10px;
                    margin-top: -10px;
                }
            }
            tr {
                &:first-child,
                &:nth-child(2) {
                    .custom-tooltip-content {
                        top: -10px !important;
                        bottom: inherit !important;
                    }
                }
                &:last-child {
                    .custom-tooltip-content {
                        top: inherit;
                    }
                }
                td {
                    border-bottom: none;
                    height: 50px;
                    &.daycell {
                        height: 30px;
                    }
                    &.inline-hover {
                        .inline-editor {
                            display: inline-block;
                            width: 100%;
                            vertical-align: top;
                        }
                        .inline-editable {
                            display: none;
                        }
                        &:hover {
                            .inline-editable {
                                display: inline-block;
                                margin-left: 5px;
                                position: absolute;
                                right: 4px;
                                color: $blue !important;
                                top: 3px;
                                line-height: 20px;
                            }
                        }
                    }
                    .checkbox-label {
                        label {
                          
                            margin-bottom: 0;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }
    .custom-tooltip {
        .custom-tooltip-content {
            top: 0px;
            right: -380px;
            position: absolute;
        }
    }
}

.table-responsive {
    &.custom-table {
        overflow-y: hidden;
        .table {
            thead {
                tr {
                    th {
                        &.inline-thead {
                            &:after {
                                font-family: "FontAwesome", sans-serif;
                                content: "\F040";
                                color: $base-color;
                                padding-left: 8px;
                                line-height: 20px;
                            }
                        }
                        &.last-th-class {
                            &:before {
                                display: none;
                            }
                            cursor: default;
                        }
                        a {
                            color: $gray;
                            display: inline-block;
                            margin-left: 10px;
                        }
                        &.th-priority {
                            width: 10%;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        cursor: default;
                        a {
                            cursor: pointer;
                            &.anchor-ticket {
                                color: $anchor-color !important;
                                &:hover,
                                &:focus {
                                    color: $anchor-hover !important;
                                }
                            }
                            i {
                                font-size: 15px;
                                line-height: 15px;
                            }
                        }
                        .subject {
                            margin-bottom: 5px;
                            a {
                                color: $base-color;
                                font-weight: $font-reg;
                                &:hover,
                                &:focus {
                                    color: $base-color;
                                }
                            }
                        }
                        .reporter-block {
                            font-weight: $font-light;
                        }
                        .td-description {
                            display: block;
                            padding-right: 10px;
                            max-height: 46px;
                            min-height: 20px;
                            overflow-y: auto;
                            position: relative;
                            white-space: pre-line;
                            line-height: 1.1;
                        }
                        .high {
                            color: $red !important;
                            font-size: 8.4px;
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 2px;
                            line-height: 1;
                            &:hover,
                            &:focus {
                                color: $red !important;
                            }
                        }
                        .medium {
                            color: $yellow !important;
                            font-size: 8.4px;
                            display: inline-block;
                            vertical-align: middle;
                            line-height: 1;
                            margin-left: 2px;
                            &:hover,
                            &:focus {
                                color: $yellow !important;
                            }
                        }
                        .low {
                            color: $low-color !important;
                            font-size: 8.4px;
                            display: inline-block;
                            vertical-align: middle;
                            line-height: 1;
                            margin-left: 2px;
                            &:hover,
                            &:focus {
                                color: $low-color !important;
                            }
                        }
                        .btn-remove {
                            font-size: 18px;
                        }
                        &.td-building-address {
                            div {
                                display: inline-block;
                            }
                        }
                        &.inline-hover {
                            position: relative;
                            &:hover {
                                background: $gray-lighter;
                            }
                        }
                    }
                }
            }
        }
    }
}

.card {
    .table {
        margin-bottom: 0;
    }
}

.fixed-header {
    .caltable {
        thead {
            width: 100% !important; //for calendar
        }
    }
    .table {
        thead {
            display: block;
            position: relative;
            border-bottom: 1px solid $gray-lighter;
          
            &.quote-head {
                width: calc(100% - 6px);
                @-moz-document url-prefix() {
                    width: calc(100% - 17px);
                }
                &:after {
                    width: calc(100% + 6px);
                    @-moz-document url-prefix() {
                        width: calc(100% + 17px);
                    }
                }
            }
            tr {
                display: table;
                width: 100%;
                table-layout: fixed;
                th {
                    border-bottom: none !important;
                }
            }
        }
        tbody {
            display: block;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: calc(100vh - 370px);
            min-height: 170px;
            tr {
                display: table;
                width: 100%;
                table-layout: fixed;
            }
        }
    }
}

.fixed-header-2 {
    .table {
        table-layout: fixed;
        thead {
            position: fixed;
        }
        tbody {
            display: block;
            overflow: auto;
            max-height: calc(100vh - 370px);
        }
    }
}

.sort-asc,
.sort-desc,
.sort-both {
    cursor: pointer;
}

.reporting-page-block {
    .card-body {
        background: $white;
    }
    .table {
        tbody {
            tr {
                td {
                    &.inline-hover {
                        .inline-editable {
                            display: none;
                        }
                        &:hover {
                            .inline-editable {
                                display: inline-block;
                                margin-left: 5px;
                                position: absolute;
                                right: 4px;
                                color: $blue;
                                top: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.table> :not(:first-child) {
    border-top: inherit;
}

.email-truncate {
    max-width: 180px;
    @include mq(laptop) {
        max-width: 300px;
    }
    @include mq(lg-desktop) {
        max-width: 350px;
    }
}