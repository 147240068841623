.pin-slider-wrapper {
    .pin-slider-card {
        background: transparent !important;
        box-shadow: none !important;
        width: 100%;
        padding: 0;
        position: relative;
        .ngx-slider {
            width: 250px;
            margin: 20px 0 15px;
        }
        .ngx-slider[disabled] {
            .ngx-slider-selection-bar {
                .ngx-slider-bar {
                    background-color: $gray-light !important;
                }
            }
            .ngx-slider-pointer {
                background: $gray-light !important;
                color: $gray-light !important;
              
            }
        }
        .ngx-slider-limit {
            display: none;
        }
        .ngx-slider-bar {
            background-color: $gray-lighter !important;
            height: 6px !important;
            border-radius: 5px;
        }
        .ngx-slider-selection-bar {
            visibility: visible !important;
            .ngx-slider-bar {
                background-color: $blue !important;
                height: 6px !important;
                position: relative;
                top: -10px;
            }
        }
        .ngx-slider-tick {
            background: $gray-dark !important;
            height: 6px;
            width: 6px;
            top: 2px;
        }
        .ngx-slider-pointer {
            width: 18px;
            height: 18px;
            top: -7px !important;
            &:after {
                left: 5px;
                top: 5px;
            }
        }
        .ngx-slider-model-value {
            z-index: 4;
        }
        .ngx-slider-model-value {
            color: $base-color;
            font-size: 13px;
            transform: translate(0, 8px) !important;
        }
    }
    .device-card {
        display: flex;
        .ngx-slider {
            width: 145px !important;
            @include mq(laptop) {
                width: 180px !important;
            }
        }
        .label-width {
            min-width: 80px;
        }
    }
    .coverage-value {
        width: 35px;
        display: inline-block;
        padding: 0 0 0 0 !important;
        margin: 0 0 0 14px;
        position: absolute;
        right: 0;
        bottom: 8px;
        .form-control {
            margin: 0;
            padding: 0 10px 0 0;
            text-align: right;
        }
        .degree {
            position: absolute;
            right: 0;
            top: 11px;
            color: $base-color;
            font-size: 23px;
            font-weight: 300;
            display: block !important;
        }
    }
}

.coverage-toggle {
    position: absolute;
    right: 0;
}

.live-device-toggle {
    &.mat-slide-toggle .mat-slide-toggle-thumb-container,
    .live-device-toggle.mat-slide-toggle .mat-slide-toggle-thumb-containe {
        cursor: pointer !important;
    }
}

.coverage-toggle,
.live-device-toggle {
    &.mat-slide-toggle {
        &.mat-checked {
            .mat-slide-toggle-bar {
                background: $blue;
                width: 32px;
                height: 15px;
            }
        }
        .mat-slide-toggle-bar {
            width: 32px;
            height: 15px;
        }
        .mat-slide-toggle-thumb-container {
            height: 11px;
            width: 11px;
            top: 2px;
        }
        .mat-slide-toggle-thumb {
            background-color: $white;
            height: 11px;
            width: 11px;
            position: relative;
            left: 3px;
        }
        .cdk-focused,
        .cdk-mouse-focused {
            background: none;
            box-shadow: none;
        }
    }
    &.mat-disabled {
        display: none;
    }
}

.coverage-bg,
.connection-bg {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: -15px;
        right: -15px;
        top: -5px;
        bottom: -8px;
        background: $gray-lightest;
        z-index: -1;
    }
    .icon-connection {
        position: absolute;
        right: 0;
        top: 6px;
        color: $base-color;
        font-size: 18px;
    }
}

.coverage-area {
    left: 24px !important;
    top: 68px !important;
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    width: auto;
    height: auto;
    z-index: -1;
    overflow: visible !important;
    transform-origin: 0 0;
    pointer-events: none;
    transform: scale(1);
}

.coverage-scale {
    stroke-miterlimit: 0;
}

// coverage customization start
.moveable-control-box {
    &.rCSuxfsyi {
        .moveable-line {
            display: none;
            .moveable-rotation-line {
                display: none;
            }
        }
        .moveable-control {
            height: 32px;
            width: 32px;
            border: none;
            background: 0.5;
            &:before {
                font-family: "Font Awesome 5 Pro", sans-serif;
                font-weight: 600;
                position: absolute;
                color: $base-color;
                font-size: 18px;
            }
            &.moveable-n {
                left: 17px;
                top: -11px;
                // background: rgba(248, 144, 7, 0.5);
                overflow: hidden;
                &:before {
                    content: "\f424";
                    left: 7px;
                    top: 2px;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                }
            }
            &.moveable-n,
            &.moveable-rotation-control {
                cursor: grab;
            }
        }
    }
    &.moveable-control-box {
        &.rCSuxfsyi {
            &.moveable-dragging {
                .moveable-control {
                    &.moveable-n,
                    &.moveable-rotation-control {
                        cursor: grabbing;
                    }
                }
            }
        }
    }
}

.circle-scale {
    background: $red;
    height: 20px;
    width: 20px;
    &::before {
        position: absolute;
        content: "";
        background: forestgreen;
        height: 20px;
        width: 20px;
    }
    &::after {
        position: absolute;
        content: "";
        background: blue;
        height: 20px;
        width: 20px;
    }
}

// coverage customization end
// for Coverage Area (field of view)
.coverage-icon-drag {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    padding: 6px;
    position: absolute;
    em {
        font-size: 18px;
    }
}

.coverage-svg-area {
    left: 24px !important;
    top: 68px !important;
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    width: auto;
    height: auto;
    z-index: -1;
    overflow: visible !important;
    transform-origin: 0 0;
    transform: scale(1);
}

.drag-icon-svg-circle {
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: -1;
    border-radius: 50px;
    cursor: grabbing !important;
    em {
        font-size: 18px;
        color: $base-color;
        padding: 7px;
        cursor: grabbing !important;
    }
}

.rotate-360 {
    transform-origin: 25px 68px;
    left: 0px;
    top: 0px;
    position: absolute;
    z-index: -2;
}

.flag {
    &.flag-bg {
        position: relative;
        margin-bottom: 25px;
        &:after {
            content: "";
            position: absolute;
            left: -15px;
            right: -15px;
            top: -11px;
            bottom: -15px;
            background: rgba($red, 0.1);
            z-index: -1;
        }
    }
    .remove-flag {
        position: absolute;
        right: 0;
        top: 0;
        color: $primary-color;
        cursor: pointer;
        font-size: 13px;
        font-weight: $font-med;
        z-index: 1;
        &:hover {
            color: $blue;
        }
    }
    .flag-tooltip {
        .custom-tooltip-content {
            bottom: inherit !important;
            top: 250px !important;
        }
    }
}

// device/task flag end
// pdf generate configuration start
.generate-pdf-body {
    max-height: 455px;
    overflow: auto;
    padding: 5px;
    .equipment-counter-pdf {
        box-shadow: 1px 3px 11px -3px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 1px 3px 11px -3px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 1px 3px 11px -3px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 1px 3px 11px -3px rgba(0, 0, 0, 0.2);
        display: block;
        background: #fff;
        &.slide-right {
            right: 0px;
        }
        .part-number-listing {
            padding: 4px 18px 4px 40px;
            color: $gray-dark;
            .part-name {
                display: block;
                float: left;
            }
            .part-count {
                display: block;
                float: right;
            }
        }
        .equip-total {
            padding: 10px 35px 10px 55px;
            color: $primary-color;
            font-weight: $font-reg;
            a {
                color: $gray;
            }
        }
        .device-card {
            width: 88%;
            .pin-slider {
                width: 180px;
            }
        }
        .filter-inner {
            margin-bottom: 0;
        }
        ul,
        label {
            margin-bottom: 0;
        }
        .counter-tabs {
            overflow: auto !important;
        }
    }
} // pdf generate configuration end


.pin-color-filter-group {
    margin-bottom: 0 !important;
    width: 132px;
    @include mq(laptop) {
        width: 165px;
    }
    .form-control {
        margin-bottom: 8px !important;
    } 
}
