.equip-list {
    box-shadow: 2px 1px 5px -2px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 2px 1px 5px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 1px 5px -2px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 2px 1px 5px -2px rgba(0, 0, 0, 0.5);
    background: $white;
    width: 330px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    -webkit-transition: right 0.5s ease-in-out;
    -o-transition: right 0.5s ease-in-out;
    transition: right 0.5s ease-in-out;
}

.equipment-sidebar {
    .panel {
        border-radius: 0px;
        box-shadow: none;
        background: none;
        margin-top: 0px !important;
        margin-bottom: 0;
        border: none;
        .panel-default {
            padding-bottom: 0;
            min-height: 0;
            border: 0;
            border-bottom: 1px solid $gray-light;
            .panel-heading {
                background-color: transparent;
                border: none;
                border-radius: 0px;
                padding: 15px 20px;
                .panel-title {
                    .accordion-toggle[aria-expanded="true"] {
                        .slide-toggle {
                            color: $primary-color;
                            font-weight: $font-med;
                            font-size: 14px;
                            line-height: 1;
                            padding-left: 20px;
                            width: 100%;
                            &:before {
                                font-family: "Font Awesome 5 Pro", sans-serif;
                                content: "\f105";
                                left: 0;
                                top: -3px;
                                position: absolute;
                                font-size: 19px;
                                line-height: 19px;
                                background: transparent;
                                cursor: pointer;
                                transform: rotate(90deg);
                                -webkit-transform: rotate(90deg);
                                -moz-transform: rotate(90deg);
                                -ms-transform: rotate(90deg);
                            }
                        }
                    }
                    .slide-toggle {
                        display: block;
                        position: relative;
                        color: $primary-color;
                        padding-left: 20px;
                        font-size: 14px;
                        line-height: 1;
                        font-weight: $font-med;
                        background: $white;
                        text-align: left;
                        &[aria-expanded="true"] {
                            &:before {
                                font-family: "Font Awesome 5 Pro", sans-serif;
                                content: "\f105";
                                left: 0;
                                top: -3px;
                                position: absolute;
                                font-size: 19px;
                                line-height: 19px;
                                background: transparent;
                                cursor: pointer;
                                transform: rotate(90deg);
                                -webkit-transform: rotate(90deg);
                                -moz-transform: rotate(90deg);
                                -ms-transform: rotate(90deg);
                            }
                        }
                        &:before {
                            font-family: "Font Awesome 5 Pro", sans-serif;
                            content: "\f105";
                            left: 0;
                            top: -3px;
                            position: absolute;
                            font-size: 19px;
                            line-height: 19px;
                            background: transparent;
                            cursor: pointer;
                        }
                    }
                }
            }
            .panel-body {
                height: auto !important;
                background: none;
                border: none;
                padding: 0;
            }
        }
    }
}

// equipment info tab start
.counter-tabs {
    overflow-y: scroll !important;
    .tab-container {
        .nav-tabs {
            border-bottom: none;
            background: $white;
            padding-left: 0px;
            li,
            em {
                margin-bottom: 0px;
                width: 50%;
                a {
                    color: $gray-light;
                    border-radius: 0px;
                    border: none;
                    text-align: center;
                    border-bottom: 3px solid $gray-light;
                    padding: 13px;
                    &:hover,
                    &:focus {
                        background: none;
                    }
                }
                &.active {
                    a {
                        color: $primary-color;
                        background: none;
                        border-bottom: 3px solid $primary-color;
                        border-left: none;
                        border-right: none;
                        border-top: none; // padding: 13px 30px;
                    }
                }
            }
        }
        .tab-content {
            .tab-pane {
                background: none;
                padding: 0;
                margin-top: 0px;
                overflow-y: auto;
                overflow-x: hidden;
                position: relative;
                .table {
                    thead {
                        tr {
                            th {
                                border-bottom: 1px solid $border-color;
                                span {
                                    color: $base-color;
                                    font-weight: $font-reg;
                                }
                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }
                    }
                    tr {
                        td {
                            border-top: 1px solid transparent;
                            span {
                                color: $base-color;
                                font-weight: $font-light;
                            }
                            &:last-child {
                                text-align: right;
                                font-weight: $font-med;
                            }
                        }
                        &:last-child {
                            td {
                                border-top: 2px solid $border-color;
                            }
                        }
                    }
                }
                .filter-inner {
                    padding: 0px 0px 15px 0;
                    height: 100%;
                    position: relative;
                    ul {
                        margin: 5px 0 0 0;
                        li {
                            &:last-child {
                                margin-bottom: 0;
                            }
                            margin-bottom: 5px;
                            padding: 0 20px;
                            &:first-child {
                              
                                label {
                                    line-height: 18px;
                                }
                                position: relative;
                                margin-top: 0px;
                                &:before {
                                    position: absolute;
                                    color: $primary-color;
                                    left: 20px;
                                    top: -25px;
                                }
                            }
                            ul {
                                margin-left: 30px;
                            }
                            .checkbox-label {
                                label {
                                    margin-bottom: 0 !important;
                                  
                                }
                            }
                            &.show-part-number {
                                margin-top: 0px; 
                                padding-top: 0px;
                                // padding-bottom: 5px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    color: $primary-color;
                                    left: 20px;
                                    top: -15px;
                                }
                                .checkbox-label {
                                    label {
                                        line-height: 18px;
                                        font-weight: $font-reg;
                                        margin-bottom: 0 !important;
                                        color: $base-color;
                                        &:after,
                                        &:before {
                                            line-height: 1;
                                        }
                                    }
                                    input[type="checkbox"] {
                                        &:checked+label {
                                            &:after {
                                                margin: 4px 0 0 3px;
                                            }
                                        }
                                    }
                                }
                            }
                            &.show-device-status {
                                margin-top: 0px; 
                                padding-top: 0px;
                                padding-bottom: 5px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    color: $primary-color;
                                    left: 20px;
                                    top: -15px;
                                }
                                .checkbox-label {
                                    label {
                                        line-height: 18px;
                                        font-weight: $font-reg;
                                        margin-bottom: 0 !important;
                                        color: $base-color;
                                        &:after,
                                        &:before {
                                            line-height: 1;
                                        }
                                    }
                                    input[type="checkbox"] {
                                        &:checked+label {
                                            &:after {
                                                margin: 4px 0 0 3px;
                                            }
                                        }
                                    }
                                }
                            }
                            &.show-install-status {
                                margin-top: 0px; 
                                padding-top: 0px;
                                padding-bottom: 5px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    color: $primary-color;
                                    left: 20px;
                                    top: -15px;
                                }
                                .checkbox-label {
                                    label {
                                        line-height: 18px;
                                        font-weight: $font-reg;
                                        margin-bottom: 0 !important;
                                        color: $base-color;
                                        &:after,
                                        &:before {
                                            line-height: 1;
                                        }
                                    }
                                    input[type="checkbox"] {
                                        &:checked+label {
                                            &:after {
                                                margin: 4px 0 0 3px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// filter menu for equipment sidebar
.filter-menu {
    .form-group {
        .btn-camera {
            min-width: inherit;
            height: 35px;
            width: 35px;
            line-height: 35px;
            text-align: center;
            float: right;
            border-radius: 50%;
            font-size: 14px;
            padding: 0;
            margin-right: 0px;
            color: $blue;
            cursor: pointer;
        }
        &.default-view {
            .file-uploaded {
                margin-top: 6px;
                .help-block {
                    margin-top: 10px;
                    &:after,
                    &:before {
                        top: 100%;
                        left: 20px !important;
                        right: inherit;
                    }
                    &:before {
                        border-right-color: transparent;
                        margin-left: -10px;
                        margin-top: 0px;
                    }
                    &:after {
                        border-top-color: $white;
                        border-right-color: transparent;
                        margin-left: -8px;
                        margin-top: 0px;
                    }
                }
            }
            .equipment-thumb-block {
                overflow-y: auto;
                .equipment-thumb-inner {
                    float: left;
                    width: 77px;
                    margin-bottom: 10px;
                    margin-right: 2.6%;
                    border: 1px solid $table-body-border;
                    height: 77px;
                    overflow: hidden;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    -ms-border-radius: 4px;
                    position: relative;
                    &:last-child {
                        margin-right: 0px;
                    }
                    .div-table {
                        height: 77px;
                        .div-table-cell {
                            width: 100%;
                        }
                    }
                    .img-thumb-remove {
                        position: absolute;
                        right: 2px;
                        top: 2px;
                        cursor: pointer;
                        background: rgba(255, 255, 255, 0.8);
                        border-radius: 50%;
                        height: 20px;
                        width: 20px;
                        line-height: 20px;
                        z-index: 1;
                        text-align: center;
                        &:after {
                            line-height: 20px;
                        }
                    }
                    .equipment-thumb-img {
                        display: block;
                        position: relative;
                        overflow: hidden;
                        img {
                            cursor: pointer;
                            max-height: 77px;
                            max-width: 77px;
                            margin: 0 auto;
                        }
                    }
                    .equipment-caption {
                        padding: 4px;
                        display: block;
                        line-height: 0.6;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        position: absolute;
                        left: 0px;
                        width: 100%;
                        bottom: 0px;
                        background: rgba(255, 255, 255, 0.8);
                        height: 18px;
                        color: $dark-color;
                        a {
                            color: $dark-color;
                        }
                    }
                }
            }
        }
        &.price-group {
            span {
                display: inline-block;
            }
            .form-control {
                width: calc(100% - 12px);
                display: inline-block;
                padding-right: 0px;
            }
        }
        &.device-group {
            .help-block {
                left: 15px;
                z-index: 1;
                right: inherit;
                margin-top: 15px;
                &:after,
                &:before {
                    bottom: 100%;
                    left: 20px;
                    right: inherit;
                    top: inherit;
                }
                &:before {
                    border-bottom-color: $red;
                    border-right-color: transparent;
                    margin-left: -10px;
                    margin-top: 0px;
                }
                &:after {
                    border-bottom-color: $white;
                    border-right-color: transparent;
                    margin-left: -8px;
                    margin-top: 0px;
                }
            }
        }
        &.install-hours {
            position: relative;
        }
    }
    .add-ticket {
        color: $blue;
        &:focus,
        &:hover {
            color: $blue;
        }
    }
    .favourite-device-info {
        .form-group {
            textarea {
                height: 80px;
            }
            &.replace-cost-group {
                position: relative;
                .help-block {
                    left: 0px;
                    z-index: 11;
                    right: inherit;
                    margin-top: 15px;
                    &:after,
                    &:before {
                        bottom: 100%;
                        left: 20px;
                        right: inherit;
                        top: inherit;
                    }
                    &:before {
                        border-bottom-color: $red;
                        border-right-color: transparent;
                        margin-left: -10px;
                        margin-top: 0px;
                    }
                    &:after {
                        border-bottom-color: $white;
                        border-right-color: transparent;
                        margin-left: -8px;
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}

.equipment-thumb-inner {
    float: left;
    width: 77px;
    margin-bottom: 10px;
    margin-right: 2.6%;
    border: 1px solid $table-body-border;
    height: 77px;
    overflow: hidden;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    position: relative;
    &:last-child {
        margin-right: 0px;
    }
    .div-table {
        height: 77px;
        .div-table-cell {
            width: 100%;
        }
    }
    .img-thumb-remove {
        position: absolute;
        right: 2px;
        top: 2px;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        height: 20px;
        width: 20px;
        line-height: 20px;
        z-index: 1;
        text-align: center;
        &:after {
            line-height: 20px;
        }
    }
    .equipment-thumb-img {
        display: block;
        position: relative;
        overflow: hidden;
        img {
            cursor: pointer;
            max-height: 77px;
            max-width: 77px;
            margin: 0 auto;
        }
    }
    .equipment-caption {
        padding: 4px;
        display: block;
        line-height: 0.6;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: absolute;
        left: 0px;
        width: 100%;
        bottom: 0px;
        background: rgba(255, 255, 255, 0.8);
        height: 18px;
        color: $dark-color;
        a {
            color: $dark-color;
        }
    }
}

.dragged-invalid-file {
    position: relative;
    z-index: 2;
    .help-block {
        &.browse-invalid {
            margin-top: 39px !important;
            position: absolute;
            width: 100%;
        }
        left: 15px !important;
        margin-top: 19px !important;
        width: 164px !important;
        padding: 8px;
        font-size: 12px !important;
        &:after,
        &:before {
            bottom: 100%;
            left: 20px;
            right: inherit;
            top: inherit;
        }
        &:before {
            border-bottom-color: $red;
            border-right-color: transparent;
            margin-left: -10px;
            margin-top: 0px;
        }
        &:after {
            border-bottom-color: $white;
            border-right-color: transparent;
            margin-left: -8px;
            margin-top: 0px;
        }
    }
}

.filter-tabs {
    .task-tab {
        ul {
            &.nav-tabs {
                display: none;
            }
        }
        .tab-content {
            height: calc(100vh - 190px);
        }
    }
    textarea {
        height: 80px !important;
        &.flag-textarea {
            height: 60px !important;
        }
    }
    .project-name {
        a {
            font-size: 14px;
        }
    }
    &.project-filter-tabs {
        textarea {
            height: 80px !important;
            &.flag-textarea {
                height: 60px !important;
            }
        }
        .nav-tabs {
            li {
                width: 50%;
            }
        }
    }
    .published-title {
        background: $red;
        padding: 8px 0;
        text-align: center;
        color: $white;
        font-size: 12px;
        margin: -10px -13px 5px;
        line-height: 1;
        text-transform: uppercase;
        span {
            display: inline-block;
            vertical-align: middle;
            line-height: 12px;
            height: 12px;
        }
        .arrow-amt {
            display: inline-block;
            vertical-align: middle;
            margin-top: 0px;
            svg {
                fill: $white;
                width: 14px;
                height: 14px;
            }
        }
    }
    &.site-filter-tabs {
        textarea {
            height: 80px !important;
        }
        .archived-title {
            background: $base-color;
            padding: 8px 0;
            text-align: center;
            color: $white;
            font-size: 12px;
            margin: -10px -13px 5px;
            line-height: 1;
            text-transform: uppercase;
            span {
                display: inline-block;
                vertical-align: middle;
                line-height: 12px;
                height: 12px;
            }
            .arrow-amt {
                display: inline-block;
                vertical-align: middle;
                margin-top: 0px;
                svg {
                    fill: $white;
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
    &.project-archive-filter-tabs {
        textarea {
            height: 80px !important;
        }
        .archived-title {
            background: $base-color;
            padding: 8px 0;
            text-align: center;
            color: $white;
            font-size: 12px;
            margin: -10px -13px 5px;
            line-height: 1;
            text-transform: uppercase;
            span {
                display: inline-block;
                vertical-align: middle;
                line-height: 12px;
                height: 12px;
            }
            .arrow-amt {
                display: inline-block;
                vertical-align: middle;
                margin-top: 0px;
                svg {
                    fill: $white;
                    width: 14px;
                    height: 14px;
                }
            }
        }
        .nav-tabs {
            li {
                width: 50%;
            }
        }
    }
    .archived-title {
        background: $base-color;
        padding: 8px 0;
        text-align: center;
        color: $white;
        font-size: 12px;
        margin: -10px -13px 5px;
        line-height: 1;
        text-transform: uppercase;
        span {
            display: inline-block;
            vertical-align: middle;
            line-height: 12px;
            height: 12px;
        }
    }
    .nav-tabs {
        border-bottom: none;
        li {
            margin-bottom: 0px;
            width: 33.33%;
            text-align: center;
            &:first-child {
                border-left: none;
            }
            a {
                border: none;
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
                border-radius: 0px;
                margin-right: 0px;
                color: $gray;
                padding: 12px 0 11px 0;
                border-bottom: 3px solid $gray-light;
                &:hover {
                    border-bottom: 3px solid $gray-light;
                }
                &:focus {
                    color: $primary-color;
                }
                span {
                    &.ticket-count {
                        color: $red;
                    }
                }
            }
            &.active {
                a {
                    color: $primary-color !important;
                    border: none;
                    border-bottom: 3px solid $primary-color;
                }
            }
        }
    }
    .tab-content {
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        .tab-pane {
            height: calc(100vh - 240px);
            padding: 10px;
            &.tickets-tab {
                height: calc(100vh - 265px);
                overflow: hidden;
            }
            &.project-tickets-tab {
                height: calc(100vh - 220px);
                overflow: hidden;
            }
        }
        .form-group {
            &.replace-cost-group {
                position: relative;
                .help-block {
                    left: 0px;
                    z-index: 11;
                    right: inherit;
                    margin-top: 15px;
                    &:after,
                    &:before {
                        bottom: 100%;
                        left: 20px;
                        right: inherit;
                        top: inherit;
                    }
                    &:before {
                        border-bottom-color: $red;
                        border-right-color: transparent;
                        margin-left: -10px;
                        margin-top: 0px;
                    }
                    &:after {
                        border-bottom-color: $white;
                        border-right-color: transparent;
                        margin-left: -8px;
                        margin-top: 0px;
                    }
                }
            }
        }
        .table-filter-row {
            margin: 0 -13px;
            a {
                color: $primary-color;
            }
            .checkbox-label {
                label {
                    margin-bottom: 0;
                }
            }
        }
        .table {
            margin-bottom: 0px;
            thead {
                tr {
                    th {
                        &:first-child {
                            padding-left: 0px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        &:first-child {
                            padding-left: 0px;
                        }
                        p {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
        .date-format:first-child {
            .custom-tooltip-content {
                position: fixed; 
                bottom: 42px !important;
            }
        }
        .date-format {
            .custom-tooltip-content {
                position: fixed; 
                bottom: 20px !important;
            }
        }
    }
    .date-format {
        margin-bottom: 3px;
        label {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0px;
            line-height: 1.2;
        }
        .created-by {
            display: inline-block;
            font-size: 13px;
            font-weight: $font-light;
            line-height: 1.2;
            color: $label-color;
            vertical-align: middle;
            max-width: 99px;
            min-width: 40px;
            margin-left: 2px; //   overflow: hidden;
            white-space: nowrap;
            // text-overflow: ellipsis;
        }
        .created {
            float: none;
            line-height: 1.2;
        }
        .created-date {
            float: right;
            line-height: 1.2;
            text-align: right;
        }
    }
}

.panel-view {
    display: inline-block;
    padding: 6px 0px;
    width: 80px;
    height: 32px;
    text-align: center;
    margin: 0px;
    background: $dark-color;
    color: $white;
    border: none;
    outline: none;
    margin-right: 12px;
    z-index: 9;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-ine;
    left: 214px;
    top: 193px;
    position: fixed;
    opacity: 0;
    &.fadein {
        opacity: 1;
    }
}

.equipment-wrapper-overlay {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    z-index: 99;
    display: none;
}

.device-history-block {
    padding: 15px;
    .accordion-group {
        max-height: 320px;
        overflow-y: auto;
        .card {
            box-shadow: none;
            background: $white;
            margin-bottom: 0;
            border: none;
            .card-header {
                background: $white;
                padding: 0;
                border: none;
            }
            .card-body {
                background: $gray-lightest;
            }
        }
    }
    .history-inner-block {
        background: $white;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    }
}

.device-history-header {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid $gray-light;
    padding: 10px 0;
    .device-head {
        display: table-cell;
        color: $label-color;
        padding: 0 15px;
        font-size: 14px;
        font-weight: $font-reg;
        position: relative;
        &.date-time-head {
            width: 24%;
            padding-left: 23px;
        }
        &.updated-head {
            width: 16%;
        }
        &.field-head {
            width: 14%;
        }
        &.old-head {
            width: 23%;
        }
        &.new-head {
            width: 23%;
        }
    }
}

.device-panel-body {
    border-bottom: 1px solid $table-body-border;
    .card {
        min-height: 35px;
    }
    .card-body {
        padding: 0;
    }
    &.panel-open {
        i,
        em {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg); 
            padding-top: 5px;
        }
    }
    i,
    em {
        margin-right: 2px !important;
        margin-left: -5px;
    }
    .device-value-block {
        display: table;
        width: 100%;
        table-layout: fixed;
        .device-val {
            display: table-cell;
            font-size: 14px;
            color: $base-color;
            line-height: 1;
            padding: 12px 15px; //   border-bottom: 1px solid $table-body-border;
            word-break: break-all;
            &.device-val-date {
                width: 24%;
                padding-left: 25px;
                .single-record {
                    display: inline-block;
                    margin-right: -4px;
                    width: 15px;
                }
                .device-accordian {
                    font-size: 12px;
                    line-height: 12px;
                    margin-right: -4px;
                    margin-top: 1px;
                    vertical-align: top;
                    width: 15px;
                    cursor: pointer;
                    &[aria-expanded="true"] {
                        &:before {
                            content: "\f078";
                        }
                    }
                    &[aria-expanded="false"] {
                        &:before {
                            content: "\f054";
                        }
                    }
                    &:before {
                        content: "\f054";
                    }
                }
                .device-dat-val {
                    display: inline-block;
                    width: calc(100% - 30px);
                }
            }
            &.device-val-update {
                width: 16%;
            }
            &.device-val-field {
                width: 14%;
            }
            &.device-val-old {
                width: 23%;
            }
            &.device-new {
                width: 23%;
            }
        }
    }
}

.device-collapse {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid $table-body-border;
    .collapse-val {
        display: table-cell;
        font-size: 14px;
        color: $base-color;
        line-height: 1;
        padding: 12px 15px;
        word-break: break-all;
        
        &.collapse-date {
            width: 24%;
        }
        &.collapse-update {
            width: 16%;
        }
        &.collapse-field {
            width: 14%;
        }
        &.collapse-old {
            width: 23%;
        }
        &.collapse-new {
            width: 23%;
        }
    }
}

.live-feed-text {
    i,
    em {
        font-size: 20px;
        line-height: 20px;
        vertical-align: middle;
        margin-right: 8px;
    }
}

