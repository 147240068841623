.login-wrapper {
  display: inline-block;
  width: 100vw;
  text-align: center;
  height: 100svh;
  background: url($my-cdn-base-path + "assets/images/login-bg.png") rgba(0, 63, 119 , 0.91) -3% 66% no-repeat;
  background-size: 75vh;
  .logo {
      @include mq(laptop) {
          margin-bottom: 80px;
      }
      display: inline-block;
      margin-bottom: 40px;
      img {
          @include mq(laptop) {
              max-width: 375px;
          }
          max-width: 230px;
          margin: 0 auto;
      }
  }
  .login-container {
      .login-box {
          @include mq(laptop) {
              max-width: 550px;
              padding: 70px 15px 15px 15px;
          }
          @include mq(mobile) {
              min-width: 280px;
              max-width: 450px;
          }
          max-width: 450px;
          min-width: 250px;
          margin: 0 auto;
          border-radius: 15px 15px 10px 10px;
          background: $white;
          padding: 45px 15px 15px 15px;
          position: relative;
          &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              background: $blue;
              height: 15px;
              border-radius: 10px 10px 0 0;
          }
          .icon-block {
              @include mq(laptop) {
                  width: 90px;
                  height: 90px;
                  line-height: 90px;
                  font-size: 40px;
                  top: -40px;
                  left: calc(100% / 2 - 45px);
              }
              position: absolute;
              left: calc(100% / 2 - 30px);
              top: -25px;
              background: $blue;
              border-radius: 50%;
              font-size: 25px;
              width: 60px;
              height: 60px;
              line-height: 60px;
              color: $white;
          }
          h1 {
              @include mq(laptop) {
                  font-size: 30px;
              }
              font-size: 20px;
              margin-bottom: 20px;
              font-weight: $font-bold;
          }
          p {
              margin: auto auto 20px auto;
              width: 100%;
              @include mq(tablet) {
                  width: 81%;
              }
          }
          .login-form-group {
              width: 75%;
              margin: 0 auto;
             
              @include mq(mobile) {
                  width: 90%;
              }
              .help-block {
                  @include mq(mobile) {
                      right: -15px;
                  }
              }
              &.account-group {
                text-align: left;
                .form-group {
                    margin-bottom: 1rem !important;
                    .form-control {
                        padding: 0;
                    }
                }
               
              }
              .form-group {
                margin-bottom: 30px;
                  @include mq(mobile) {
                      margin-bottom: 45px;
                  }
                  .form-control {
                      border-bottom: 1px solid $gray-light;
                      -webkit-box-shadow: 0 0 0 1000px white inset !important;
                      box-shadow: 0 0 0 1000px white inset !important;
                      padding: 15px 15px 15px 35px;
                      font-family: "Fira Sans", sans-serif, Arial, Helvetica;
                      font-size: 14px;
                      -webkit-appearance: none !important;
                      &.otp-input {
                        border: 1px solid $gray-light;
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
                        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
                        -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
                        -ms-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
                        -o-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
                        padding: 25px 4px;
                        font-family: "Fira Sans", sans-serif, Arial, Helvetica;
                        font-size: 25px;
                        text-align: center;
                        min-width: 40px;
                        max-width: 45px;
                        font-weight: $font-med;
                        -webkit-appearance: none !important;
                        @include mq(desktop) {
                            padding: 26px 4px;
                        }
                    }
                  }
                  .help-block {
                    right: 0px;
                }
                  &.email-control {
                      position: relative;
                      font-family: "Fira Sans", sans-serif, Arial, Helvetica !important;
                      font-size: 14px;
                       &:before {
                          position: absolute;
                          left: 0;
                          top: 5px;
                          content: "\f0e0";
                          font-family: "Font Awesome 5 Pro", sans-serif;
                          color: $blue;
                          font-size: 20px;
                          line-height: 20px;
                      }
                
                  }
                  .pass-control {
                      font-size: 14px;
                      position: relative;
                      font-family: "Fira Sans", sans-serif, Arial, Helvetica !important;
                      display: inherit;
                       &:before {
                          position: absolute;
                          left: 0;
                          top: 5px;
                          content: "\f084";
                          font-family: "Font Awesome 5 Pro", sans-serif;
                          color: $blue;
                          font-size: 20px;
                          line-height: 20px;
                      }
                  }
                  input:hover,
                  input:focus,
                  input:-webkit-autofill,
                  input:-webkit-autofill:hover,
                  input:-webkit-autofill:focus,
                  input:-webkit-autofill:active,
                  input:-webkit-internal-autofill-selected,
                  input::placeholder {
                      -webkit-box-shadow: 0 0 0px 1000px #000 inset;
                      box-shadow: 0 0 0px 1000px #000 inset;
                      transition: background-color 5000s ease-in-out 0s;
                      font-family: "Fira Sans", sans-serif, Arial, Helvetica !important;
                      font-size: 14px;
                  }
                  .reset-pass {
                      margin-top: 15px;
                      display: inline-block;
                  }
                  .toggle-password {
                      position: absolute;
                      right: 0;
                      top: 5px;
                      cursor: pointer;
                      color: $blue;
                      text-transform: uppercase;
                      font-size: 12px;
                      i {
                          color: $blue;
                          font-size: 17px;
                          position: relative;
                          top: 1px;
                      }
                  }
              }
          }
      }
  }
}

// Reset password start
.login-wrapper {
  &.reset-password-wrapper {
    .login-container {
      .login-box {
        .form-group {
          &.new-password-block {
            .help-block {
              right: -75px;
              margin-top: -60px;
              max-width: 290px;
              bottom: 0;
              z-index: 9;
              @include mq(mobile) {
                right: 0;
                bottom: 34px;
                left: 0;
                padding: 7px 10px;
                width: max-content;
                max-width: 100%;
                text-align: left;
              }
              &:before {
                @include mq(mobile) {
                  left: 25px;
                  -webkit-transform: rotate(-90deg);
                  transform: rotate(-90deg);
                  top: calc(100% - -10px);
                }
              }
              &:after {
                @include mq(mobile) {
                  margin-top: -6px;
                  left: 27px;
                  -webkit-transform: rotate(90deg);
                  transform: rotate(-90deg);
                  top: calc(100% - -5px);
                }
              }
            }
          }
          &.confirm-password-block {
            .help-block {
              right: -75px;
              margin-top: -45px;
              z-index: 9;
            }
          }
        }
      }
    }
  }
}
// Reset password end


// method card for change delivery start
.method-card {
  box-shadow: 0px 1px 3px #00000029;
  -webkit-box-shadow: 0px 1px 3px #00000029;
  -moz-box-shadow: 0px 1px 3px #00000029;
  -ms-box-shadow: 0px 1px 3px #00000029;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  padding: 15px;
  margin-bottom: 15px !important;
  display: block;
  color: $base-color !important;
  font-size: 14px !important;
  font-weight: $font-reg !important;
  cursor: pointer;
  .method-card-radio {
      width: 25%;
      margin: 0 !important;
      label {
          font-weight: $font-med !important;
          font-size: 13px;
          padding-left: 22px;
          line-height: 14px;
          display: inline-block;
          color: $base-color;
      }
  }
  .method-card-description {
      border-left: 1px solid $gray-light;
      margin-left: 25px;
      padding-left: 25px;
      width: 70%;
      .method-card-list {
          margin-bottom: 0 !important;
          li {
              margin-bottom: 0;
              &.text-note {
                  color: $gray;
              }
          }
      }
  }
  .method-card-user-radio {
      margin: 0 0 2px 0 !important;
      label {
          font-weight: $font-med !important;
          font-size: 13px;
          padding-left: 22px;
          line-height: 14px;
          display: inline-block;
          color: $base-color;
      }
  }
  .method-card-user-description {
      margin-left: 22px;
  }
  .method-typical-user {
      width: 30%;
      text-align: center;
      .user-title {
          display: block;
          color: $gray-light;
          font-size: 12px;
          font-weight: $font-light;
      }
      .user {
          font-weight: $font-reg;
          font-size: 12px;
      }
  }
  &.active {
      background: $gray-lightest !important;
  }
  &.user-card {
      min-height: 280px;
      cursor: auto;
  }
}
// method card for change delivery end
