.equipment-tooltip {
    .equipment-inner {
            &.active {
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                -ms-border-radius: 4px;
                -o-border-radius: 4px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    background-color: inherit;
                    z-index: -1;
                    animation: boxpulse 2s infinite;
                    will-change: transform;
                    pointer-events: none !important;
                }
            }
    }
}
@keyframes boxpulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.2, 2.2);
        opacity: 0;
    }
}

.pulse-after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    left: 50%;
    position: absolute;
    margin-left: -19px;
    top: -13px;
    animation: boxpulse-after 2s infinite;
    transform: translate(center, center);
    will-change: transform;
    pointer-events: none !important;

}
@keyframes boxpulse-after {
    0% {
        transform: scale(0.5);
        opacity: 1;
    }
    100% {
        transform: scale(1.5 , 1);
        opacity: 0;
    }
}