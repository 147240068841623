.profile-sidebar {
    height: 100%;
    position: relative;
    float: left;
  
    .leave {
        float: right;
        margin-top: 0;
        color: $anchor-color !important;
        &:hover {
            color: $anchor-hover !important;
        }
    }
    .terms-group {
        label {
            &:before,
            &:after {
                color: $base-color;
            }
        }
    }
    .profile-img-block {
        display: block;
        position: relative;
        width: 120px;
        float: left;
        margin-right: 35px;
        .remove-icon {
            position: absolute;
            top: 5px;
            cursor: pointer;
            font-size: 12px;
            z-index: 2;
            right: 0;
            background: $blue;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            height: 20px;
            width: 20px;
            text-align: center;
            color: $white;
            line-height: 20px;
        }
    }
    .btn-upload-pic {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border: 3px solid $blue;
        background-color: $white;
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
        display: block;
        overflow: hidden;
        text-align: center;
        position: relative i {
            line-height: 86px;
            font-size: 35px;
            color: $dark-color;
        }
        img {
            width: 122px;
            height: 100%;
            object-fit: cover;
            border-radius: 0;
            position: relative;
            top: 0;
        }
        .browse-text {
            position: absolute;
            bottom: -25px;
            left: 37px;
        }
        &:focus {
            outline: none;
        }
    }
    .profile-inner {
        width: 330px;
        float: left;
    }
    .user-error {
        color: $red;
    }
}

.content-wrapper {
    &.profile-wrapper {
        margin-left: 485px;
        width: calc(100% - 485px);
        .btn-invite {
            i {
                margin-right: 10px;
            }
        }
        h4 {
            margin-top: 0px;
        }
    }
}

.not-allowed {
    cursor: not-allowed;
}