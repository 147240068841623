body {
    color: $base-color;
    background: $off-white;
    font-family: $base-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: $font-reg;
    font-size: $base-font-size;
    overflow-x: hidden;
    height: 100%;
}
// adaptive images
img {
    max-width: 100%;
    height: auto;
}
ul, ol {
    list-style: none;
    padding-left: 0;
    margin: 0;
}
a {
    cursor: pointer;
}