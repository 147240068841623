.open-status {
    background: none;
    padding: 0px;
    color: $red;
    font-size: 14px;
    font-weight: $font-reg;
    word-break: break-all;
}

.close-status {
    background: none;
    color: $blue;
    font-size: 14px;
    font-weight: $font-reg;
    padding: 0px;
    word-break: break-all;
}

.inprogress-status {
    background: none;
    color: $yellow;
    font-size: 14px;
    font-weight: $font-reg;
    padding: 0px;
    word-break: break-all;
}

.ticket-sidebar {
    .form-group {
        &.cost-group {
            .help-block {
                left: 15px;
                right: inherit;
                margin-top: 15px;
                width: 88%;
                z-index: 2;
                &:after,
                &:before {
                    bottom: 100%;
                    left: 20px;
                    right: inherit;
                    top: inherit;
                }
                &:before {
                    border-bottom-color: $red;
                    border-right-color: transparent;
                    margin-left: -10px;
                    margin-top: 0px;
                }
                &:after {
                    border-bottom-color: $white;
                    border-right-color: transparent;
                    margin-left: -8px;
                    margin-top: 0px;
                }
            }
        }
    }
}
