/* Role tooltip start */
.associate-user-table,
.associate-table,
.role-table,
.project-role-table,
.associated-Projects-table {
  overflow: inherit !important;
}

.role-table {
  td {
    position: relative;
    &.customer-col {
      max-width: 250px;
      span {
        position: relative;
        width: 85%;
        display: inline-block;
        white-space: pre-wrap;
        padding-right: 20px;
        span {
          width: 100%;
          .cutomer-tooltip-icon {
            right: -20px;
          }
        }
      }
  
    }
  }
}

.table {
  thead {
    .live-system-role-col,
    .project-role-col,
    .user-role-col,
    .access-level-col {
      width: 14%;
      @include mq(md-desktop) {
        width: 13%;
      }
    }
  }
  tbody {
    tr {
      td {
        .btn-edit-cust {
          position: relative;
          right: 15px;
  
        }
        .role-tooltip-icon {
          position: relative;
          right: 20px;
          top: 2px;
          cursor: pointer;
          color: $primary-color;
          &.administrator-info-icon {
            right: inherit;
            padding-left: 5px;
            padding-top: 1px;
          }
          &.fas {
            font-weight: 400 !important;
          }
          // @include mq(laptop) {
          //   right: 70px;
          // }
        }
        .cutomer-tooltip-icon {
          top: calc(50% - 8px);
          cursor: pointer;
          color: $primary-color;
          position: absolute;
          right: 0;

          &.solid-icon {
            font-weight: 600 !important;
          }
        }
      }
    }
  }
}
.project-role-table {
  .role-tooltip-icon {
    top: calc(50% - 8px);
    cursor: pointer;
    color: $primary-color;
    @include mq(md-desktop) {
      right: 85px;
    }
    &.fas {
      font-weight: 400 !important;
    }
  }
  .project-role-col,
  .access-level-col {
    width: 14% !important;
    @include mq(md-desktop) {
      width: 13% !important;
    }
  }
  li {
    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      .custom-tooltip-content {
        top: -10px !important;
        bottom: inherit !important;
      }
    }
    &:last-child {
      .custom-tooltip-content {
        top: inherit;
      }
    }
  }
}

/* Role tooltip end */
.custom-tooltip {
  position: relative;
  display: inline-block;
  line-height: normal;
  width: auto;
  color: $anchor-color !important;
  cursor: pointer;
  &:hover {
    color: $anchor-hover !important;
  }
  span {
    word-break: break-word;
  }
}

.custom-tooltip-content {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  -ms-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  width: 370px; 
  position: absolute; 
  left: calc(100% + 5px);
  top: inherit ;
  bottom: 0 !important;
  padding: 10px 0px 0px;
  background: $white;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -ms-transition-delay: 0.3s;
  display: table;
  .user-profile-block {
    margin-bottom: 10px;
    padding: 5px 15px 0 15px;
    .user-profile-img {
      float: left;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-right: 10px;
      img {
        width: 30px;
        height: 30px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .user-desc {
      float: left;
      .user-name {
        font-size: 15px;
        line-height: 1.2;
        display: block;
        color: $base-color;
        font-weight: $font-med;
        margin-bottom: 2px;
      }
    }
  }
  .user-profile-detail {
    .user-info-lable {
      margin-bottom: 7px;
      &.user-employer {
        margin-bottom: 5px;
        padding-left: 15px;
      }
      label {
        color: $label-color;
        font-weight: $font-reg;
        margin-bottom: 0px;
        line-height: 16px;
      }
      span {
        color: $base-color;
        line-height: 16px;
      }
    }
    .custom-tooltip-bot {
      border-top: 1px solid $form-control-border;
      padding-top: 5px;
      padding-bottom: 5px;
      .user-info-lable {
        margin-bottom: 0px;
        padding-left: 15px;
        &.mobile-info {
          padding: 0 15px 0 0;
          text-align: right;
        }
      }
    }
  }
  &.right {
    right: calc(100% + 5px) !important;
    left: inherit;
  }
}

.custom-tooltip:hover .custom-tooltip-content {
  visibility: visible;
  opacity: 1;
}

.date-modify-site {
  .custom-tooltip {
    .custom-tooltip-content {
      font-size: 14px;
      top: inherit;
      bottom: -20px;
      right: -380px;
      position: absolute;
      .user-profile-detail {
        .user-info-lable {
          &.user-employer {
            label {
              font-size: 14px;
              width: auto;
              display: inline-block;
              font-weight: $font-med;
              vertical-align: middle;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
        .custom-tooltip-bot {
          .user-info-lable {
            label {
              font-size: 14px;
              vertical-align: middle;
              width: auto;
              display: inline-block;
              font-weight: $font-med;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
.filter-date {
  .custom-tooltip {
    .custom-tooltip-content {
      font-size: 14px;
      .user-profile-detail {
        .user-info-lable {
          &.user-employer {
            label {
              font-size: 14px;
              width: auto;
              display: inline-block;
              font-weight: $font-med;
              vertical-align: middle;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
        .custom-tooltip-bot {
          .user-info-lable {
            label {
              font-size: 14px;
              vertical-align: middle;
              width: auto;
              display: inline-block;
              font-weight: $font-med;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.view-image-date {
  .custom-tooltip {
    .custom-tooltip-content {
      position: fixed;
      left: inherit;
      bottom: inherit !important;
      .user-profile-detail {
        .user-info-lable {
          &.user-employer {
            label {
              font-size: 14px;
              width: auto;
              display: inline-block;
              font-weight: $font-med;
              vertical-align: middle;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
        .custom-tooltip-bot {
          .user-info-lable {
            label {
              font-size: 14px;
              vertical-align: middle;
              width: auto;
              display: inline-block;
              font-weight: $font-med;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.project-date {
  .custom-tooltip {
    .custom-tooltip-content {
      font-size: 14px;
      .user-profile-detail {
        .user-info-lable {
          &.user-employer {
            label {
              font-size: 14px;
              width: auto;
              display: inline-block;
              font-weight: $font-med;
              vertical-align: middle;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
        .custom-tooltip-bot {
          .user-info-lable {
            label {
              font-size: 14px;
              vertical-align: middle;
              width: auto;
              display: inline-block;
              font-weight: $font-med;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.service-table,
.customer-device-table,
.project-device-table,
.associate-user-table,
.user-list-table,
.user-provide-table {
  .custom-tooltip {
    .custom-tooltip-content {
      top: 0px;
      right: -380px;
      position: absolute;
    }
  }
}

// ticket page tool tip start
.comment-box {
  .custom-tooltip {
    .custom-tooltip-content {
      font-size: 14px;
      .user-profile-detail {
        .user-info-lable {
          &.user-employer {
            label {
              font-size: 14px;
              width: auto;
              display: inline-block;
              font-weight: $font-reg;
              vertical-align: middle;
              color: $label-color;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
        .custom-tooltip-bot {
          .user-info-lable {
            label {
              font-size: 14px;
              vertical-align: middle;
              width: auto;
              display: inline-block;
              font-weight: $font-reg;
              color: $label-color;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.ticket-sidebar {
  .custom-tooltip {
    .custom-tooltip-content {
      font-size: 14px;
      .user-profile-detail {
        .user-info-lable {
          &.user-employer {
            label {
              font-size: 14px;
              width: auto;
              display: inline-block;
              font-weight: $font-reg;
              vertical-align: middle;
              color: $label-color;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
        .custom-tooltip-bot {
          .user-info-lable {
            label {
              font-size: 14px;
              vertical-align: middle;
              width: auto;
              display: inline-block;
              font-weight: $font-reg;
              color: $label-color;
            }
            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
// ticket page tool tip end

.info-tooltip {
  top: 22px !important;
  left: -3px !important;
  width: 238px !important;
  height: 80px !important;
  font-size: 13px !important;
  padding: 12px !important;
}
.table-tooltip {
  top: 22px !important;
    left: -3px !important;
    width: 283px !important;
    height: 60px !important;
    font-size: 13px !important;
    padding: 12px !important;
    p {
        span {
            white-space: break-spaces !important;
        }
    }
}