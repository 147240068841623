@import "../../../../../../apps/site-owl/src/assets/scss/base/variables";

.content-wrapper {
    width: 100%;
    z-index: 1;
    .equip-header {
        margin-bottom: 10px;
        h4 {
            display: inline-block;
            border-right: 1px solid $border-color;
            padding-right: 10px;
            vertical-align: middle;
            line-height: 1.3;
        }
        .form-control {
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
            width: 140px;
            background: none;
        }
    }
    .card {
        &.floor-card {
            .card-title {
                h4 {
                    display: inline-block;
                    border-right: 1px solid $border-color;
                    padding-right: 10px;
                    vertical-align: middle;
                    line-height: 1.3;
                }

                .form-control {
                    display: inline-block;
                    margin-left: 10px;
                    vertical-align: middle;
                    width: 120px;
                }
            }

            .card-body {
                text-align: center;
                padding: 5px;
                overflow: hidden;
                height: calc(100vh - 143px);
                border: 3px solid transparent;

                .upload-main-img {
                    display: block;
                    position: relative;
                    cursor: grab;
                }
            }
        }
    }
}

.btn-complete {
    margin-bottom: 20px;
    margin-right: 50px;
}

.zoom {
    list-style: none;
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 9;
    padding: 0;
    background: &white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);

    li {
        border-left: 1px solid $gray-lighter;
        padding: 8px 12px;
        font-size: 16px;
        color: $primary-color;
        display: inline-block;
        background: $white;
        cursor: pointer;
        &:hover {
            color: $blue;
        }

        &:first-child {
            border-left: none;
        }

        .fa-animated {
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
        }
    }
}

.floor-plan-card {
    overflow: hidden;
    height: calc(100vh - 142px);
    margin-bottom: 15px;
}

.blue {
    color: $blue;
}

.count-filter-btn {
    list-style: none;
    display: inline-block;
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 9;
    padding: 0;
    margin-bottom: 0;

    li {
        text-align: center !important;
        font-size: 16px !important;
        color: $primary-color !important;
        display: inline-block !important;
        border-radius: 50px !important;
        height: 40px !important;
        width: 40px !important;
        min-width: 40px !important;
        cursor: pointer !important;
        margin-left: 15px !important;
        padding: 0 !important;
        margin-right: 0 !important;

        @include mq(laptop) {
            height: 50px !important;
            width: 50px !important;
            min-width: 50px !important;
        }

        em {
            color: $white;
            vertical-align: middle;
            line-height: 40px;
            margin: 0;
            font-size: 14px;

            @include mq(laptop) {
                line-height: 50px;
                font-size: 16px;
            }
        }
    }
}

.floor-img {
    max-width: inherit;
    width: auto;
}

.img-zoom {
    max-width: inherit;
    width: 100%;
    height: auto;
}

.poninter-none {
    pointer-events: none !important;
}

.ruler-card {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: max-content;
    min-height: 40px;
    margin: 0;
    padding: 9px 10px 7px 10px;
    z-index: 9;
    border-radius: 2px !important;
}
.secondary-device-type-18 {
    .secondary-devices {
        top: 25px !important;
    }
}
.secondary-device-type-24 {
    .secondary-devices {
        top: 22px !important;
    }
}
.secondary-device-type-30 {
    .secondary-devices {
        top: 15px !important;
    }
}
.secondary-device-type-36 {
    .secondary-devices {
        top: 12px !important;
    }
}
.secondary-device-type-42 {
    .secondary-devices {
        top: 10px !important;
    }
}
.secondary-device-type-48 {
    .secondary-devices {
        top: 0px !important;
    }
}
.secondary-device-type-54 {
    .secondary-devices {
        top: 0px !important;
    }
}
.secondary-device-type-60 {
    .secondary-devices {
        top: -5px !important;
    }
}
.secondary-devices-right {
    position: absolute;
    width: 330px;
    left: 78px;
    border: 1px solid $border-color;
    background: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    margin-top: 2px;
    z-index: 999;
    &:after,
    &:before {
        right: 100%;
        top: 22px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:before {
        border: 1px solid transparent;
        border-right-color: $gray-lighter;
        border-width: 16px;
        margin-top: -10px;
    }

    &:after {
        border: 1px solid transparent;
        border-right-color: $white;
        border-width: 12px;
        margin-top: -6px;
    }

    .secondary-inner {
        max-height: 275px;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .device-list {
            width: 100%;
            min-height: 55px;
            position: relative;
            margin: 0;
            cursor: pointer;
            padding: 4px 4px 4px 15px;
            border-bottom: 1px solid $gray-lighter;
            display: flex;

            &:last-child {
                border-bottom: none;
            }

            .drag-icon {
                width: 35px;
                height: 35px;
                display: inline-block;
                margin: auto 0;
                top: -4px;

                i {
                    line-height: 14px;

                    &:before {
                        font-size: 24px;
                        line-height: 24px;
                        padding-left: 0;
                    }
                }

                &.drag-secondary-select {
                    border: 3px solid $green !important;
                }
            }

            .equipment-tooltip {
                left: 28px;
                text-align: left;
                min-width: 176px;
                display: flex;
                position: relative !important;
                justify-content: space-between;
                align-items: center;

                .equipment-inner {
                    border: none;
                    margin: 0 0;
                    .equip-part-name,
                    .equip-part-number {
                        font-size: 14px;
                        color: $base-color;
                        font-weight: $font-reg;
                        max-width: 176px;
                        line-height: 16px;
                        display: block;
                    }
                    &.tooltip-operational,
                    &.tooltip-operationalwith,
                    &.tooltip-notworking {
                        border: none;
                        &.active {
                            background: transparent;
                            box-shadow: none;
                            position: relative;
                        }
                    }
                }
                .pulse {
                    display: none;
                }
            }
            .pulse {
                display: none;
            }
            &.list-active {
                background: $gray-lighter;
                .pulse {
                    display: block;
                }
                .equipment-tooltip {
                    .equipment-inner {
                        display: block;
                    }
                }
            }
            &:nth-child(2n) {
                margin-right: 0px;
            }
        }
    }
}
.secondary-devices-left {
    position: absolute;
    width: 330px;
    right: 23px;
    border: 1px solid $border-color;
    background: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    margin-top: 2px;
    z-index: 999;
    &:after,
    &:before {
        left: 100%;
        top: 22px;
        border: 1px solid $border-color;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:before {
        border: 1px solid transparent;
        border-left-color: $gray-lighter;
        border-width: 16px;
        margin-top: -10px;
    }
    &:after {
        border: 1px solid transparent;
        border-left-color: $white;
        border-width: 12px;
        margin-top: -6px;
    }
    .secondary-inner {
        max-height: 275px;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        .device-list {
            width: 100%;
            min-height: 55px;
            float: left;
            position: relative;
            margin: 0;
            cursor: pointer;
            padding: 4px 4px 4px 15px;
            border-bottom: 1px solid $gray-lighter;
            display: flex;
            &:last-child {
                border-bottom: none;
            }
            &.list-active {
                background: $gray-lighter;
                .pulse {
                    display: block;
                }

                .equipment-tooltip {
                    .equipment-inner {
                        display: block;
                    }
                }
            }
            &:nth-child(2n) {
                margin-right: 0px;
            }
            .drag-icon {
                width: 35px;
                height: 35px;
                display: inline-block;
                margin: auto 0;
                top: -4px;

                &:after {
                    border-left-width: 6px;
                    border-right-width: 6px;
                    border-top-width: 10px;
                    bottom: -11px;
                    left: 9px;
                }
                i {
                    line-height: 14px;

                    &:before {
                        font-size: 24px;
                        line-height: 24px;
                        padding-left: 0;
                    }
                }
                &.drag-secondary-select {
                    border: 3px solid $green !important;
                }
            }
            .equipment-tooltip {
                float: left;
                left: 28px;
                text-align: left;
                min-width: 160px;
                display: table;
                height: 46px;
                position: relative !important;
                .equipment-inner {
                    border: none;
                    margin: 10px 0;
                    display: table-cell !important;
                    vertical-align: middle;
                    .equip-part-name,
                    .equip-part-number {
                        font-size: 14px;
                        color: $base-color;
                        font-weight: $font-reg;
                        max-width: 125px;
                        line-height: 16px;
                    }
                    &.tooltip-operational,
                    &.tooltip-operationalwith,
                    &.tooltip-notworking {
                        border: none;
                        &.active {
                            background: transparent;
                            box-shadow: none;
                            position: relative;
                        }
                    }
                }
                .pulse {
                    display: none;
                }
            }
        }
    }
}

.secondary-devices-bottom {
    position: absolute;
    top: auto !important;
    bottom: 0 !important;
    &:after,
    &:before {
        top: auto !important;
        bottom: 22px !important;
    }
    &:before {
        margin-top: auto !important;
        margin-bottom: -5px !important;
    }
}

.drag-container {
    position: absolute;
    padding-top: 5px;
    margin-left: -26px;
    margin-top: -68px;
    min-height: 70px;

    .equipment-inner {
        border: 2px solid transparent;
    }

    cursor: default;

    .active {
        background: $primary-color;
        color: $white;
    }

    .drag-icon {
        &.green-border {
            border: 3px solid $green;
        }
    }

    &.green-border {
        .equipment-inner {
            border: 2px solid $green !important;
        }

        &.icon-reduce-size {
            .drag-icon {
                border: 5px solid $green;
            }
        }

        .device-list {
            .equipment-inner {
                border: 2px solid transparent !important;
            }
        }

        .secondary-inner {
            .device-list {
                .drag-icon {
                    border: 3px solid transparent !important;

                    &.drag-secondary-select {
                        border: 3px solid $green !important;
                    }
                }
            }
        }
    }

    .badge {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        z-index: 9999;
        position: absolute;
        top: 20px;
        left: 45px;
        height: 22px;
        background: $dark-color;
        color: $white !important;
        width: 22px;
        font-size: 11px;
        padding: 0;
        text-align: center;
        border-radius: 50%;

        &.domotz-badge {
            left: 19px;
            top: -10px;

            .live-feed-icon {
                font-size: 10px;
                line-height: 22px;
                color: $white;
            }

            &.online-badge {
                background: rgba($blue, var(--alpha));
            }

            &.offline-badge {
                background: rgba($red, var(--alpha));
            }

            svg {
                height: 11px;
                fill: $white;
                display: inline-block;
                margin-top: 6px;
            }
        }

        &.image-count {
            bottom: -9px;
            top: 44px;
            left: 18px;

            em {
                &:before {
                    font-size: 10px;
                    line-height: 20px;
                }
            }

            &.Operational {
                background: rgba($blue, var(--alpha));
            }

            &.OperationalWithIssue {
                background: rgba($yellow, var(--alpha));
            }

            &.NotWorking {
                background: rgba($red, var(--alpha));
            }
        }

        &.ticket-count {
            bottom: 9px;
            top: 20px;
            left: -9px;

            em {
                &:before {
                    font-size: 10px;
                    line-height: 20px;
                }
            }

            &.Operational {
                background: rgba($blue, var(--alpha));
            }

            &.OperationalWithIssue {
                background: rgba($yellow, var(--alpha));
            }

            &.NotWorking {
                background: rgba($red, var(--alpha));
            }
        }
    }

    .blue {
        background: $blue;
    }

    .red {
        background: $red;
    }

    .yellow {
        background: $yellow;
    }

    .grey {
        background: rgba($gray-dark, var(--alpha));
    }

    .drag-icon {
        border: 3px solid transparent;
        text-align: center;
        width: 52px;
        height: 52px;
        border-radius: 50% 50% 50% 0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        z-index: 1;
        position: relative;

        &.Operational {
            background: rgba($blue, var(--alpha));
        }

        &.addAction {
            background: $green !important;
        }

        &.move-action {
            background: $gray-dark !important;
        }

        &.archive {
            @include background-opacity($base-color);

            :after {
                @include border-opacity($base-color);
            }
        }

        &.Operation-task {
            position: relative;
            background: rgba($blue, var(--alpha));
            border-radius: 5px;
            transform: rotate(0deg);

            i {
                transform: rotate(0deg);
                line-height: 35px;

                &:before {
                    padding-left: 0px;
                }
            }

            .badge {
                transform: rotate(0deg);
                top: -10px;
                left: 45px;

                &.Operation-task {
                    background: $blue;
                }

                &.image-count {
                    top: 44px !important;
                }
            }
        }

        &.OperationalWithIssue {
            background: rgba($yellow, var(--alpha));
        }

        &.OperationalWithIssue-task {
            position: relative;
            background: rgba($yellow, var(--alpha));
            border-radius: 5px;
            transform: rotate(0deg);

            i {
                transform: rotate(0deg);
                line-height: 35px;

                &:before {
                    padding-left: 0px;
                }
            }

            .badge {
                transform: rotate(0deg);
                top: -10px;
                left: 45px;

                &.OperationalWithIssue-task {
                    background: $yellow;
                }

                &.image-count {
                    top: 44px !important;
                }
            }
        }

        &.NotWorking {
            background: rgba($red, var(--alpha));
        }

        &.NotWorking-task {
            position: relative;
            background: rgba($red, var(--alpha));
            border-radius: 5px;
            transform: rotate(0deg);

            i {
                transform: rotate(0deg);
                line-height: 35px;

                &:before {
                    padding-left: 0px;
                }
            }

            .badge {
                transform: rotate(0deg);
                top: -10px;
                left: 45px;

                &.NotWorking-task {
                    background: $red;
                }

                &.image-count {
                    top: 44px !important;
                }
            }
        }

        &.Archive-task {
            position: relative;
            border-radius: 5px;
            transform: rotate(0deg);
            background: rgba(68, 68, 68, 0.8) !important;

            .badge {
                transform: rotate(0deg);
                top: -10px;
                left: 45px;

                &.archive-task {
                    background: rgba(68, 68, 68, 0.8) !important;
                }

                &.image-count {
                    top: 44px !important;
                }
            }
        }

        i {
            line-height: 32px;
            display: block;
            position: relative;
            z-index: 2;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            &:before {
                padding-left: 5px;
                font-size: 34px;
                color: $white;
            }
            &.icon-shooter-detection {
                &:before {
                    font-size: 24px;
                }
            }
        }
        .thumb-remove {
            display: none;
            position: absolute;
            top: -10px;
            right: 10px;
            font-size: 20px;
            z-index: 100;
            color: $base-color;
            cursor: pointer;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);

            &:hover {
                display: inline-block;
            }
        }
        &:hover .thumb-remove {
            display: inline-block;
        }
    }
    .equipment-tooltip {
        position: absolute;
        left: 54.95%;
        top: 93%;
        width: 100px;
        margin-left: -24px;
        display: inline-block;
        text-align: center;
        pointer-events: none;
        word-break: break-word;
        .equipment-inner {
            border: 2px solid $red;
            border-radius: 4px;
            display: inline-block;
            padding: 3px 8px 4px;
            background: #fff;
            min-width: 40px;
            position: relative;
            line-height: normal;
            pointer-events: all;
            &.tooltip-operational {
                border: 2px solid $blue;
                &.active {
                    box-shadow: 0 0 0 rgba(37, 183, 234, 0.7);

                    background: $blue;
                    .equip-part-name {
                        color: $white;
                    }
                    .equip-part-number {
                        color: $white;
                        border-color: $white;
                    }
                }
            }
            &.tooltip-archive {
                border: 2px solid $base-color !important;
                &.active {
                    box-shadow: 0 0 0 rgba(68, 68, 68, 0.7) !important;
                    position: absolute;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    -ms-border-radius: 4px;
                    -o-border-radius: 4px;
                    width: 100%;
                    left: 0px;
                    top: 0;
                    right: 0px;
                    @include background-opacity($base-color);
                    .equip-part-name {
                        color: $white;
                    }
                    .equip-part-number {
                        color: $white;
                    }
                }
            }
            &.tooltip-addAction {
                border: 2px solid $green !important;
                &.active {
                    box-shadow: 0 0 0 rgba(89, 199, 168, 0.7) !important;
                    background: $green !important;
                    .equip-part-name {
                        color: $white;
                    }
                    .equip-part-number {
                        color: $white;
                        border-color: $white;
                    }
                }
            }
            &.tooltip-addAction-for-secondary {
                &.active {
                    background: $gray-lighter !important;
                    .equip-part-name {
                        color: $white;
                    }
                    .equip-part-number {
                        color: $white;
                        border-color: $white;
                    }
                }
            }
            &.tooltip-move-action {
                border: 2px solid $gray-dark !important;
            }
            &.tooltip-operationalwith {
                border: 2px solid $yellow;
                &.active {
                    box-shadow: 0 0 0 rgba(255, 185, 70, 0.7);
                    background: $yellow;
                    .equip-part-name {
                        color: $white;
                    }
                    .equip-part-number {
                        color: $white;
                        border-color: $white;
                    }
                }
            }
            &.tooltip-notworking {
                border: 2px solid $red;
                &.active {
                    box-shadow: 0 0 0 rgba(240, 40, 65, 1);
                    background: $red;
                    .equip-part-name {
                        color: $white;
                    }
                    .equip-part-number {
                        color: $white;
                        border-color: $white;
                    }
                }
            }
        }
        .equip-part-name {
            font-size: 11px;
            line-height: 12px;
            font-weight: $font-med;
            display: block;
            padding: 4px 0;
           ;
        }

        .equip-part-number {
            border-top: 1px solid $border-color;
            display: block;
            padding: 5px 0;
            &:last-child {
                padding-bottom: 0;
            }

            font-size: 11px;
            line-height: 12px;

            font-weight: $font-med;
        }
    }

    .pulse {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        height: 14px;
        width: 14px;
        position: absolute;
        left: 42.5%;
        top: 100%;
        margin: -3px 0 0 0 !important;
        transform: rotateX(55deg);
        -webkit-transform: rotateX(55deg);
        -moz-transform: rotateX(55deg);
        -ms-transform: rotateX(55deg);
        -o-transform: rotateX(55deg);
        bottom: 0;
        z-index: -1 !important;
        pointer-events: none !important;

        &.pulse-archive {
            &:after {
                @include shadow-opacity(0 0 1px 4px rgba($base-color, 0.3));
            }
        }

        &.pulse-hold {
            &:after {
                box-shadow: 0 0 1px 4px #89849b;
                -webkit-box-shadow: 0 0 1px 4px #89849b;
                -ms-box-shadow: 0 0 1px 4px #89849b;
                -moz-box-shadow: 0 0 1px 4px #89849b;
                -o-box-shadow: 0 0 1px 4px #89849b;
            }
        }

        &.pulse-add-action {
            &:after {
                box-shadow: 0 0 1px 4px $green !important;
                -webkit-box-shadow: 0 0 1px 4px $green !important;
                -ms-box-shadow: 0 0 1px 4px $green !important;
                -moz-box-shadow: 0 0 1px 4px $green !important;
                -o-box-shadow: 0 0 1px 4px $green !important;
            }
        }

        &.pulse-move-action {
            &:after {
                box-shadow: 0 0 1px 4px $gray-dark !important;
                -webkit-box-shadow: 0 0 1px 4px $gray-dark !important;
                -ms-box-shadow: 0 0 1px 4px $gray-dark !important;
                -moz-box-shadow: 0 0 1px 4px $gray-dark !important;
                -o-box-shadow: 0 0 1px 4px $gray-dark !important;
            }
        }
    }

    &.icon-reduce-size {
        .drag-icon {
            transform: scale(0.36) rotate(-45deg);
            -webkit-transform: scale(0.36) rotate(-45deg);
            -moz-transform: scale(0.36) rotate(-45deg);
            -ms-transform: scale(0.36) rotate(-45deg);
            -o-transform: scale(0.36) rotate(-45deg);
            margin: 10px auto 0;
            top: 13px;
            z-index: 1;

            i {
                line-height: 38px;
            }

            .thumb-remove {
                font-size: 56px;
                top: -40px;
                right: 0px;
                line-height: 1;
            }

            &:after {
                width: 38px;
                height: 38px;
                margin: 7px;
            }
        }

        .equipment-tooltip {
            .equipment-inner {
                margin: 6px auto 0;
            }
        }

        .pulse {
            top: inherit;
            bottom: -4px;
            margin: 4px 0 0 -3.5px;
            height: 7px;
            width: 7px;

            &:after {
                margin-left: -20px;
                top: -15px;
            }
        }
    }

    .drag-scale {
        transform: scale(1);
        transform-origin: 26px 62px;
        position: absolute;
        height: 60px;
        width: 60px;
        .drag-icon {
            position: absolute;
            width: 60px;
            height: 60px;
            top: -10px;
            right: 5px;
            padding: 3px 0;

            &.on-drag-hover {
                border: 3px dotted $base-color;

                .task-arrow {
                    &::before {
                        content: "";
                        border-bottom: 3px dotted $base-color;
                        border-right: 3px dotted $base-color;
                        position: absolute;
                        left: -7px;
                        bottom: 3px;
                        width: 13px;
                        height: 13px;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        &.scale-60 {
            transform: scale(1);
        }

        &.scale-54 {
            transform: scale(0.9);
        }

        &.scale-48 {
            transform: scale(0.8);
        }

        &.scale-42 {
            transform: scale(0.7);
        }

        &.scale-36 {
            transform: scale(0.6);
        }

        &.scale-30 {
            transform: scale(0.5);
        }

        &.scale-24 {
            transform: scale(0.4);
        }

        &.scale-18 {
            transform: scale(0.3);
        }
    }
    .flag-badge {
        top: -8px;
        left: 38px;
        width: 25px;
        height: 25px;
        background: rgba($red, var(--alpha));
        font-size: 13px;
        line-height: 25px !important;

        &.task-badge {
            top: -18px !important;
            left: 15px !important;
        }
    }
}
.cursorGrab {
    cursor: -webkit-grab;
}
.cursorMove {
    cursor: move;
}
.cursorGrabbing {
    cursor: -webkit-grabbing;
}
.cursorPointer {
    cursor: pointer;
}
.btn-complete {
    margin-bottom: 20px;
    margin-right: 50px;
}

.display_none {
    display: none;
}
.secondary-status {
    ul {
        list-style: none;
        display: inline-block;
        position: absolute;
        right: 10px;
        top: calc(100% / 2 - 10px);

        li {
            width: 30px;
            display: inline-block;

            em {
                color: $gray-dark;

                &.planned,
                &.NotWorking,
                &.not-working,
                &.red {
                    color: $red;
                }

                &.in-progress,
                &.not-working,
                &.yellow {
                    color: $yellow;
                }

                &.installed,
                &.operational,
                &.blue {
                    color: $blue;
                }
            }
        }
    }
}
.list-highlight {
    .drag-icon {
        border: 3px solid $green;
    }

    .equipment-inner {
        border: none !important;
    }
}
.device-dragging * {
    cursor: grabbing !important;
}
.device-dragging-not-allowed * {
    cursor: not-allowed !important;
}