.site-toggle-main {
    .site-toggle {
        border-bottom: 1px solid $gray-lighter;
        margin-bottom: 10px;
        padding: 5px 0;
        .site-toogle-name {
            font-size: 15px;
            font-weight: $font-med;
            padding-left: 15px;
            position: relative;
            margin-bottom: 10px;
            display: block;
            &:before {
                font-family: "Font Awesome 5 Pro", sans-serif;
                content: "\f105";
                left: 0;
                top: 0;
                position: absolute;
                font-size: 19px;
                line-height: 22px;
                background: transparent;
            }
            .building-count {
                font-weight: $font-reg;
            }
        }
        .building-toggle {
            font-weight: $font-reg;
            padding-left: 15px;
            position: relative;
            .building-toggle-name {
                font-size: 15px;
                font-weight: $font-med;
                padding-left: 17px;
                position: relative;
                // margin-bottom: 10px;
                display: inline-block;
                vertical-align: middle;
                line-height: 18px;
                width: 100%;
                cursor: grab;
                .building-name-wrap {
                    max-width: 57%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    float: left;
                    font-weight: $font-reg;
                    cursor: pointer;
                    line-height: 18px;
                    @include mq(laptop) {
                        max-width: 66%;
                    }
                }
                .floor-count {
                    display: inline-block;
                    padding-left: 4px;
                    font-weight: $font-reg;
                    cursor: pointer;
                    padding-right: 5px;
                    line-height: 18px;
                }
                &:before {
                    font-family: "Font Awesome 5 Pro", sans-serif;
                    content: "\f105";
                    left: 2px;
                    top: 0;
                    position: absolute;
                    font-size: 19px;
                    line-height: 18px;
                    background: transparent;
                    cursor: pointer;
                }
                &::after {
                    font-family: "Font Awesome 5 Pro", sans-serif;
                    content: "\f0b2";
                    right: 14px;
                    top: 0;
                    position: absolute;
                    font-size: 17px;
                    line-height: 18px;
                    color: $gray-dark;
                    cursor: grab;
                }
            }
        }
    }
    .building-toggle-card {
        background: $gray-lightest;
        padding: 10px 5px 10px 10px;
        margin-bottom: 10px;
        box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
    }
    .building-floor-plan {
        overflow: auto;
        max-height: 320px;
    }
    .buidling-scroll {
        // max-height: 320px;
        // overflow: auto;
        padding-right: 5px;
        margin-top: 10px;
        @include mq(laptop) {
            max-height: 420px;
        }
    }
    .floor-move-main {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        .floor-move {
            background: $white;
            padding: 5px 10px;
            display: block;
            position: relative;
            line-height: 22px;
            &::after {
                font-family: "Font Awesome 5 Pro", sans-serif;
                content: "\f0b2";
                right: 10px;
                top: 0;
                position: absolute;
                font-size: 14px;
                line-height: 30px;
                color: $gray-dark;
                cursor: grab;
            }
            // &:first-child {
            //     margin-top: 10px;
            // }
            .floor-name-wrap {
                max-width: 90%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                vertical-align: middle;
                line-height: 16px;
            }
            &.hide {
                visibility: hidden !important;
            }
        }
    }
    .spacer {
        height: 65px;
        pointer-events: none;
        width: 100%;
        position: relative;
    }
    .floor-message {
        position: relative;
        top: -65px;
        width: 95%;
        font-size: 12px !important;
        text-align: left !important;
        line-height: 16px !important;
        display: block !important;
    }
    .arrow-expand {
        &:before {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -webkit-transition: right 0.5s ease-in-out;
            -o-transition: right 0.5s ease-in-out;
            transition: right 0.5s ease-in-out;
        }
    }
    .show-text {
        margin-top: 10px;
        display: block;
        cursor: pointer;
        &.building-link {
            margin-bottom: 10px;
            padding-left: 15px;
        }
        .link {
            color: $blue !important;
        }
    }
    .gu-mirror {
        pointer-events: none;
    }
}

.gu-unselectable {
    cursor: grabbing;
    z-index: 99999 !important;
    .building-toggle-card {
        cursor: grabbing;
    }
    .building-toggle-name {
        &::after {
            cursor: grabbing;
        }
    }
    .floor-move-main {
        .floor-move {
            &::after {
                cursor: grabbing !important;
            }
        }
    }
}