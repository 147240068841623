.search-tab {
    .tab-container {
        .nav-tabs {
            .nav-link {
                padding: 15px !important;
            }
        }
    }
}

.project-tab,
.site-tab,
.ticket-tab,
.user-tab,
.search-tab {
    height: 100%;

    .tab-container {
        .nav-tabs {
            border-bottom: 1px solid $gray-light;

            .nav-link {
                padding: 16px 20px 13px 20px;
                border: none;
                color: $gray-light !important;

                &.active {
                    border-bottom: 3px solid $primary-color;
                    color: $primary-color !important;
                }
            }
        }

        .tab-content {
            height: calc(100% - 53px);
            padding: 15px !important;
            display: inline-block;
            position: relative;
            width: 100%;

            &.audit-device-tab-content {
                padding: 0 !important;
            }
        }
    }

    .btn-site-delete {
        position: absolute;
        top: -44px;
        left: 260px;
        box-shadow: none;
        color: $gray-light;

        &:hover {
            background: $red;
            color: $white;
        }
    }

    .btn-site-unarchive {
        position: absolute;
        top: -44px;
        left: 130px;
        box-shadow: none;
        color: $gray-light;

        &:hover {
            background: $blue;
            color: $white;
        }
    }

    .btn-ticket-archive {
        position: absolute;
        top: -44px;
        left: 366px;
        box-shadow: none;
        color: $gray-light;

        &:hover {
            background: $red;
            color: $white;
        }
    }

    .btn-ticket-unarchive {
        position: absolute;
        top: -44px;
        left: 366px;
        box-shadow: none;
        color: $gray-light;

        &:hover {
            background: $blue;
            color: $white;
        }
    }

    .btn-ticket-delete {
        position: absolute;
        top: -44px;
        left: 505px;
        box-shadow: none;
        color: $gray-light;

        &:hover {
            background: $red;
            color: $white;
        }
    }

    .btn-project-archive {
        position: absolute;
        top: -44px;
        left: 605px;
        box-shadow: none;
        color: $gray-light;

        &:hover {
            background: $red;
            color: $white;
        }
    }

    .btn-project-unarchive {
        position: absolute;
        top: -44px;
        left: 485px;
        box-shadow: none;
        color: $gray-light;

        &:hover {
            background: $blue;
            color: $white;
        }
    }

    .btn-project-delete {
        position: absolute;
        top: -44px;
        left: 630px;
        box-shadow: none;
        color: $gray-light;

        &:hover {
            background: $red;
            color: $white;
        }
    }

    .btn-site-archive {
        position: absolute;
        top: -44px;
        left: 130px;
        box-shadow: none;
        color: $gray-light;

        &:hover {
            background: $red;
            color: $white;
        }
    }
}

.project-tab {
    .tab-container {
        .nav-tabs {
            .nav-link {
                padding: 16px 20px 13px 20px;
                border: none;
                color: $gray-light;

                &.active {
                    border-bottom: 3px solid $primary-color;
                    color: $primary-color;
                }
            }
        }
    }
}

.ticket-tab {
    .tab-container {
        .nav-tabs {
            .nav-link {
                padding: 16px 20px 13px 20px;
                border: none;
                color: $gray-light;

                &.active {
                    border-bottom: 3px solid $primary-color;
                    color: $primary-color;
                }
            }
        }
    }
}

// progress tab start
.project-progress-card,
.installaction-activity-card {
    .ngx-charts {
        overflow: visible;
        float: none !important;

        .circle,
        .bar,
        .arc {
            cursor: pointer;
        }

        .bar,
        .cell,
        .arc,
        .card {
            opacity: 0.8 !important;

            &.active,
            &:hover {
                opacity: 1 !important;
                transition: opacity 100ms ease-in-out;
            }

            &:focus {
                outline: none;
            }

            &.hidden {
                display: none;
            }
        }

        g {
            &:focus {
                outline: none;
            }
        }

        .line-series,
        .line-series-range,
        .polar-series-path,
        .polar-series-area,
        .area-series {
            &.inactive {
                transition: opacity 100ms ease-in-out;
                opacity: 0.2;
            }
        }

        .line-highlight {
            display: none;

            &.active {
                display: block;
            }
        }

        .area {
            opacity: 0.6;
        }

        .circle {
            &:hover {
                cursor: pointer;
            }
        }

        .label {
            font-size: 12px;
            font-weight: normal;
        }

        .tooltip-anchor {
            fill: rgb(0, 0, 0);
        }

        .gridline-path {
            stroke: #ddd;
            stroke-width: 1;
            fill: none;
        }

        .gridline-path-vertical {
            stroke: #ddd;
            stroke-width: 1;
            fill: none;
        }

        .refline-path {
            stroke: #a8b2c7;
            stroke-width: 1;
            stroke-dasharray: 5;
            stroke-dashoffset: 5;
        }

        .refline-label {
            font-size: 9px;
        }

        .reference-area {
            fill-opacity: 0.05;
            fill: #000;
        }

        .gridline-path-dotted {
            stroke: #ddd;
            stroke-width: 1;
            fill: none;
            stroke-dasharray: 1, 20;
            stroke-dashoffset: 3;
        }

        .grid-panel {
            rect {
                fill: none;
            }

            &.odd {
                rect {
                    fill: rgba(0, 0, 0, 0.05);
                }
            }
        }
    }

    .chart-legend {
        display: block !important;
        position: sticky;
        bottom: -50px;
        background: $white;
        margin-top: 10px;

        .legend-wrap {
            display: flex;
        }

        .legend-labels {
            background: transparent !important;
            width: auto;
            margin: 0 auto;

            .legend-label-color {
                opacity: 0.8;
            }

            .legend-label-text {
                font-size: 13px;
                color: $anchor-color;
            }

            li {
                &:hover {
                    .legend-label-color {
                        opacity: 1;
                    }

                    .legend-label-text {
                        color: $anchor-hover;
                    }
                }
            }
        }
    }

    .progress-equipment-icon {
        font-size: 28px !important;
    }
}

.search-header {
    &.tab-container {
        .tab-content {
            padding: 0 !important;

            .search-message,
            .search-btn {
                padding: 15px;
                display: block;
            }
        }
    }
}